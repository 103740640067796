import { useMutation, useQuery } from "@apollo/client";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  Typography,
  useTheme,
} from "@mui/material";
import { useAlerts } from "components/Alerts/AlertProvider";
import { CatalogLink } from "components/ModelLinks/CatalogLink";
import { Dialog } from "components/Dialog";
import { DateField } from "components/Forms/DateField";
import { ErrorDisplay } from "components/Forms/ErrorDisplay";
import { FormSelect } from "components/Forms/FormSelect";
import { FormTextField } from "components/Forms/FormTextField";
import { IssueListButton } from "components/Forms/IssueListButton";
import { PressurePicker } from "components/PressurePicker";
import { SaveButton } from "components/SaveButton";
import { SearchSelect } from "components/SearchSelect";
import { SkeletonFormGroup } from "components/Skeleton";
import { StorageLocationLatLong } from "components/StorageLocationLatLong";
import { StorageLocationPicker } from "components/StorageLocationPicker";
import {
  STORAGE_TYPE_DESCRIPTIONS,
  StorageType,
} from "encamp-shared/src/constants/tierii";
import { prettyPrintEnumValue } from "encamp-shared/src/utils/prettyPrintEnumValue";
import { gql } from "generated-graphql";
import {
  ChemicalDetailFragment,
  FacilityChemicalMeasurementInput,
  InsideOutside,
  Pressure,
  StorageQuadrant,
  Temperature,
  UnitType,
} from "generated-graphql/graphql";
import { transform } from "hooks/transform/transformFacilityChemicalMeasurement";
import { useValidatingForm } from "hooks/useValidatingForm";
import invariant from "invariant";
import { DateTime } from "luxon";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Controller, FormProvider } from "react-hook-form";
import { useParams } from "react-router-dom";
import { hasCriticalIssues } from "util/forms";
import { v4 as uuid } from "uuid";
import { ChemicalPicker } from "../../../components/ChemicalPicker";
import { useFacility } from "../Facility/useFacility";
import { CHEMICALS_WITH_MEASUREMENTS } from "./ChemicalsWithMeasurementsTable";
import { FACILITY_CHEMICAL_MEASUREMENTS_DETAILS } from "./Inventory/Facility/schema";
import { GET_REPORT, useReport } from "./Report/useReport";
import { useFacilityChemicalMeasurementInputValidation } from "./Report/validationHooks/useFacilityChemicalMeasurementInputValidation";
import { ConfirmDialog } from "components/ConfirmDialog";
import { UnfinishedChangesPrompt } from "components/UnfinishedChangesPrompt";
import { getStorageQuadrantLabel } from "util/storage";

const STORAGE_LOCATION_MEASUREMENT_TOOLTIP = (
  <div>
    <p>
      Storage Locations must be unique and are reported individually per
      chemical.
    </p>
    <p>
      If you wish to record a measurement for a specific vessel at the same
      general location at your facility, include the container ID in the Storage
      Location. For example: {'"'}
      West building (Tank A).{'"'}
    </p>
    <p>
      Recording a second measurement for the same chemical, at the same
      location, on the same day will cause the second measurement to supersede
      the first, regardless of Storage Type.
    </p>
  </div>
);

export const UPSERT_FACILITY_CHEMICAL_MEASUREMENT = gql(`
  mutation UpsertFacilityChemicalMeasurement($id: ID!, $input: FacilityChemicalMeasurementInput!) {
    upsertFacilityChemicalMeasurement(id: $id, input: $input) {
      id
    }
  }
`);

const FACILITY_CHEMICAL_MEASUREMENT = gql(`
  query FacilityChemicalMeasurement($id: ID!) {
    facilityChemicalMeasurement(id: $id) {
      id
      amount
      unit
      pressure
      otherPressureValue
      temperature
      measuredAtUtc
      storageType
      storageTypeDescription
      chemicalId
      chemical {
        ...ChemicalPicker
        alternateId
      }
      storageLocationId
      storageLocation {
        ...StorageLocationPicker
      }
      issues {
        ...issue
      }
    }
  }
`);

// This type makes storageLocationId optional for the case where the user is
// adding a new measurement and needs to select a storage location. If provided,
// the set storage location will be used.
export type ChemicalMeasurementSeed = Omit<
  FacilityChemicalMeasurementInput,
  "measurementId" | "measuredAtUtc" | "storageLocationId"
> & { storageLocationId?: string };

type Props = {
  facilityId: string;
  inputChemical?: ChemicalDetailFragment;
  measurementId?: string;
  measurementSeed?: ChemicalMeasurementSeed;
  onClose: () => void;
  open: boolean;
};

export function FacilityChemicalMeasurementForm(props: Props) {
  const { facilityId, measurementId, inputChemical, measurementSeed } = props;
  const alerts = useAlerts();
  const theme = useTheme();
  const { tenantId } = useParams<{
    tenantId: string;
  }>();
  invariant(facilityId, "Facility ID is required");
  const { data: reportData } = useReport();
  const { data: facilityData } = useFacility(facilityId);

  const facilityState =
    facilityData?.facility?.state ?? reportData?.tierIIReport.facility.state;

  const isOregonFacility = facilityState === "OR";
  const isCaliforniaFacility = facilityState === "CA";

  const { data: measurementResult, loading: measurementLoading } = useQuery(
    FACILITY_CHEMICAL_MEASUREMENT,
    {
      skip: !measurementId,
      variables: { id: measurementId ?? "" },
    }
  );
  const measurement = measurementResult?.facilityChemicalMeasurement;

  const mode = measurement?.id ? "Edit" : "Add";

  const defaultValues: FacilityChemicalMeasurementInput = useMemo(
    () => ({
      id: measurementId,
      amount: measurement?.amount ?? measurementSeed?.amount ?? null,
      unit:
        measurement?.unit ??
        measurementSeed?.unit ??
        (mode === "Add" ? UnitType.Pounds : null),
      pressure:
        measurement?.pressure ??
        measurementSeed?.pressure ??
        (mode === "Add" ? Pressure.AmbientPressure : null),
      otherPressureValue: measurement?.otherPressureValue ?? null,
      temperature:
        measurement?.temperature ??
        measurementSeed?.temperature ??
        (mode === "Add" ? Temperature.AmbientTemperature : null),
      storageType:
        measurement?.storageType ?? measurementSeed?.storageType ?? "",
      storageTypeDescription:
        measurement?.storageTypeDescription ??
        measurementSeed?.storageTypeDescription ??
        "",
      chemicalId: measurement?.chemicalId ?? measurementSeed?.chemicalId ?? "",
      facilityId: facilityId ?? "",
      storageLocationId:
        measurement?.storageLocationId ??
        measurementSeed?.storageLocationId ??
        "",
      storageLocation: (measurement?.storageLocation && {
        ...measurement?.storageLocation,
        description: measurement.storageLocation.description ?? "",
        latitude:
          measurement.storageLocation.latitude ??
          facilityData?.facility?.latitude,
        longitude:
          measurement.storageLocation.longitude ??
          facilityData?.facility?.longitude,
      }) ??
        measurementSeed?.storageLocation ?? {
          facilityId,
          description: "",
          id: "",
          latitude: facilityData?.facility?.latitude,
          longitude: facilityData?.facility?.longitude,
        },
      measuredAtUtc:
        measurement?.measuredAtUtc ??
        DateTime.fromJSDate(new Date()).toISODate(),
    }),
    [
      facilityId,
      measurement,
      measurementSeed,
      measurementId,
      facilityData?.facility?.latitude,
      facilityData?.facility?.longitude,
      mode,
    ]
  );

  // This stores the selected chemical name from the ChemicalPicker
  const [selectedChemicalName, setSelectedChemicalName] = useState<
    string | null
  >(inputChemical?.name ?? null);

  const form = useValidatingForm<FacilityChemicalMeasurementInput>(
    defaultValues,
    measurement?.issues ?? [],
    useFacilityChemicalMeasurementInputValidation()
  );

  const {
    watch,
    reset,
    handleSubmit,
    control,
    setValue,
    getValues,
    issues,
    formState,
    trigger,
  } = form;

  const { isSubmitSuccessful, errors } = formState;

  useEffect(() => {
    // Since we're not in the context of a real react-hook-form, we need to manually trigger the validation when the form opens
    if (mode === "Edit") {
      trigger();
    }
  }, [trigger, mode]);

  const storageType = watch("storageType");
  const storageLocation = watch("storageLocation");

  const [mutate, { loading }] = useMutation(
    UPSERT_FACILITY_CHEMICAL_MEASUREMENT,
    {
      refetchQueries: [
        CHEMICALS_WITH_MEASUREMENTS,
        FACILITY_CHEMICAL_MEASUREMENTS_DETAILS,
        GET_REPORT,
      ],
    }
  );

  const onClose = useCallback(() => {
    setSelectedChemicalName(null);
    reset();
    props.onClose();
  }, [props, reset]);

  const hasPendingChanges = formState.isDirty && !formState.isSubmitSuccessful;

  const [showConfirmClose, setShowConfirmClose] = useState(false);

  const onCancel = useCallback(() => {
    // If there are pending changes, verify the user wants to close the dialog
    if (hasPendingChanges) {
      setShowConfirmClose(true);
      return;
    }

    onClose();
  }, [onClose, hasPendingChanges]);

  const onSubmit = useCallback(
    async (data: FacilityChemicalMeasurementInput) => {
      try {
        await mutate({
          variables: {
            id: measurement?.id?.length ? measurement.id : uuid(),
            input: transform(data),
          },
        });
        onClose();
      } catch (err) {
        alerts.error("An error occurred while saving measurement data", err);
      }
    },
    [alerts, measurement?.id, mutate, onClose]
  );

  useEffect(() => {
    if (isSubmitSuccessful) {
      onClose();
      alerts.success("Successfully saved measurement data");
      reset();
    }
  }, [alerts, isSubmitSuccessful, onClose, reset]);

  return (
    <>
      <Dialog open={props.open} onClose={onCancel} fullWidth>
        <FormProvider {...form}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <DialogTitle>{mode} Chemical Measurement</DialogTitle>
            <DialogContent>
              {measurementId && measurementLoading ? (
                <SkeletonFormGroup count={8} />
              ) : (
                <Grid
                  container
                  columnSpacing={theme.spacing(3)}
                  rowSpacing={theme.spacing(1)}
                >
                  {/* Chemical */}
                  <Grid item xs={12}>
                    <Typography variant="body2">
                      Chemical measurements remain relevant until another
                      measurement is made for the same chemical at the same
                      Storage Location. If a chemical is no longer present at a
                      storage location, enter '0' for amount on the date it was
                      removed.
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sx={{ marginTop: theme.spacing(1) }}>
                    <Controller
                      name="chemicalId"
                      control={control}
                      render={({ field, fieldState }) => (
                        <ChemicalPicker
                          {...field}
                          {...fieldState}
                          value={measurement?.chemical || inputChemical}
                          defaultSearchTerm={`tenantId:${tenantId}`}
                          onChange={(chem) => {
                            setValue("chemicalId", chem?.id, {
                              shouldValidate: true,
                            });
                            setSelectedChemicalName(chem?.name ?? null);
                          }}
                          required
                          readOnly={!!measurementId || !!inputChemical}
                        />
                      )}
                    />
                  </Grid>

                  {/* Amount */}
                  <Grid item xs={4}>
                    <FormTextField
                      name="amount"
                      control={control}
                      label="Amount"
                      type="number"
                      textFieldProps={{
                        required: true,
                        inputMode: "numeric",
                        onClick: (event) =>
                          (event.target as HTMLInputElement).select(),
                      }}
                      hideErrors
                    />
                  </Grid>

                  {/* Unit */}
                  <Grid item xs={4}>
                    <FormSelect
                      name="unit"
                      label="Unit"
                      control={control}
                      selectItems={Object.values(UnitType).map((value) => ({
                        value,
                        display: prettyPrintEnumValue(value),
                      }))}
                      rules={{ required: true }}
                      hideErrors
                    />
                  </Grid>

                  {/* Measurement Date */}
                  <Grid item xs={4}>
                    <DateField
                      label="Measurement Date"
                      name="measuredAtUtc"
                      control={control}
                      required={true}
                      datePickerProps={{
                        maxDate: DateTime.now(),
                      }}
                      hideErrors
                    />
                  </Grid>

                  <Grid item xs={12} sx={{ pt: "0!important" }}>
                    <ErrorDisplay
                      errors={errors}
                      fields={["amount", "unit", "measuredAtUtc"]}
                    />
                  </Grid>

                  {/* Storage Location */}
                  <Grid item xs={12}>
                    <Controller
                      name="storageLocationId"
                      control={control}
                      render={({ field, fieldState }) => (
                        <StorageLocationPicker
                          error={fieldState.error}
                          disableClearable
                          facilityId={facilityId}
                          description={storageLocation?.description}
                          tooltip={STORAGE_LOCATION_MEASUREMENT_TOOLTIP}
                          required
                          onSelectLocation={(location) => {
                            setValue(
                              "storageLocation",
                              {
                                ...location,
                                latitude:
                                  location.latitude ??
                                  facilityData?.facility?.latitude,
                                longitude:
                                  location.longitude ??
                                  facilityData?.facility?.longitude,
                                description: location.description ?? "",
                              },
                              { shouldValidate: true }
                            );
                            setValue("storageLocationId", location.id, {
                              shouldValidate: true,
                            });
                          }}
                        />
                      )}
                    />
                  </Grid>
                  {isOregonFacility && (
                    <>
                      <Grid item xs={12}>
                        <FormSelect
                          name="storageLocation.OR_insideOutside"
                          label="Inside/Outside *"
                          selectItems={Object.values(InsideOutside).map(
                            (insideOutside) => ({
                              display: prettyPrintEnumValue(insideOutside),
                              value: insideOutside,
                            })
                          )}
                          control={control}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormTextField
                          label="Storage Building *"
                          name="storageLocation.OR_storageBuilding"
                          control={control}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormTextField
                          label="Storage Floor"
                          name="storageLocation.OR_storageFloor"
                          control={control}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormSelect
                          name="storageLocation.OR_storageQuadrant"
                          label="Storage Quadrant *"
                          selectItems={Object.values(StorageQuadrant).map(
                            (storageQuadrant) => ({
                              display: getStorageQuadrantLabel(storageQuadrant),
                              value: storageQuadrant,
                            })
                          )}
                          control={control}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormTextField
                          label="Storage Room"
                          name="storageLocation.OR_storageRoom"
                          control={control}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormTextField
                          label="Storage Area *"
                          name="storageLocation.OR_storageArea"
                          control={control}
                        />
                      </Grid>
                    </>
                  )}
                  {isCaliforniaFacility && (
                    <>
                      <Grid item xs={12}>
                        <FormTextField
                          label="Grid Number"
                          name="storageLocation.CA_gridNumber"
                          control={control}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormTextField
                          label="Map Number"
                          name="storageLocation.CA_mapNumber"
                          control={control}
                        />
                      </Grid>
                    </>
                  )}
                  {/* Storage Type */}
                  <Grid item xs={storageType === StorageType.Other ? 6 : 12}>
                    <Controller
                      name="storageType"
                      control={control}
                      render={({ field, fieldState }) => (
                        <SearchSelect
                          {...field}
                          error={fieldState.error}
                          label="Storage Type"
                          options={Object.values(STORAGE_TYPE_DESCRIPTIONS)}
                          getOptionLabel={(opt: any) =>
                            prettyPrintEnumValue(opt)
                          }
                          required={true}
                        />
                      )}
                    />
                  </Grid>

                  {/* Storage Type Description */}
                  {storageType === StorageType.Other ? (
                    <Grid item xs={6}>
                      <FormTextField
                        label="Storage Type Description"
                        name="storageTypeDescription"
                        control={control}
                      />
                    </Grid>
                  ) : (
                    <></>
                  )}

                  {/* Pressure */}
                  <Grid item xs={12}>
                    <PressurePicker
                      facilityState={facilityData?.facility?.state}
                    />
                  </Grid>

                  {/* Temperature */}
                  <Grid item xs={12}>
                    <FormSelect
                      label="Temperature"
                      name="temperature"
                      control={control}
                      selectItems={Object.values(Temperature).map((value) => ({
                        value,
                        display: prettyPrintEnumValue(value),
                      }))}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <StorageLocationLatLong facilityState={facilityState} />
                  </Grid>

                  <Grid item xs={12} sx={{ pt: "0!important" }}>
                    <CatalogLink
                      id={inputChemical?.id ?? getValues("chemicalId") ?? ""}
                      type="Chemical"
                      name={inputChemical?.name ?? selectedChemicalName ?? ""}
                      issues={measurement?.issues ?? issues ?? []}
                    />
                  </Grid>
                </Grid>
              )}
            </DialogContent>
            <DialogActions>
              <IssueListButton issues={issues} />
              <FormControl>
                <Button variant="outlined" onClick={() => onCancel()}>
                  Cancel
                </Button>
              </FormControl>
              <FormControl>
                <SaveButton
                  loading={loading || measurementLoading}
                  disabled={hasCriticalIssues(issues)}
                />
              </FormControl>
            </DialogActions>
          </form>
        </FormProvider>
      </Dialog>
      <UnfinishedChangesPrompt when={hasPendingChanges} />
      <ConfirmDialog
        open={showConfirmClose}
        onConfirm={onClose}
        onClose={() => setShowConfirmClose(false)}
        title="Are you sure you want to leave?"
        msg={"You have unsaved changes."}
      />
    </>
  );
}
