import { Grid, Stack, Typography, Button, Box } from "@mui/material";
import { Controller } from "react-hook-form";
import { TenantPicker } from "components/TenantPicker";
import Edit from "@mui/icons-material/Edit";
import { useState } from "react";
import { FacilitiesGrid } from "../components/FacilitiesGrid";
import { useCommunicationFormContext } from "../hooks/useCommunicationForm";

export const OrganizationStep = () => {
  const { control, watch, setValue, mode } = useCommunicationFormContext();
  const disabled = mode === "view";

  const [isEditingTenant, setIsEditingTenant] = useState(false);

  const tenant = watch("tenant");

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box sx={{ mb: 2 }}>
        {tenant && !isEditingTenant ? (
          <Stack direction="row" spacing={2} alignItems="center">
            <Typography variant="subtitle1">
              Organization: {tenant.name}
            </Typography>
            <Button
              startIcon={<Edit />}
              disabled={disabled}
              onClick={() => {
                setIsEditingTenant(true);
                setValue("facilityIds", [], { shouldValidate: true });
              }}
              size="small"
            >
              Change
            </Button>
          </Stack>
        ) : (
          <Controller
            name="tenant"
            control={control}
            render={({ field }) => (
              <Box p={2}>
                <TenantPicker
                  {...field}
                  onTenantSelected={(tenant) => {
                    field.onChange(tenant);
                    setIsEditingTenant(false);
                    // Clear facilities when tenant changes
                    setValue("facilityIds", [], { shouldValidate: true });
                  }}
                />
              </Box>
            )}
          />
        )}
      </Box>
      {tenant && !isEditingTenant && (
        <Box>
          <Controller
            name="facilityIds"
            control={control}
            rules={{
              required: "At least one facility is required",
              validate: (value) => {
                const length = Array.isArray(value) ? value.length : 0;
                return length > 0 || "At least one facility must be selected";
              },
            }}
            render={({ field, fieldState }) => (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                }}
              >
                <Typography variant="subtitle1" sx={{ mb: 1 }}>
                  Select Facilities ({field.value?.length} selected)
                </Typography>
                <Box sx={{ flex: 1 }}>
                  <FacilitiesGrid
                    disabled={disabled}
                    tenantId={tenant.id}
                    facilityIds={field.value}
                    onFacilitySelect={(newFacilityIds) => {
                      field.onChange(newFacilityIds);
                    }}
                  />
                </Box>
                {fieldState.error && (
                  <Typography color="error" variant="caption" sx={{ mt: 1 }}>
                    {fieldState.error.message}
                  </Typography>
                )}
              </Box>
            )}
          />
        </Box>
      )}
    </Box>
  );
};
