import { RefetchQueriesInclude, useLazyQuery, useQuery } from "@apollo/client";
import Download from "@mui/icons-material/Download";
import FindReplaceIcon from "@mui/icons-material/FindReplace";
import {
  Box,
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { useAlerts } from "components/Alerts/AlertProvider";
import { Dialog } from "components/Dialog";
import { DocumentPreview } from "components/DocumentPreview";
import { useDocumentViewer } from "components/DocumentViewer";
import { Dropzone } from "components/Dropzone";
import { FormAutocomplete } from "components/Forms/FormAutocomplete";
import { FormSelect } from "components/Forms/FormSelect";
import { FormTextField } from "components/Forms/FormTextField";
import { SaveButton } from "components/SaveButton";
import { TagPicker } from "components/TagPicker";
import { CA_DocumentOption } from "encamp-compliance-engine/src/types/facilityStateFieldTypes";
import { prettyPrintDocumentType } from "encamp-shared/src/utils/prettyPrintDocumentType";
import { gql } from "generated-graphql";
import {
  CaDocumentType,
  DocumentAssociationsInput,
  DocumentInput,
  DocumentType,
  FacilityDocumentInput,
  Document as GqlDocument,
  Issue,
  TagInput,
  TagPickerFragment,
  TagType,
} from "generated-graphql/graphql";
import { useCurrentUser } from "hooks/useCurrentUser";
import { useDocumentUpload } from "hooks/useDocumentUpload";
import { useUpsertDocument } from "hooks/useUpsertDocument";
import { useValidatingForm } from "hooks/useValidatingForm";
import GET_DOCUMENT_DOWNLOAD_LINK_QUERY from "queries/getDocumentDownloadLink";
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import { Controller, SubmitHandler } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import {
  isHmbpDocumentType,
  useHmbpDocumentOptions,
  useHmbpFeature,
  useHmbpStateFieldMutation,
} from "routes/Customer/Chemicals/Report/useHmbp";
import { useDocumentIssuesForModel } from "routes/Customer/Chemicals/Report/useReport";
import { useDocumentInputValidation } from "routes/Customer/Chemicals/Report/validationHooks/useDocumentInputValidation";
import { documentTypeToLabel } from "util/constants";
import { hasCriticalIssues } from "util/forms";
import {
  FacilityDocumentPicker,
  FacilityDocumentPickerItem,
} from "./FacilityDocumentPicker";
import { DateField } from "./Forms/DateField";
import { MultipleFacilityPicker } from "./MultipleFacilityPicker";

const GET_UPSERTED_DOCUMENT = gql(`
  query GetDocumentForAddEdit($id: ID!) {
    document(id: $id) {
      id
      title
      description
      storageLink
      documentType
      updatedAt
      createdAt
      authoredAt
      reportId
      documentTags {
        id
        name
        tenantId
        isDisabled
        type
      }
    }
  }
`);

type AddEditDocumentDialogProps = {
  onSubmit?: (
    document: Pick<
      GqlDocument,
      "id" | "fileExtension" | "title" | "documentType"
    >,
    dialogState: AddEditDocumentDialogState
  ) => void;
  dialogState: AddEditDocumentDialogState;
  setDialogState: Dispatch<SetStateAction<AddEditDocumentDialogState>>;
  documentTypeOptions?: DocumentType[];
  activityPickerOptions?: {
    id: string;
    title: string;
  }[];
  tenantId: string;
  reportId?: string;
  state?: string | null;
  showFacilityPicker?: boolean;
  showTagPicker?: boolean;
  refetchQueries?: RefetchQueriesInclude;
  facilityDocumentIssues?: Issue[];
  showAttestation?: boolean;
  generateTitleOnCreate?: boolean;
  standardizeDocumentTitle?: (
    fileExtension?: string | null
  ) => string | undefined;
};

export enum FormState {
  UPLOAD = "Upload",
  FORM = "Form",
}

export const defaultAddEditDocumentDialogState: AddEditDocumentDialogState = {
  open: false,
  saving: false,
  step: FormState.UPLOAD,
};

export enum EditMode {
  ADD = "Add",
  ADD_OR_PICK = "AddOrPick",
  EDIT = "Edit",
}

export type AddEditDocumentDialogState = {
  facilities?: FacilityDocumentInput[];
  documentId?: string;
  open: boolean;
  mode?: EditMode;
  step?: FormState;
  title?: string;
  documentType?: DocumentType;
  storageLink?: string;
  activityId?: string;
  fileExtension?: string | null | undefined;
  selectedFile?: File;
  documentTags?: TagPickerFragment[];
  saving: boolean;
  currentSubmissionDocument?: boolean;
  authoredAt?: string;
  description?: string | null | undefined;
  isAddingFromIssue?: boolean;
};

// 1. select a document
// 2. upload a new document

export type DocumentFormData = Omit<DocumentInput, "documentTags"> & {
  fileSize?: number;

  // gql-gen generates this attribute with type `InputMaybe<T>` which means it could be null
  // but it can only be undefined or an array of tag inputs
  documentTags?: TagInput[];
};

export function AddEditDocumentDialog({
  onSubmit,
  dialogState,
  setDialogState,
  documentTypeOptions,
  activityPickerOptions,
  tenantId,
  reportId,
  state,
  showFacilityPicker,
  showTagPicker,
  facilityDocumentIssues,
  showAttestation,
  generateTitleOnCreate,
  standardizeDocumentTitle,
  refetchQueries,
}: AddEditDocumentDialogProps) {
  const theme = useTheme();
  const alerts = useAlerts();
  const { user } = useCurrentUser();
  const { fetchAndHandleDownload, clearDownloadLink } = useDocumentViewer();
  const navigate = useNavigate();
  const hmbpFeature = useHmbpFeature(state ?? "");
  const { setDocumentOptionToUpload, loading: loadingStateFields } =
    useHmbpStateFieldMutation();
  const [pickedDocument, setPickedDocument] =
    useState<FacilityDocumentPickerItem | null>(null);

  const shouldFieldsBeReadOnly =
    dialogState.mode === EditMode.ADD_OR_PICK && !!pickedDocument;
  const [attestation, setAttestation] = useState<boolean>(false);

  const initialIssues = useDocumentIssuesForModel(dialogState.documentId ?? "");
  const defaultValues: DocumentFormData = useMemo(
    () => ({
      title: dialogState.title ?? "",
      documentType: dialogState.documentType,
      activityId: dialogState.activityId,
      fileExtension: dialogState.fileExtension,
      storageLink: dialogState.storageLink,
      currentSubmissionDocument: dialogState.currentSubmissionDocument,
      facilities: dialogState.facilities || [],
      documentTags: dialogState.documentTags ?? [],
      authoredAt: dialogState.authoredAt,
      description: dialogState.description,
    }),
    [
      dialogState.title,
      dialogState.documentType,
      dialogState.activityId,
      dialogState.fileExtension,
      dialogState.storageLink,
      dialogState.currentSubmissionDocument,
      dialogState.facilities,
      dialogState.documentTags,
      dialogState.authoredAt,
      dialogState.description,
    ]
  );

  const { data } = useQuery(GET_DOCUMENT_DOWNLOAD_LINK_QUERY, {
    variables: { id: dialogState.documentId ?? "" },
    skip: !dialogState.documentId,
  });

  const { uploadFile, isUploading } = useDocumentUpload({
    generateTitleOnCreate: generateTitleOnCreate,
    onSuccess: (document) => {
      onClose();
      if (onSubmit) {
        onSubmit(document, dialogState);
      }
      reset();
      setAttestation(false);
      alerts.success("Successfully uploaded the file");
    },
    onError: (error) => {
      alerts.error("An error occurred while uploading the document", error);
    },
    refetchQueries,
  });

  const { upsertDocument } = useUpsertDocument();
  const [fetchUpsertedDocument] = useLazyQuery(GET_UPSERTED_DOCUMENT);

  const {
    handleSubmit,
    reset,
    control,
    setValue,
    watch,
    issues,
    trigger,
    setError,
    clearErrors,
  } = useValidatingForm<DocumentFormData>(
    defaultValues,
    initialIssues,
    useDocumentInputValidation()
  );

  const watchedActivityId = watch("activityId");
  const watchTitle = watch("title");
  const watchDocumentType = watch("documentType");
  const watchFileExtension = watch("fileExtension");

  const documentOption = useHmbpDocumentOptions(watchDocumentType);
  const showHmbpWarning =
    hmbpFeature &&
    (documentOption ===
      CA_DocumentOption[CA_DocumentOption["Provided Elsewhere"]] ||
      documentOption === CA_DocumentOption[CA_DocumentOption["Exempt"]]);

  const isInvalidSds =
    watchDocumentType === DocumentType.SafetyDataSheet &&
    watchFileExtension?.toLowerCase() !== "pdf";

  const uncheckedRequiredAttestation = showAttestation && !attestation;

  const saveDisabled = useMemo(() => {
    return (
      hasCriticalIssues(issues) || isInvalidSds || uncheckedRequiredAttestation
    );
  }, [issues, isInvalidSds, uncheckedRequiredAttestation]);

  useEffect(() => {
    if (watchTitle && (!watchFileExtension || watchFileExtension === "")) {
      const match = /\.[0-9a-z]+$/i.exec(watchTitle);
      if (match) {
        setValue("fileExtension", match[0].slice(1)); // Remove the leading "."
      }
    }
  }, [watchTitle, setValue, watchFileExtension]);

  const activitySelectItems = useMemo(() => {
    if (activityPickerOptions === undefined) {
      return undefined;
    }
    const options = activityPickerOptions.map((v) => ({
      display: v.title,
      value: v.id as string | null,
    }));
    if (watchedActivityId) {
      options.unshift({ display: "None", value: null });
    }
    return options;
  }, [watchedActivityId, activityPickerOptions]);

  useEffect(() => {
    let msg = "";
    const titleAndExtensionIssues = issues?.filter(
      (issue) => issue.key === "title" || issue.key === "fileExtension"
    );

    if (!titleAndExtensionIssues?.length) {
      clearErrors("title");
      return;
    }

    for (const issue of titleAndExtensionIssues ?? []) {
      if (msg === "") {
        msg += issue.message;
      } else {
        msg += "|" + issue.message;
      }
    }

    setError("title", { message: msg });
  }, [issues, setError, clearErrors]);

  // dialogState.selectedFile is set when we are adding a document
  // data?.getDocumentDownloadLink is set when we are editing a document
  // There are both b/c one is a File and the other is a string link, and it's not worth the
  // pain to unify them
  const hasSelectedFile = useMemo(() => {
    return !!(dialogState.selectedFile || data?.getDocumentDownloadLink);
  }, [dialogState.selectedFile, data?.getDocumentDownloadLink]);

  // Memoize the URL so that the document preview will not unnecessarily reload which causes it to flash
  const fileUrl = useMemo(() => {
    return dialogState.selectedFile
      ? URL.createObjectURL(dialogState.selectedFile ?? new Blob())
      : data?.getDocumentDownloadLink ?? "";
  }, [dialogState.selectedFile, data?.getDocumentDownloadLink]);

  // Wait until we have a user before rendering
  if (!user) {
    return null;
  }

  const removeDocumentIdFromUrl = () => {
    const pathSegments = location.pathname.split("/").filter(Boolean);
    // Only do this if we are on the documents list page and there is a document id in the url
    if (pathSegments.length === 4 && pathSegments[2] === "documents") {
      pathSegments.pop(); // Remove the last segment

      const newPath = `/${pathSegments.join("/")}${location.search}`;
      navigate(newPath);
    }
  };

  const onClose = () => {
    setDialogState(defaultAddEditDocumentDialogState);
    reset();
    setAttestation(false);
    removeDocumentIdFromUrl();
  };

  const caDocumentTypes = Object.values(
    CaDocumentType
  ) as unknown as DocumentType[];
  const defaultDocumentTypeOptions = Object.values(DocumentType).filter(
    (x) => !caDocumentTypes.includes(x)
  );

  const selectedDocumentType = dialogState.documentType;

  const documentPickerOptions = [
    ...new Set(
      [
        ...(documentTypeOptions ?? defaultDocumentTypeOptions),
        // include the already selected document type in case it's not in the list
        ...(selectedDocumentType ? [selectedDocumentType] : []),
      ].map((v) => ({
        label: documentTypeToLabel(v),
        value: v,
      }))
    ),
  ];

  const handleFileSelect = async (files: File[]) => {
    const file = files?.[0];
    if (!file) {
      return;
    }

    const match = /\.[0-9a-z]+$/i.exec(file.name);
    const fileExtension = match ? match[0] : "";

    setDialogState((state) => ({
      ...state,
      selectedFile: file,
      step: FormState.FORM,
    }));
    setValue("fileExtension", fileExtension);
    setValue("title", standardizeDocumentTitle?.(fileExtension) ?? file.name);
    setValue("fileSize", file.size);
    await trigger(["title", "fileExtension"]);
  };

  const facilityId =
    dialogState.facilities?.length === 1
      ? dialogState.facilities[0].id
      : undefined;

  const onAddSubmit: SubmitHandler<DocumentFormData> = async (data) => {
    // Since the File type is immutable, create a new file
    // with the name from the form submission
    const file = dialogState.selectedFile
      ? new File(
          [dialogState.selectedFile],
          data.title ?? dialogState.selectedFile.name,
          { type: dialogState.selectedFile.type }
        )
      : undefined;

    const associations: DocumentAssociationsInput[] = getAssociations(
      data,
      tenantId,
      reportId,
      facilityId
    );

    await uploadFile({
      file,
      documentData: {
        id: data.id,
        tenantId: tenantId ?? "",
        documentType: data.documentType ?? DocumentType.Other,
        title: data.title ?? file?.name,
        description: data.description,
        documentTags: data.documentTags,
        authoredAt: data.authoredAt,
        storageLink: data.storageLink ?? undefined,
      },
      associations,
      currentSubmissionDocument:
        facilityDocumentIssues?.some(
          (i) =>
            i.key === "facilityDocuments" &&
            i.metadata.documentType === data.documentType
        ) ||
        dialogState.currentSubmissionDocument ||
        false,
    });
  };

  const onEditSubmit: SubmitHandler<DocumentFormData> = async (data) => {
    try {
      const associations: DocumentAssociationsInput[] = getAssociations(
        data,
        tenantId,
        reportId,
        facilityId
      );

      if (dialogState?.selectedFile) {
        // Since the File type is immutable, create a new file
        // with the name from the form submission
        const file = new File(
          [dialogState.selectedFile],
          data.title ?? dialogState.selectedFile.name,
          { type: dialogState.selectedFile.type }
        );

        await uploadFile({
          file,
          documentData: {
            id: dialogState.documentId,
            tenantId: tenantId ?? "",
            documentType: data.documentType ?? DocumentType.Other,
            title: file.name,
            description: data.description,
            documentTags: data.documentTags,
            authoredAt: data.authoredAt,
          },
          associations,
          currentSubmissionDocument:
            dialogState.currentSubmissionDocument ||
            data.currentSubmissionDocument ||
            false,
        });
      } else {
        setDialogState((state) => ({ ...state, saving: true }));
        const documentId = await upsertDocument({
          documentData: {
            id: dialogState.documentId,
            tenantId: tenantId ?? "",
            documentType: data.documentType,
            title: data.title,
            description: data.description,
            documentTags: data.documentTags,
            fileExtension: data.fileExtension,
            storageLink: data.storageLink,
            authoredAt: data.authoredAt,
          },
          generateTitleOnCreate: generateTitleOnCreate,
          associations,
          currentSubmissionDocument:
            dialogState.currentSubmissionDocument ||
            data.currentSubmissionDocument ||
            false,
        });
        const { data: upsertedDocumentData } = await fetchUpsertedDocument({
          variables: { id: documentId ?? "" },
        });
        const document = upsertedDocumentData?.document;

        if (
          hmbpFeature &&
          data.documentType &&
          isHmbpDocumentType(data.documentType)
        ) {
          await setDocumentOptionToUpload(data.documentType);
        }

        onClose();
        if (onSubmit) {
          onSubmit(
            document ?? {
              id: dialogState.documentId ?? "",
              title: data.title ?? dialogState?.selectedFile?.name ?? "",
              documentType: data.documentType ?? DocumentType.Other,
              fileExtension: data.fileExtension ?? "",
            },
            dialogState
          );
        }
        reset();
        setAttestation(false);
        alerts.success("File successfully edited");
      }
    } catch (err) {
      console.error(`${err}`);
      alerts.error("An error occurred while editing the document");
      setDialogState((state) => ({ ...state, saving: false }));
    }
  };

  const handleDocumentPick = (document: FacilityDocumentPickerItem) => {
    setPickedDocument(document);
    setDialogState((state) => ({
      ...state,
      documentId: document.id,
      title: document.title,
      documentType: document.documentType,
      fileExtension: document.fileExtension,
      storageLink: document.storageLink,
      description: document.description,
      authoredAt: document.authoredAt,
      step: FormState.FORM,
    }));
  };

  const showFacilityDocumentPicker =
    dialogState.mode === EditMode.ADD_OR_PICK && facilityId;

  return (
    <Dialog
      open={dialogState.open}
      onClose={onClose}
      fullWidth
      maxWidth={hasSelectedFile ? "lg" : "xs"}
      sx={{ minHeight: theme.spacing(59) }}
    >
      <form
        onSubmit={handleSubmit(
          // TODO: We can consolidate these two handlers into one. The logic should be the same now that we are using the upsertDocument hook which handles adding, editing and file upload.
          dialogState.mode === "Add" ? onAddSubmit : onEditSubmit
        )}
      >
        <DialogTitle>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="h6">
              {dialogState.mode &&
                `${
                  dialogState.mode === EditMode.ADD_OR_PICK
                    ? "Add"
                    : dialogState.mode
                }${
                  dialogState.documentType
                    ? ` ${documentTypeToLabel(dialogState.documentType)}`
                    : ""
                } Document`}
            </Typography>
          </Stack>
        </DialogTitle>
        <DialogContent>
          {dialogState.step === "Upload" ? (
            <Stack spacing={2}>
              {showFacilityDocumentPicker && (
                <FacilityDocumentPicker
                  facilityId={facilityId ?? ""}
                  documentTypes={
                    dialogState.documentType
                      ? [dialogState.documentType]
                      : documentPickerOptions.map((o) => o.value)
                  }
                  onSelect={handleDocumentPick}
                  disabled={isUploading || dialogState.saving}
                />
              )}

              <Dropzone
                label={"Drag and drop your document or click to select file"}
                dragActiveText="Drop the file here"
                onDrop={(acceptedFiles) => {
                  handleFileSelect(acceptedFiles);
                }}
                sx={{
                  minWidth: theme.spacing(48),
                  minHeight: theme.spacing(29),
                }}
                disabled={isUploading || dialogState.saving}
              />
            </Stack>
          ) : (
            <Stack
              spacing={theme.spacing(3)}
              direction="row"
              justifyContent="space-between"
            >
              <Stack gap={theme.spacing(1)} sx={{ flex: 1 }}>
                <Grid container spacing={2} columns={12}>
                  <Grid item xs={11} mt={theme.spacing(1)}>
                    {generateTitleOnCreate &&
                      dialogState.mode === EditMode.ADD && (
                        <Typography
                          variant="body2"
                          color="text.secondary"
                          sx={{ pb: theme.spacing(1) }}
                        >
                          This document will be renamed to match the format
                          "DocumentType_FacilityName_Date.fileExtension"
                        </Typography>
                      )}
                    <FormTextField
                      name="title"
                      label="Filename *"
                      control={control}
                      sx={{ marginTop: theme.spacing(1) }}
                      disabled={shouldFieldsBeReadOnly}
                    />
                  </Grid>
                  {!shouldFieldsBeReadOnly && (
                    <Grid
                      item
                      xs={1}
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        paddingBottom: theme.spacing(2.5),
                      }}
                    >
                      <IconButton
                        onClick={() => {
                          setDialogState((state) => ({
                            ...state,
                            step: FormState.UPLOAD,
                            selectedFile: undefined,
                            fileExtension: undefined,
                          }));
                        }}
                      >
                        <FindReplaceIcon color="primary" fontSize="medium" />
                      </IconButton>
                    </Grid>
                  )}
                </Grid>

                <div style={{ display: "none" }}>
                  <FormTextField
                    name="fileExtension"
                    hideErrors
                    label="File Extension"
                    control={control}
                    disabled={shouldFieldsBeReadOnly}
                  />
                </div>

                <div style={{ display: "none" }}>
                  <FormTextField
                    name="storageLink"
                    hideErrors
                    label="Storage Link"
                    control={control}
                    disabled={shouldFieldsBeReadOnly}
                  />
                </div>

                <div style={{ display: "none" }}>
                  <FormTextField
                    name="currentSubmissionDocument"
                    hideErrors
                    label="Current Submission Document"
                    control={control}
                    disabled={shouldFieldsBeReadOnly}
                  />
                </div>

                {issues?.find((issue) => issue.key === "fileSize") && (
                  <Typography
                    color="error"
                    sx={{ mt: 0, pt: 0, mb: theme.spacing(1) }}
                  >
                    {issues?.find((issue) => issue.key === "fileSize")?.message}
                  </Typography>
                )}
                <FormAutocomplete
                  label="Type *"
                  control={control}
                  name="documentType"
                  autocompleteItems={documentPickerOptions}
                  disabled={shouldFieldsBeReadOnly}
                />
                {isInvalidSds && (
                  <Typography
                    color="error"
                    sx={{ mt: 0, pt: 0, mb: theme.spacing(2) }}
                  >
                    Safety Data Sheets must be in PDF format
                  </Typography>
                )}
                {showHmbpWarning && (
                  <Typography
                    color="error"
                    sx={{ mt: 0, pt: 0, mb: theme.spacing(2) }}
                  >
                    {`${
                      prettyPrintDocumentType(dialogState.documentType) ??
                      "This document type"
                    } was previously designated as "${documentOption}" and will be changed to the "Upload Document" option.`}
                  </Typography>
                )}
                <DateField
                  name="authoredAt"
                  label="Date Authored"
                  control={control}
                  disabled={shouldFieldsBeReadOnly}
                />

                <FormTextField
                  name="description"
                  label="Description"
                  control={control}
                  disabled={shouldFieldsBeReadOnly}
                  textFieldProps={{
                    multiline: true,
                    rows: 3,
                  }}
                />

                {activitySelectItems && (
                  <FormSelect
                    name="activityId"
                    label="Activity"
                    control={control}
                    selectItems={activitySelectItems}
                    disabled={shouldFieldsBeReadOnly}
                  />
                )}
                {showTagPicker && (
                  <Controller
                    name="documentTags"
                    control={control}
                    render={({ field, fieldState }) => (
                      <TagPicker
                        {...field}
                        {...fieldState}
                        tagType={TagType.Document}
                        label="Tags"
                        disabled={shouldFieldsBeReadOnly}
                      />
                    )}
                  />
                )}
                <Controller
                  name="facilities"
                  control={control}
                  render={({ field, fieldState }) => (
                    <MultipleFacilityPicker
                      {...field}
                      {...fieldState}
                      sx={{
                        display: showFacilityPicker ? "block" : "none",
                        minHeight: theme.spacing(16.5),
                      }}
                      value={
                        defaultValues.facilities?.map((f) => {
                          return { id: f.id ?? "", name: f.name ?? "" };
                        }) ?? []
                      }
                      defaultSearchTerm={`tenantId:${tenantId}`}
                      lazyLoad={false}
                      label="Facilities"
                      onChange={(facilities) => {
                        field.onChange(facilities ?? []);
                      }}
                      disabled={shouldFieldsBeReadOnly}
                    />
                  )}
                />
              </Stack>
              {hasSelectedFile && (
                <Box
                  sx={{
                    flex: 2,
                    position: "sticky",
                    height: "48vh",
                  }}
                >
                  <DocumentPreview
                    fileUrl={fileUrl}
                    noPreviewAvailable={
                      !(
                        watchFileExtension?.toLowerCase() === "pdf" ||
                        dialogState?.fileExtension?.toLowerCase() === "pdf"
                      )
                    }
                  />
                </Box>
              )}
            </Stack>
          )}
        </DialogContent>
        <DialogActions sx={{ p: theme.spacing(2) }}>
          <Stack
            direction="row"
            justifyContent={
              dialogState.documentId || hasSelectedFile
                ? "space-between"
                : "flex-end"
            }
            flex={1}
          >
            <Stack direction="row" alignItems="center" gap={theme.spacing(1)}>
              {dialogState.documentId && (
                <Button
                  onClick={async () => {
                    await fetchAndHandleDownload({
                      documentId: dialogState.documentId ?? "",
                    });
                    clearDownloadLink();
                  }}
                  endIcon={<Download />}
                >
                  Download
                </Button>
              )}

              {hasSelectedFile && showAttestation && (
                <Stack direction="row" alignItems="center">
                  <Checkbox
                    onChange={(event) => setAttestation(event.target.checked)}
                  />
                  <Typography variant="body2">
                    I reviewed and can confirm that all information and
                    documents provided are correct
                  </Typography>
                </Stack>
              )}
            </Stack>
            <Box sx={{ display: "flex", gap: theme.spacing(2) }}>
              <Button onClick={onClose} variant="outlined">
                Cancel
              </Button>
              <SaveButton
                loading={
                  isUploading || dialogState.saving || loadingStateFields
                }
                disabled={saveDisabled}
              />
            </Box>
          </Stack>
        </DialogActions>
      </form>
    </Dialog>
  );
}

function getAssociations(
  data: DocumentFormData,
  tenantId: string,
  reportId: string | undefined,
  facilityId: string | undefined
): DocumentAssociationsInput[] {
  return [
    ...(data.facilities ?? []).map((f) => ({
      tenantId: tenantId ?? "",
      facilityId: f.id,
    })),
    ...(reportId
      ? [
          {
            tenantId: tenantId ?? "",
            facilityId: facilityId ?? "",
            reportId,
          },
        ]
      : []),
    ...(data.activityId ? [{ activityId: data.activityId }] : []),
  ];
}
