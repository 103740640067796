import { PaymentMethod, PaymentStatus } from "generated-graphql/graphql";
import * as yup from "yup";
import { PayeeFormInputs } from "./PaymentDialog";

export enum PaymentType {
  LOB_CHECK = "lob",
  RECORD_PAYMENT = "payment",
}

export const validationSchema = yup.object({
  status: yup
    .string()
    .nullable()
    .test("disable-editing-based-on-status", function (this) {
      const { status } = this.parent as PayeeFormInputs;

      if (status === PaymentStatus.Pending) {
        return this.createError({
          message: `Editing is disabled because the payment is currently in "${status}" status.`,
        });
      }

      return true;
    }),
  type: yup.string().nullable().required("Select a payment type"),
  payeeType: yup.string().nullable().required("Required"),
  recipientName: yup
    .string()
    .nullable()
    .required("Required")
    .when("type", {
      is: PaymentType.LOB_CHECK,
      then: yup
        .string()
        .nullable()
        .required("Required")
        .max(40, "Recipient cannot be more than 40 characters"),
    }),
  companyName: yup
    .string()
    .nullable()
    .when("type", {
      is: PaymentType.LOB_CHECK,
      then: yup
        .string()
        .nullable()
        .max(40, "Company cannot be more than 40 characters"),
    }),
  addressLine1: yup
    .string()
    .nullable()
    .when("type", {
      is: PaymentType.LOB_CHECK,
      then: yup
        .string()
        .nullable()
        .required("Required")
        .max(64, "Address 1 cannot be more than 64 characters")
        .test(
          "address-combined-length",
          "Combined address lines must not exceed 50 characters",
          function () {
            const { addressLine1, addressLine2 } = this
              .parent as PayeeFormInputs;
            const combinedLength =
              (addressLine1?.length || 0) + (addressLine2?.length || 0);
            return combinedLength <= 50;
          }
        ),
    }),
  addressLine2: yup
    .string()
    .nullable()
    .when("type", {
      is: PaymentType.LOB_CHECK,
      then: yup
        .string()
        .nullable()
        .max(64, "Address 2 cannot be more than 64 characters"),
    }),
  city: yup
    .string()
    .nullable()
    .when("type", {
      is: PaymentType.LOB_CHECK,
      then: yup.string().nullable().required("Required"),
    }),
  state: yup
    .string()
    .nullable()
    .when("type", {
      is: PaymentType.LOB_CHECK,
      then: yup.string().nullable().required("Required"),
    }),
  zip: yup
    .string()
    .nullable()
    .when("type", {
      is: PaymentType.LOB_CHECK,
      then: yup.string().nullable().required("Required"),
    }),
  memo: yup
    .string()
    .nullable()
    .when("type", {
      is: PaymentType.LOB_CHECK,
      then: yup
        .string()
        .nullable()
        .max(40, "Memo cannot be more than 40 characters"),
    }),
  invoiceNumber: yup.string().nullable(),
  paymentMethod: yup
    .string()
    .nullable()
    .required("Required")
    .when("type", {
      is: PaymentType.LOB_CHECK,
      then: yup
        .string()
        .nullable()
        .test(
          "is-check",
          "Payment method must be Check",
          (value) => value === PaymentMethod.Check
        ),
    }),
  amount: yup
    .number()
    .nullable()
    .required("Required")
    .test(
      "matches-sum-of-items",
      "Total amount must equal the sum of all item amounts",
      function (this, totalAmount) {
        const { associations } = this.parent as PayeeFormInputs;

        if (associations.length) {
          // Multiply by 100 to avoid floating point errors
          const factor = 100;
          const intSumOfItems = associations.reduce(
            (sum, item) => sum + Math.round(item.amount * factor),
            0
          );
          return totalAmount === intSumOfItems / factor;
        }
        return true;
      }
    ),
  invoiceDocument: yup
    .mixed<Document>()
    .nullable()
    .required("An invoice is required")
    .when(["status"], (status: PaymentStatus, schema) => {
      if ([PaymentStatus.Pending].includes(status)) {
        return schema.notRequired();
      }
      return schema;
    }),
  receiptDocument: yup.mixed<Document>().when(["type", "status"], {
    is: (type: PaymentType, status: PaymentStatus) =>
      type === PaymentType.RECORD_PAYMENT && status !== PaymentStatus.Pending,
    then: yup.mixed<Document>().required("A receipt is required"),
  }),
  associations: yup.array().of(
    yup.object().shape({
      amount: yup.number().required("Required"),
    })
  ),
});
