import { useCallback } from "react";
import { SaveButton } from "components/SaveButton";
import { hasCriticalIssues } from "util/forms";
import { IssueListButton } from "components/Forms/IssueListButton";
import {
  Box,
  Button,
  Card,
  CardContent,
  FormControl,
  Typography,
  Stack,
  Skeleton,
} from "@mui/material";
import { useMemo } from "react";
import { FormProvider } from "react-hook-form";
import { OrganizationStep } from "./steps/OrganizationStep";
import { ReportsStep } from "./steps/ReportsStep";
import { DocumentsStep } from "./steps/DocumentsStep";
import { RecipientsStep } from "./steps/RecipientsStep";
import { useMutation, useQuery } from "@apollo/client";
import {
  transformCommunicationFormDataToInput,
  useCommunicationForm,
  useCommunicationFormContext,
  CommunicationFormType,
} from "./hooks/useCommunicationForm";
import {
  CREATE_COMMUNICATIONS_MUTATION,
  COMMUNICATION_QUERY,
  UPDATE_COMMUNICATION_MUTATION,
} from "../api";
import { useAlerts } from "components/Alerts/AlertProvider";
import { useNavigate, useParams } from "react-router-dom";

const CommunicationFormContent = () => {
  const alerts = useAlerts();
  const navigate = useNavigate();
  const { communicationId } = useParams<{ communicationId: string }>();
  const { mode, handleSubmit, issues, formState } =
    useCommunicationFormContext();
  const disabled = mode === "view";

  const [createCommunication, { loading: createLoading }] = useMutation(
    CREATE_COMMUNICATIONS_MUTATION
  );
  const [updateCommunication, { loading: updateLoading }] = useMutation(
    UPDATE_COMMUNICATION_MUTATION
  );
  const loading = useMemo(
    () => createLoading || updateLoading,
    [createLoading, updateLoading]
  );

  const onSubmit = useCallback(
    async (formData: CommunicationFormType) => {
      const input = transformCommunicationFormDataToInput(formData);

      if (communicationId) {
        const { data } = await updateCommunication({
          variables: {
            id: communicationId,
            input,
            draft: false,
          },
        });
        if (data?.updateCommunication) {
          alerts.success("Communication updated successfully");
          navigate(`/staff/mail-room/status/${communicationId}`);
        }
      } else {
        const { data } = await createCommunication({
          variables: { input },
        });
        if (data?.createCommunication) {
          alerts.success("Communications created successfully");
          navigate(`/staff/mail-room/status/${data.createCommunication.id}`);
        }
      }
    },
    [
      createCommunication,
      updateCommunication,
      alerts,
      navigate,
      communicationId,
    ]
  );

  const handleFormSubmit = useCallback(
    () => handleSubmit((formData) => onSubmit(formData))(),
    [handleSubmit, onSubmit]
  );

  const title = useMemo(() => {
    const capitalizedMode = mode.charAt(0).toUpperCase() + mode.slice(1);
    return `${capitalizedMode} Communication`;
  }, [mode]);

  return (
    <Box sx={{ maxWidth: 1200, mx: "auto", p: 3 }}>
      <Card>
        <CardContent>
          <Typography variant="h5" gutterBottom>
            {title}
          </Typography>

          <Stack direction="column" gap={2}>
            <OrganizationStep />
            <ReportsStep />
            <DocumentsStep />
            <RecipientsStep />
          </Stack>

          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              mt: 3,
              gap: 1,
            }}
          >
            <IssueListButton issues={issues} />
            <FormControl>
              <Button
                variant="outlined"
                disabled={loading || disabled}
                onClick={() => navigate(`/staff/mail-room`)}
              >
                Cancel
              </Button>
            </FormControl>
            <FormControl>
              <SaveButton
                saveText={title}
                loading={loading}
                disabled={
                  hasCriticalIssues(issues) ||
                  loading ||
                  disabled ||
                  !formState.isDirty
                }
                onClick={handleFormSubmit}
              />
            </FormControl>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export const CreateCommunications = () => {
  const { communicationId } = useParams<{ communicationId: string }>();
  const { data: communicationData, loading: communicationLoading } = useQuery(
    COMMUNICATION_QUERY,
    {
      variables: { id: communicationId ?? "" },
      skip: !communicationId,
    }
  );

  const methods = useCommunicationForm(communicationData?.communication);
  if (communicationLoading)
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        }}
      >
        <Skeleton variant="rectangular" height={1000} />
      </Box>
    );
  return (
    <FormProvider {...methods}>
      <CommunicationFormContent />
    </FormProvider>
  );
};
