import {
  Button,
  DialogActions,
  DialogContent,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { FormTextField } from "components/Forms/FormTextField";
import { SaveButton } from "components/SaveButton";
import { useFormContext } from "react-hook-form";
import { Fragment } from "react/jsx-runtime";

type ExemptProps = {
  handleBack: () => void;
  loading?: boolean;
};

export function PublicInternetURL({ handleBack, loading }: ExemptProps) {
  const theme = useTheme();
  const { control, watch } = useFormContext();

  const url: string | null = watch("publicInternetURL");

  return (
    <Fragment>
      <DialogContent>
        <Typography variant="body1" marginBottom={theme.spacing(2)}>
          Available at a Public URL
        </Typography>

        <Typography variant="body2" marginBottom={theme.spacing(2)}>
          You may use this option if acceptable by your local regulator AND if
          the document is publicly available on a non-password protected
          location on the Internet. Enter a web address (URL) that either links
          directly to the document, or links to a page that can be quickly
          scanned to find the link to this document.
        </Typography>

        <FormTextField
          control={control}
          name="publicInternetURL"
          label="Public Internet URL"
          textFieldProps={{
            required: true,
          }}
        />
      </DialogContent>
      <DialogActions>
        <Stack
          direction="row"
          gap={theme.spacing(2)}
          justifyContent="flex-end"
          sx={{ marginTop: theme.spacing(2) }}
        >
          <Button onClick={handleBack} variant="outlined">
            Back
          </Button>
          <SaveButton loading={loading ?? false} disabled={url === null} />
        </Stack>
      </DialogActions>
    </Fragment>
  );
}
