import { reportStepMetadata } from "util/constants";
import { StepperPageHeader } from "./Report/StepperPageHeader";
import { ReportingFacilityChemicalTable } from "./ReportingFacilityChemical/ReportingFacilityChemicalTable";
import { Issue } from "generated-graphql/graphql";

export type ChemicalSummaryTableProps = {
  facilityId: string;
  reportId?: string;
};

export function ChemicalSummaryTable({
  facilityId,
  reportId,
}: ChemicalSummaryTableProps) {
  return (
    <>
      <StepperPageHeader
        header={reportStepMetadata.CHEMICAL_SUMMARY.title}
        description={reportStepMetadata.CHEMICAL_SUMMARY.overviewDescription}
      />
      <ReportingFacilityChemicalTable
        facilityId={facilityId}
        reportId={reportId}
      />
    </>
  );
}
