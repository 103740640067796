import Close from "@mui/icons-material/Close";
import { Box, IconButton, Stack, useMediaQuery, useTheme } from "@mui/material";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import { Fragment } from "react";
import EncampLogo from "../assets/EncampLogo.svg";
import { useLocation } from "react-router-dom";
import { type StandardNavItem, NavItem } from "./NavItem";

type CustomNavItem = {
  kind: "CustomElement";
  customElement: JSX.Element;
};

export type NavItem = StandardNavItem | CustomNavItem;

interface NavDrawerProps {
  navItems: NavItem[];
  open?: boolean;
  drawerWidth: string;
  onClose?: () => void;
}

export function LeftNavDrawer({
  navItems,
  open = true,
  drawerWidth,
  onClose = () => {},
}: NavDrawerProps) {
  const location = useLocation();
  const pathnames = location.pathname.split("/").filter((x) => x);
  const selected = pathnames[pathnames.length - 1];
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Drawer
      variant={"persistent"}
      open={open}
      sx={{
        display: open ? "block" : "none",
        width: {
          md: open ? drawerWidth : 0,
          xs: open ? "100vw" : 0,
        },
      }}
    >
      <Box
        sx={{
          width: isLargeScreen ? drawerWidth : "100vw",
          position: "relative",
          flex: 1,
          backgroundColor:
            theme.palette.mode === "light"
              ? "#051E3C"
              : theme.palette.background.default,
          overflowY: "auto",
        }}
      >
        <IconButton
          sx={{
            zIndex: 99999,
            position: "absolute",
            color: "white",
            top: 0,
            right: 0,
            display: isLargeScreen ? "none" : "box",
          }}
          onClick={onClose}
        >
          <Close sx={{ fontSize: "3rem" }} />
        </IconButton>
        <Stack
          sx={{
            height: {
              md: "150px",
              xs: "50px",
            },
            marginTop: {
              md: 0,
              xs: "20px",
            },
            marginBottom: {
              md: 0,
              xs: "20px",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginLeft: "auto",
              marginRight: "auto",
              marginTop: "auto",
              marginBottom: "auto",
            }}
          >
            <img
              src={EncampLogo}
              alt="Encamp logo"
              style={{
                width: isLargeScreen ? "40%" : "25%",
              }}
            />
          </Box>
        </Stack>
        <List
          sx={{
            paddingTop: {
              md: 0,
              xs: 0,
            },
          }}
        >
          {navItems.map((navItem, idx) => {
            if (navItem.kind === "CustomElement") {
              return <Fragment key={idx}>{navItem.customElement}</Fragment>;
            } else {
              return (
                <NavItem
                  key={idx}
                  navItem={navItem}
                  isLargeScreen={isLargeScreen}
                  onClose={onClose}
                />
              );
            }
          })}
        </List>
      </Box>
    </Drawer>
  );
}
