import { Stack, Typography, IconButton, Link } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import DownloadIcon from "@mui/icons-material/Download";
import { DocumentsForCommunicationQuery } from "generated-graphql/graphql";

type GraphQLDocument = NonNullable<
  DocumentsForCommunicationQuery["documents"]["items"]
>[number];

interface DocumentItemProps {
  document: GraphQLDocument;
  onDelete: () => void;
  onDownload: () => void;
}

export const DocumentItem = ({
  document,
  onDelete,
  onDownload,
}: DocumentItemProps) => {
  return (
    <Stack
      direction="row"
      spacing={1}
      alignItems="center"
      justifyContent="space-between"
      sx={{
        p: 1,
        bgcolor: "background.paper",
        borderRadius: 1,
        border: "1px solid",
        borderColor: "divider",
      }}
    >
      <Link
        href="#"
        onClick={(e) => {
          e.preventDefault();
          onDownload();
        }}
        sx={{ textDecoration: "none" }}
      >
        <Typography variant="body2">{document.title}</Typography>
      </Link>
      <Stack direction="row" spacing={1}>
        <IconButton size="small" onClick={onDownload} title="Download">
          <DownloadIcon fontSize="small" />
        </IconButton>
        <IconButton
          size="small"
          onClick={onDelete}
          title="Delete"
          color="error"
        >
          <DeleteIcon fontSize="small" />
        </IconButton>
      </Stack>
    </Stack>
  );
};
