import { gql } from "generated-graphql";

gql(`
  fragment CommunicationFragment on Communication {
    id
    tenantId
    tenant {
      id
      name
    }
    facilities {
      id
      name
      customerFacilityId
      state
    }
    tierIIReports {
        id
        reportKind
        reportingYear
        facilityId
        facility {
          id
          name
          customerFacilityId
          streetAddress1
          city
          state
          zip
        }
    }
    recipientName
    communicationType
    status

    emailRecipientAddresses
    emailSubject
    emailFrom
    emailReplyTo
    emailTemplateId

    mailRecipientAddressLine1
    mailRecipientAddressLine2
    mailRecipientCity
    mailRecipientState
    mailRecipientZip
    attachments {
      id
      title
      documentType
      storageLink
    }
    proofDocument {
      id
      title
    }
    lepcId
    lepc {
      ...LepcPicker
    }
    fireDepartmentId
    fireDepartment {
      ...FireDepartmentPicker
    }
    policeDepartmentId
    policeDepartment {
      ...PoliceDepartmentPicker
    }
    leadAgencyId
    leadAgency {
      ...LeadAgencyPicker
    }
    completedByUser {
      id
      email
    }
    completedAt
    createdAt
    createdBy
    updatedAt
    updatedBy
  }
`);

export const COMMUNICATIONS_QUERY = gql(`
  query Communications($search: String, $page: Int, $pageSize: Int, $sort: [SortModel!]) {
    communications(search: $search, page: $page, pageSize: $pageSize, sort: $sort) {
      items {
        ...CommunicationFragment
      }
      count
    }
  }
`);

export const COMMUNICATION_QUERY = gql(`
  query Communication($id: ID!) {
    communication(id: $id) {
      ...CommunicationFragment
    }
  }
`);

export const UPDATE_COMMUNICATION_MUTATION = gql(`
  mutation UpdateCommunication($id: ID!, $input: CreateCommunicationInput!, $draft: Boolean) {
    updateCommunication(id: $id, input: $input, draft: $draft) {
      ...CommunicationFragment
    }
  }
`);

export const CREATE_COMMUNICATIONS_MUTATION = gql(`
  mutation CreateCommunication($input: CreateCommunicationInput!, $draft: Boolean) {
    createCommunication(input: $input, draft: $draft) {
      ...CommunicationFragment
    }
  }
`);

export const COMMUNICATION_VALIDATOR_QUERY = gql(`
  query CommunicationValidator($input: CreateCommunicationInput!) {
    communicationValidator(input: $input) {
      ...issue
    }
  }
`);
