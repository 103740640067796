import {
  Stack,
  Typography,
  useTheme,
  IconButton,
  Tooltip,
} from "@mui/material";
import { OmnisearchDataGrid } from "components/OmnisearchDataGrid";
import {
  CommunicationsQuery,
  CommunicationsQueryVariables,
  CommunicationStatus,
  CommunicationType,
} from "generated-graphql/graphql";
import { useSelectedIdInUrl } from "hooks/useSelectedIdInUrl";
import { OmnisearchGridColDef } from "hooks/useOmnisearchDatagridSettings";
import { useCallback, useMemo } from "react";
import { prettyPrintDateTime } from "util/dates";
import { COMMUNICATIONS_QUERY } from "./api";
import { prettyPrintEnumValue } from "encamp-shared/src/utils/prettyPrintEnumValue";
import { PaletteColor } from "@mui/material/styles";
import {
  RecipientType,
  getFacilityName,
  formatAddress,
  formatCommunicationType,
} from "./utils";
import OpenInNew from "@mui/icons-material/OpenInNew";
import { useLazyQuery } from "@apollo/client";
import GET_DOCUMENT_DOWNLOAD_LINK_QUERY from "queries/getDocumentDownloadLink";

type CommunicationRow = CommunicationsQuery["communications"]["items"][number];

export const CommunicationsTable = () => {
  const theme = useTheme();
  const [getDocumentDownloadLink] = useLazyQuery(
    GET_DOCUMENT_DOWNLOAD_LINK_QUERY
  );

  const {
    selectedId: communicationId,
    pushIdToUrl,
    popIdFromUrl,
  } = useSelectedIdInUrl("communicationId");

  const getCommunicationStatusColorCallback = useCallback(
    (status: CommunicationStatus | undefined | null) => {
      switch (status) {
        case CommunicationStatus.SentToEmailClient:
        case CommunicationStatus.SentToMailClient:
          return theme.palette.success;
        case CommunicationStatus.Error:
          return theme.palette.error;
        case CommunicationStatus.Draft:
        case CommunicationStatus.Pending:
        default:
          return theme.palette.info;
      }
    },
    [theme]
  );

  const handleViewProof = useCallback(
    async (proofDocumentId: string) => {
      const { data } = await getDocumentDownloadLink({
        variables: { id: proofDocumentId },
      });
      if (data?.getDocumentDownloadLink) {
        window.open(data.getDocumentDownloadLink, "_blank");
      }
    },
    [getDocumentDownloadLink]
  );

  const columns: OmnisearchGridColDef<CommunicationRow>[] = useMemo(
    () => [
      {
        headerName: "Organization",
        field: "tenant",
        flex: 1,
        valueGetter(params) {
          return params.row.tenant.name;
        },
      },
      {
        headerName: "Facility/Facilities",
        field: "facilities",
        flex: 1.2,
        valueGetter(params) {
          const facilities = params.row.facilities.map((f) =>
            getFacilityName(f)
          );
          if (facilities.length <= 2) {
            return facilities.join(", ");
          }
          return `${facilities[0]}, ${facilities[1]} + ${
            facilities.length - 2
          } more`;
        },
      },
      {
        headerName: "Recipient Type",
        field: "recipientType",
        flex: 1,
        enumValues: Object.values(RecipientType),
        filterKeyType: "enum",
        valueGetter(params) {
          if (params.row.lepcId) {
            return RecipientType.LEPC;
          }
          if (params.row.fireDepartmentId) {
            return RecipientType.FIRE_DEPARTMENT;
          }
          if (params.row.policeDepartmentId) {
            return RecipientType.POLICE_DEPARTMENT;
          }
          if (params.row.leadAgencyId) {
            return RecipientType.LEAD_AGENCY;
          }
          return RecipientType.CUSTOM;
        },
        valueFormatter(params) {
          return prettyPrintEnumValue(params.value);
        },
      },
      {
        headerName: "Recipient",
        field: "recipientName",
        flex: 1,
      },
      {
        headerName: "Method",
        field: "communicationType",
        flex: 1,
        enumValues: Object.values(CommunicationType),
        filterKeyType: "enum",
        valueGetter(params) {
          return formatCommunicationType(params.row?.communicationType);
        },
      },
      {
        headerName: "Address",
        field: "address",
        flex: 1.2,
        valueGetter(params) {
          if (params.row.communicationType === CommunicationType.Email) {
            return params.row.emailRecipientAddresses.join(", ");
          }
          return formatAddress(params.row);
        },
      },
      {
        headerName: "Document(s)",
        field: "attachments",
        flex: 1.2,
        valueGetter(params) {
          return params.row.attachments.map((a) => a.title).join(", ");
        },
        renderCell({ value }: { value?: string }) {
          if (!value) return null;
          const documents = value.split(", ");
          if (documents.length <= 2) return value;

          return `${documents.slice(0, 2).join(", ")} + ${
            documents.length - 2
          } more`;
        },
      },
      {
        headerName: "Completed By",
        field: "completedByUser",
        flex: 1,
        valueGetter(params) {
          return params.row.completedByUser?.email;
        },
      },
      {
        headerName: "Sent At",
        field: "completedAt",
        flex: 1,
        filterKeyType: "date",
        valueGetter(params) {
          if (!params.row.completedAt) return null;
          return prettyPrintDateTime(params.row.completedAt);
        },
      },
      {
        headerName: "Status",
        field: "status",
        flex: 1,
        enumValues: Object.values(CommunicationStatus),
        filterKeyType: "enum",
        renderCell({ value }: { value?: CommunicationStatus }) {
          const color: PaletteColor =
            getCommunicationStatusColorCallback(value);
          const text = prettyPrintEnumValue(value);
          return (
            <Typography
              sx={{
                color: color.main,
              }}
            >
              {text}
            </Typography>
          );
        },
      },
      {
        headerName: "Created At",
        field: "createdAt",
        flex: 1,
        filterKeyType: "date",
        valueGetter(params) {
          if (!params.row.createdAt) return null;
          return prettyPrintDateTime(params.row.createdAt);
        },
      },
      {
        headerName: "Actions",
        field: "actions",
        flex: 0.5,
        sortable: false,
        filterable: false,
        renderCell: ({ row }) => {
          if (!row.proofDocument) return null;
          return (
            <Tooltip title="View Proof">
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  if (!row.proofDocument) return;
                  handleViewProof(row.proofDocument.id);
                }}
              >
                <OpenInNew />
              </IconButton>
            </Tooltip>
          );
        },
      },
    ],
    [getCommunicationStatusColorCallback, handleViewProof]
  );

  return (
    <Stack py={3}>
      <OmnisearchDataGrid<
        CommunicationsQuery,
        CommunicationsQueryVariables,
        CommunicationRow
      >
        persistenceKey="communications"
        rowHeight="auto"
        initialSortModel={[{ field: "createdAt", sort: "desc" }]}
        onRowClick={(params) => {
          pushIdToUrl(params.row.id);
        }}
        initialState={{
          columns: {
            columnVisibilityModel: {
              createdAt: false,
            },
          },
        }}
        columns={columns}
        dataQuery={COMMUNICATIONS_QUERY}
        getItems={(data) => data.communications.items}
        getCount={(data) => data.communications.count}
      />
    </Stack>
  );
};
