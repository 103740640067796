import { Skeleton, Stack } from "@mui/material";
import { HasFeature } from "components/HasFeature";
import { TierIiReportStep } from "generated-graphql/graphql";
import { ChemicalSummaryTable } from "../../ChemicalSummaryTable";
import { ReportFloatingBar } from "../ReportFloatingBar";
import {
  useReport,
  useReportingFacilityChemicalIssues,
  useReportingFacilityChemicalsIssues,
} from "../useReport";
import { useTouchReportMutation } from "../useTouchReportMutation";

export function Summary() {
  const { data } = useReport();
  const facilityId = data?.tierIIReport.facility.id ?? "";
  const reportId = data?.tierIIReport.id ?? "";
  const { handleSave, loading } = useTouchReportMutation(
    reportId,
    data?.tierIIReport.touchedSteps ?? [],
    TierIiReportStep.ChemicalSummary
  );

  const issues = useReportingFacilityChemicalIssues();
  const reportingFacilityChemicalsIssues =
    useReportingFacilityChemicalsIssues();

  if (!data?.tierIIReport) {
    return <Skeleton />;
  }

  return (
    <>
      <Stack
        sx={{
          height: "100%",
          flexDirection: "column",
        }}
      >
        <ChemicalSummaryTable facilityId={facilityId} reportId={reportId} />
      </Stack>
      <ReportFloatingBar
        onSaveClick={handleSave}
        loading={loading}
        disabledWithAnyIssues
        issues={issues}
        saveText="Mark as Complete"
      />
    </>
  );
}
