import Delete from "@mui/icons-material/Delete";
import { Button, Grid, IconButton, Typography, useTheme } from "@mui/material";
import { STATES_AND_PROVINCES } from "encamp-shared/src/constants/states";
import { Issue, PhoneType } from "generated-graphql/graphql";
import { useMemo } from "react";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import { formatPhoneType } from "util/phoneNumbers";
import { getTankOwnerTypeOptions } from "../../util/facilityContacts";
import { ErrorDisplay } from "../Forms/ErrorDisplay";
import { FormSelect } from "../Forms/FormSelect";
import { FormTextField } from "../Forms/FormTextField";
import { EditContactFormData } from "./EditContactDialog";

type Mode = "add" | "edit" | "displayOnly";
export const EditPerson = ({
  mode,
  loading,
  isUser,
  issues,
  isStepper = false,
  showTankOwnerType,
}: {
  mode?: Mode;
  loading?: boolean;
  isUser?: boolean;
  issues?: Issue[];
  isStepper?: boolean;
  showTankOwnerType?: boolean;
}) => {
  const theme = useTheme();

  const {
    control,
    formState: { errors },
  } = useFormContext<EditContactFormData>();

  const {
    fields: phones,
    append,
    remove,
  } = useFieldArray({ control, name: "person.phones", keyName: "uniqueId" });

  const disabled = mode === "displayOnly" || loading;
  const disableForStepper = isStepper && mode === "edit";

  const inferredPhoneType = useMemo(() => {
    if (issues?.some((issue) => issue.message.includes("24 hour phone"))) {
      return PhoneType.Hrs_24;
    }
    return PhoneType.Work;
  }, [issues]);

  return (
    <Grid
      container
      columnSpacing={theme.spacing(3)}
      rowSpacing={theme.spacing(1)}
    >
      <Grid item xs={6}>
        <FormTextField
          label="First Name"
          name="person.first"
          control={control}
          disabled={disableForStepper || disabled || isUser}
          hideErrors
        />
      </Grid>
      <Grid item xs={6}>
        <FormTextField
          label="Last Name"
          name="person.last"
          control={control}
          disabled={disableForStepper || disabled || isUser}
          textFieldProps={{ required: true, autoComplete: "off" }}
          hideErrors
        />
      </Grid>
      <Grid item xs={12}>
        <ErrorDisplay
          errors={errors}
          fields={["person.first", "person.last"]}
        />
      </Grid>
      <Grid item xs={12}>
        <FormTextField
          label="Email"
          name="person.email"
          control={control}
          disabled={disableForStepper || disabled || isUser}
        />
      </Grid>
      <Grid item xs={6}>
        <FormTextField
          label="Company"
          name="person.company"
          control={control}
          disabled={disabled}
          hideErrors
        />
      </Grid>
      <Grid item xs={6}>
        <FormTextField
          label="Title"
          name="person.title"
          control={control}
          disabled={disabled}
          hideErrors
        />
      </Grid>
      <Grid item xs={12}>
        <ErrorDisplay
          errors={errors}
          fields={["person.title", "person.company"]}
        />
      </Grid>

      {showTankOwnerType && (
        <Grid item xs={12}>
          <FormSelect
            label="Tank Owner Type"
            name="person.tankOwnerType"
            control={control}
            disabled={disabled}
            selectItems={getTankOwnerTypeOptions()}
          />
        </Grid>
      )}

      <Grid item xs={12} sx={{ mt: theme.spacing(1) }}>
        <Typography variant="h6" fontSize={16}>
          Phone Numbers
        </Typography>
      </Grid>
      <Grid item container xs={12}>
        <Controller
          control={control}
          name="person.phones"
          render={({ fieldState: { error } }) => (
            <>
              {phones.map((phone, index) => {
                return (
                  <Grid
                    sx={{ alignItems: "start", mb: theme.spacing(1.5) }}
                    container
                    spacing={3}
                    key={phone.uniqueId}
                  >
                    <Grid item xs={6}>
                      <FormSelect
                        selectItems={Object.values(PhoneType).map((type) => ({
                          value: type,
                          display: formatPhoneType(type),
                        }))}
                        label="Type"
                        name={`person.phones.${index}.type`}
                        control={control}
                        disabled={disabled}
                        hideErrors
                      />
                    </Grid>
                    <Grid item xs={5}>
                      <FormTextField
                        label="Number"
                        name={`person.phones.${index}.number`}
                        control={control}
                        disabled={disabled}
                        hideErrors
                      />
                    </Grid>
                    <Grid item xs={1}>
                      {mode !== "displayOnly" && (
                        <IconButton
                          sx={{ mt: theme.spacing(1) }}
                          onClick={() => remove(index)}
                          disabled={disabled}
                        >
                          <Delete />
                        </IconButton>
                      )}
                    </Grid>
                    <Grid item xs={12} sx={{ pt: "0 !important" }}>
                      <ErrorDisplay
                        errors={errors}
                        fields={[`person.phones.${index}.number`]}
                      />
                    </Grid>
                  </Grid>
                );
              })}
              {error?.message && (
                <Grid item xs={12} sx={{ mb: theme.spacing(1) }}>
                  <ErrorDisplay error={error} />
                </Grid>
              )}
            </>
          )}
        />
        <div>
          {mode !== "displayOnly" && (
            <Button
              onClick={() => append({ type: inferredPhoneType, number: "" })}
              sx={{ mb: theme.spacing(2) }}
              disabled={disabled}
            >
              Add phone number
            </Button>
          )}
        </div>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h6" fontSize={16}>
          Contact Address
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <FormTextField
          name="person.streetAddress1"
          control={control}
          label="Street Address"
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={6}>
        <FormTextField
          label="Apartment, Suite, etc."
          name="person.streetAddress2"
          control={control}
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={6}>
        <FormTextField
          label="Country"
          name="person.country"
          control={control}
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={6}>
        <FormTextField
          label="City"
          name="person.city"
          control={control}
          disabled={disabled}
          hideErrors
        />
      </Grid>
      <Grid item xs={2}>
        <FormSelect
          label="State"
          name="person.state"
          selectItems={STATES_AND_PROVINCES.map((state) => ({
            value: state,
            display: state,
          }))}
          control={control}
          disabled={disabled}
          hideErrors
        />
      </Grid>
      <Grid item xs={4}>
        <FormTextField
          label="Postal Code"
          name="person.zip"
          control={control}
          disabled={disabled}
          hideErrors
        />
      </Grid>
      <Grid item xs={12}>
        <ErrorDisplay
          errors={errors}
          fields={["person.city", "person.state", "person.zip"]}
        />
      </Grid>
    </Grid>
  );
};
