import {
  CommunicationsQuery,
  CommunicationType,
} from "generated-graphql/graphql";

type CommunicationRow = CommunicationsQuery["communications"]["items"][number];

export enum RecipientType {
  LEPC = "LEPC",
  FIRE_DEPARTMENT = "FIRE_DEPARTMENT",
  POLICE_DEPARTMENT = "POLICE_DEPARTMENT",
  LEAD_AGENCY = "LEAD_AGENCY",
  CUSTOM = "CUSTOM",
}

export const getFacilityName = (
  facility: Pick<
    CommunicationRow["facilities"][number],
    "name" | "customerFacilityId"
  >
) => {
  if (facility.customerFacilityId) {
    return `${facility.name} (${facility.customerFacilityId})`;
  }
  return facility.name;
};

export const formatAddress = (address: CommunicationRow) => {
  if (!address) return "";
  return [
    address.mailRecipientAddressLine1,
    address.mailRecipientAddressLine2,
    address.mailRecipientCity,
    address.mailRecipientState,
    address.mailRecipientZip,
  ]
    .filter(Boolean)
    .join(", ");
};

export const formatCommunicationType = (
  communicationType?: CommunicationType | null
) => {
  switch (communicationType) {
    case CommunicationType.Email:
      return "Email";
    case CommunicationType.CertifiedMail:
      return "Certified Mail";
    case CommunicationType.FirstClassMail:
      return "Uncertified Mail";
    case CommunicationType.Check:
      return "Check";
    default:
      return communicationType ?? "";
  }
};
