import Add from "@mui/icons-material/Add";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tooltip,
} from "@mui/material";
import { GridActionsCellItem } from "@mui/x-data-grid-premium";
import { OmnisearchDataGrid } from "components/OmnisearchDataGrid";
import { currentTierIIReportingYear } from "encamp-shared/src/constants/tierii";
import {
  AssociatedFacilitiesQuery,
  AssociatedFacilitiesQueryVariables,
  AssociatedTierIiReportsQuery,
  AssociatedTierIiReportsQueryVariables,
} from "generated-graphql/graphql";
import { usePartnerTenant } from "hooks/usePartnerTenant";
import {
  FACILITY_ASSOCIATIONS_QUERY,
  TIER_II_REPORT_ASSOCIATIONS_QUERY,
} from "./schema";
import { Association } from "./types";

export const AddAssociation = ({
  onClose,
  addAssociation,
  disabledFacilityIds,
  disabledReportIds,
  tenantId,
  type,
}: {
  onClose: () => void;
  addAssociation: (association: Association) => void;
  disabledFacilityIds: string[];
  disabledReportIds: string[];
  tenantId: string;
  type: "Report" | "Facility";
}) => {
  const { partnerTenantId } = usePartnerTenant(tenantId);

  return (
    <Dialog fullWidth open={true} onClose={onClose}>
      <DialogTitle>Add {type} Association</DialogTitle>
      <DialogContent>
        {type === "Report" && (
          <AddReportDataGrid
            tenantId={tenantId}
            partnerTenantId={partnerTenantId}
            disabledReportIds={disabledReportIds}
            add={(reportId) => {
              addAssociation({ reportId, amount: 0 });
              onClose();
            }}
          />
        )}
        {type === "Facility" && (
          <AddFacilityDataGrid
            tenantId={tenantId}
            partnerTenantId={partnerTenantId}
            disabledFacilityIds={disabledFacilityIds}
            add={(facilityId) => {
              addAssociation({ facilityId, amount: 0 });
              onClose();
            }}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={onClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const AddReportDataGrid = ({
  tenantId,
  partnerTenantId,
  disabledReportIds,
  add,
}: {
  tenantId: string;
  partnerTenantId: string | null;
  disabledReportIds: string[];
  add: (tierIIReportId: string) => void;
}) => {
  const tenantFilter = partnerTenantId
    ? `partnerTenantId:${partnerTenantId}`
    : `tenantId:${tenantId}`;
  return (
    <OmnisearchDataGrid<
      AssociatedTierIiReportsQuery,
      AssociatedTierIiReportsQueryVariables,
      AssociatedTierIiReportsQuery["tierIIReports"]["items"][number]
    >
      isURLDriven={false}
      columns={[
        {
          field: "facility",
          headerName: "Facility",
          filterKeyType: "facility",
          flex: 1,
          valueGetter: ({ row }) => row.facility.name,
        },
        { field: "reportingYear", headerName: "Reporting Year", flex: 0.4 },
        { field: "reportKind", headerName: "Type", flex: 0.4 },
        {
          field: "actions",
          type: "actions",
          getActions: (params) => {
            const actions = [];

            const isAddReportDisabled = disabledReportIds.includes(
              params.row.id
            );
            actions.push(
              <GridActionsCellItem
                sx={{
                  "&.Mui-disabled": {
                    pointerEvents: "all",
                  },
                }}
                icon={
                  <Tooltip
                    title={
                      isAddReportDisabled
                        ? "Report already added"
                        : "Add Report"
                    }
                  >
                    <Add />
                  </Tooltip>
                }
                label="Add Report"
                disabled={isAddReportDisabled}
                onClick={() => {
                  if (params.row.id) {
                    add(params.row.id);
                  }
                }}
              />
            );

            return actions;
          },
          flex: 0.1,
        },
      ]}
      dataQuery={TIER_II_REPORT_ASSOCIATIONS_QUERY}
      defaultSearch={`${tenantFilter} reportingYear:${currentTierIIReportingYear}`}
      getItems={(data) => data.tierIIReports.items}
      getCount={(data) => data.tierIIReports.count}
      withPadding={false}
    />
  );
};

const AddFacilityDataGrid = ({
  tenantId,
  partnerTenantId,
  disabledFacilityIds,
  add,
}: {
  tenantId: string;
  partnerTenantId: string | null;
  disabledFacilityIds: string[];
  add: (facilityId: string) => void;
}) => {
  const tenantFilter = partnerTenantId
    ? `partnerTenantId:${partnerTenantId}`
    : `tenantId:${tenantId}`;
  return (
    <OmnisearchDataGrid<
      AssociatedFacilitiesQuery,
      AssociatedFacilitiesQueryVariables,
      AssociatedFacilitiesQuery["facilities"]["items"][number]
    >
      isURLDriven={false}
      columns={[
        { field: "name", headerName: "Facility", flex: 1 },
        {
          field: "address",
          headerName: "Address",
          renderCell: (params) => {
            if (params.row.streetAddress1) {
              const { state, city, zip, streetAddress1, streetAddress2 } =
                params.row;
              return `${streetAddress1} ${streetAddress2} ${city}, ${state} ${zip}`;
            }
            return null;
          },
          sortable: false,
        },
        {
          field: "actions",
          type: "actions",
          getActions: (params) => {
            const actions = [];

            const isAddFacilityDisabled = disabledFacilityIds.includes(
              params.row.id
            );

            actions.push(
              <GridActionsCellItem
                sx={{
                  "&.Mui-disabled": {
                    pointerEvents: "all",
                  },
                }}
                icon={
                  <Tooltip
                    title={
                      isAddFacilityDisabled
                        ? "Facility already added"
                        : "Add Facility"
                    }
                  >
                    <Add />
                  </Tooltip>
                }
                label="Add Facility"
                disabled={isAddFacilityDisabled}
                onClick={() => {
                  add(params.row.id);
                }}
              />
            );

            return actions;
          },
        },
      ]}
      dataQuery={FACILITY_ASSOCIATIONS_QUERY}
      defaultSearch={tenantFilter}
      getItems={(data) => data.facilities.items}
      getCount={(data) => data.facilities.count}
      withPadding={false}
    />
  );
};
