import LoadingButton from "@mui/lab/LoadingButton";
import {
  Button,
  DialogActions,
  DialogContent,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { RadioGroupField } from "components/Forms/RadioGroupField";
import { useFormContext } from "react-hook-form";
import { Fragment } from "react/jsx-runtime";
import { DocumentOption } from "./types";

type DocumentOptionsProps = {
  handleClose: () => void;
  handleNext: () => void;
  handleClearResponse: () => void;
  loading: boolean;
};

export function DocumentOptions({
  handleClose,
  handleNext,
  handleClearResponse,
  loading,
}: DocumentOptionsProps) {
  const theme = useTheme();
  const { control, watch } = useFormContext();

  const option: DocumentOption | null = watch("documentOption");

  return (
    <Fragment>
      <DialogContent>
        <RadioGroupField
          control={control}
          name="documentOption"
          label="Document options"
          direction="column"
          radioOptions={[
            { value: DocumentOption.Upload, label: "Upload Document(s)" },
            {
              value: DocumentOption.PublicInternetURL,
              label: "Public Internet URL",
            },
            {
              value: DocumentOption.ProvidedToRegulator,
              label: "Provided to Regulator",
            },
            {
              value: DocumentOption.ProvideElsewhere,
              label: "Provided Elsewhere in CERS",
            },
            { value: DocumentOption.Exempt, label: "Exempt" },
            {
              value: DocumentOption.StoredAtFacility,
              label: "Stored at Facility",
            },
          ]}
        />

        <Typography variant="caption" marginBottom={theme.spacing(2)}>
          Please contact Encamp Support if you need another option for your
          document(s).
        </Typography>
      </DialogContent>
      <DialogActions>
        <Stack
          direction="row"
          gap={theme.spacing(2)}
          justifyContent="space-between"
          sx={{ marginTop: theme.spacing(2) }}
        >
          <LoadingButton
            onClick={handleClearResponse}
            variant="text"
            loading={loading}
          >
            Clear Response
          </LoadingButton>
          <Stack direction="row" gap={theme.spacing(2)}>
            <Button onClick={handleClose} variant="outlined" disabled={loading}>
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                if (!option) {
                  return;
                }

                handleNext();
              }}
              disabled={loading}
            >
              Next
            </Button>
          </Stack>
        </Stack>
      </DialogActions>
    </Fragment>
  );
}
