import { Stack, Typography } from "@mui/material";
import { DataGrid } from "components/DataGrid";
import { ReportingFacilityChemicalQuery } from "generated-graphql/graphql";
import { useMeasurements } from "hooks/useMeasurements";
import { useProducts } from "hooks/useProducts";
import { prettyPrintDateMed } from "util/dates";
import { prettyPrintQuantity } from "encamp-shared/src/utils/prettyPrintUnits";
import { NoRowsOverlay } from "../Inventory/Facility/NoRowsOverlay";
import { IssueCount } from "components/IssueCount";

export const MeasurementsTable = ({
  rfc,
}: {
  rfc: ReportingFacilityChemicalQuery["reportingFacilityChemical"];
}) => {
  const { hasProducts } = useProducts();
  const { hasMeasurements } = useMeasurements(rfc.facilityId);

  if (!hasMeasurements) return null;

  return (
    <Stack spacing={1}>
      <Typography variant="h6">
        Daily Measurements{hasProducts ? " for Other Chemicals" : ""}
      </Typography>
      <DataGrid
        pagination
        pageSizeOptions={[5]}
        disableRowSelectionOnClick
        initialState={{
          pagination: { paginationModel: { pageSize: 5 } },
          sorting: { sortModel: [{ field: "measuredAtUtc", sort: "desc" }] },
        }}
        slots={{
          noRowsOverlay: () => (
            <NoRowsOverlay message="No measurements found." />
          ),
        }}
        rows={rfc.origin?.facilityChemicalMeasurements ?? []}
        columns={[
          {
            field: "measuredAtUtc",
            headerName: "Date",
            flex: 1,
            renderCell(params) {
              return prettyPrintDateMed(params.row.measuredAtUtc);
            },
          },
          {
            field: "amount",
            headerName: "Amount",
            flex: 1,
            renderCell(params) {
              return prettyPrintQuantity({
                amount: params.row.amount,
                unit: params.row.unit,
                state: rfc.facility?.state,
              });
            },
          },
          {
            field: "location",
            headerName: "Location",
            flex: 1,
            valueGetter(params) {
              const isOregonFacility = rfc.facility?.state === "OR";
              return isOregonFacility
                ? params.row.storageLocation?.OR_storageBuilding
                : params.row.storageLocation?.description;
            },
          },
          {
            field: "storageType",
            headerName: "Storage Type",
            flex: 1,
            valueGetter(params) {
              return params.row.storageType;
            },
          },
          {
            field: "issues",
            headerName: "Issues",
            flex: 1,
            renderCell({ row }) {
              const issues = rfc.issues?.filter(
                (issue) =>
                  issue.modelName === "FacilityChemicalMeasurement" &&
                  issue.modelId === row.id
              );
              return <IssueCount issueCount={issues?.length} />;
            },
          },
        ]}
      />
    </Stack>
  );
};
