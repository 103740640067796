import { StateAbbreviation } from "encamp-shared/dist/constants/states";

export type FacilityStateFields =
  | AL_FACILITY_STATE_FIELDS
  | AR_FACILITY_STATE_FIELDS
  | AZ_FACILITY_STATE_FIELDS
  | CA_FACILITY_STATE_FIELDS
  | CO_FACILITY_STATE_FIELDS
  | DE_FACILITY_STATE_FIELDS
  | FL_FACILITY_STATE_FIELDS
  | HI_FACILITY_STATE_FIELDS
  | ID_FACILITY_STATE_FIELDS
  | IL_FACILITY_STATE_FIELDS
  | IN_FACILITY_STATE_FIELDS
  | KS_FACILITY_STATE_FIELDS
  | KY_FACILITY_STATE_FIELDS
  | LA_FACILITY_STATE_FIELDS
  | MA_FACILITY_STATE_FIELDS
  | MD_FACILITY_STATE_FIELDS
  | ME_FACILITY_STATE_FIELDS
  | MI_FACILITY_STATE_FIELDS
  | MN_FACILITY_STATE_FIELDS
  | MO_FACILITY_STATE_FIELDS
  | ND_FACILITY_STATE_FIELDS
  | NE_FACILITY_STATE_FIELDS
  | NJ_FACILITY_STATE_FIELDS
  | NM_FACILITY_STATE_FIELDS
  | NV_FACILITY_STATE_FIELDS
  | NY_FACILITY_STATE_FIELDS
  | OH_FACILITY_STATE_FIELDS
  | OK_FACILITY_STATE_FIELDS
  | OR_FACILITY_STATE_FIELDS
  | PA_FACILITY_STATE_FIELDS
  | RI_FACILITY_STATE_FIELDS
  | SD_FACILITY_STATE_FIELDS
  | TX_FACILITY_STATE_FIELDS
  | UT_FACILITY_STATE_FIELDS
  | WA_FACILITY_STATE_FIELDS
  | WI_FACILITY_STATE_FIELDS
  | WV_FACILITY_STATE_FIELDS;

export type AL_FACILITY_STATE_FIELDS = {
  masterIdNumber: number;
};

export type AR_FACILITY_STATE_FIELDS = {
  isRegulatedByCfats: boolean | undefined;
  rcraGenerator: AR_RcraGenerator | undefined;
  isRegulatedByOshaPsm: boolean | undefined;
  hasReportedSpill: boolean | undefined;
  spillReportDates: string | undefined;
  nrcCaseNumbers: string | undefined;
};

export type AZ_FACILITY_STATE_FIELDS = {
  directionsToFacility: string | undefined;
  sicCode: number;
  tribalLand: AZ_TribalLand;
  faxNumber: string | undefined;
  email: string;
  isOnStateLeasedLand: boolean | undefined;
  isVoluntary: boolean | undefined;
  mailingAddressName: string;
  onlyLeadAcidBatteries: boolean | undefined;
  onlyGasOrDiesel: boolean | undefined;
  primarilySellsGasAndDieselToPublic: boolean | undefined;
};

export type CA_FACILITY_STATE_FIELDS = {
  apnNumber: string | undefined;
  numberOfEmployees: number | undefined;
  sicCode: number | undefined;
  hazardousMaterialsOnSite: boolean | undefined;
  hasUST: boolean | undefined;
  hasHazardousWaste: boolean;
  hazardousWasteOnSite: boolean | undefined;
  hazardousWasteFinancialAssuranceRequirements: boolean | undefined;
  hazardousWasteConsolidate: boolean | undefined;
  hazardousWasteTankClosureRemoval: boolean | undefined;
  hazardousWasteRCRA: boolean | undefined;
  hazardousWasteHHWCollectionSite: boolean | undefined;
  hasExcludedExemptedMaterials: boolean;
  hasAPS: boolean;
  apsaConditionallyExempt: boolean | undefined | null;
  apsaDateOfCertificationOrReview: string | undefined;
  apsaTanksInUndergroundAreas: number | undefined;
  apsaTotalStorageCapacity: number | undefined;
  hasRegulatedSubstancesGreaterThanThreshold: boolean | undefined;
  additionalInformation: string | undefined;
  facilityFaxNumber: string | undefined;

  // HMBP document fields
  sitePlanDocumentOption: CA_DocumentOption | undefined;
  sitePlanPublicInternetURL: string | undefined;
  sitePlanProvidedToRegulator: string | undefined;
  sitePlanSuppliedIn: string | undefined;
  sitePlanSuppliedInExplanation: string | undefined;
  sitePlanExemptExplanation: string | undefined;
  sitePlanStoredAtFacilityCersId: string | undefined;

  emergencyResponseDocumentOption: CA_DocumentOption | undefined;
  emergencyResponsePublicInternetURL: string | undefined;
  emergencyResponseProvidedToRegulator: string | undefined;
  emergencyResponseSuppliedIn: string | undefined;
  emergencyResponseSuppliedInExplanation: string | undefined;
  emergencyResponseExemptExplanation: string | undefined;
  emergencyResponseStoredAtFacilityCersId: string | undefined;

  apsaDocumentationDocumentOption: CA_DocumentOption | undefined;
  apsaDocumentationPublicInternetURL: string | undefined;
  apsaDocumentationProvidedToRegulator: string | undefined;
  apsaDocumentationSuppliedIn: string | undefined;
  apsaDocumentationSuppliedInExplanation: string | undefined;
  apsaDocumentationExemptExplanation: string | undefined;
  apsaDocumentationStoredAtFacilityCersId: string | undefined;

  hazardousWasteTankClosureCertificateDocumentOption:
    | CA_DocumentOption
    | undefined;
  hazardousWasteTankClosureCertificatePublicInternetURL: string | undefined;
  hazardousWasteTankClosureCertificateProvidedToRegulator: string | undefined;
  hazardousWasteTankClosureCertificateSuppliedIn: string | undefined;
  hazardousWasteTankClosureCertificateSuppliedInExplanation: string | undefined;
  hazardousWasteTankClosureCertificateExemptExplanation: string | undefined;
  hazardousWasteTankClosureCertificateStoredAtFacilityCersId:
    | string
    | undefined;

  recyclableMaterialsReportDocumentOption: CA_DocumentOption | undefined;
  recyclableMaterialsReportPublicInternetURL: string | undefined;
  recyclableMaterialsReportProvidedToRegulator: string | undefined;
  recyclableMaterialsReportSuppliedIn: string | undefined;
  recyclableMaterialsReportSuppliedInExplanation: string | undefined;
  recyclableMaterialsReportExemptExplanation: string | undefined;
  recyclableMaterialsReportStoredAtFacilityCersId: string | undefined;

  // Emergency Response Training Plan
  trainingPlanDocumentOption: CA_DocumentOption | undefined;
  trainingPlanPublicInternetURL: string | undefined;
  trainingPlanProvidedToRegulator: string | undefined;
  trainingPlanSuppliedIn: string | undefined;
  trainingPlanSuppliedInExplanation: string | undefined;
  trainingPlanExemptExplanation: string | undefined;
  trainingPlanStoredAtFacilityCersId: string | undefined;

  ownerStatementOfDesignatedUSTOperatorComplianceDocumentOption:
    | CA_DocumentOption
    | undefined;
  ownerStatementOfDesignatedUSTOperatorCompliancePublicInternetURL:
    | string
    | undefined;
  ownerStatementOfDesignatedUSTOperatorComplianceProvidedToRegulator:
    | string
    | undefined;
  ownerStatementOfDesignatedUSTOperatorComplianceSuppliedIn: string | undefined;
  ownerStatementOfDesignatedUSTOperatorComplianceSuppliedInExplanation:
    | string
    | undefined;
  ownerStatementOfDesignatedUSTOperatorComplianceExemptExplanation:
    | string
    | undefined;
  ownerStatementOfDesignatedUSTOperatorComplianceStoredAtFacilityCersId:
    | string
    | undefined;

  ustCertificationOfFinancialResponsibilityDocumentOption:
    | CA_DocumentOption
    | undefined;
  ustCertificationOfFinancialResponsibilityPublicInternetURL:
    | string
    | undefined;
  ustCertificationOfFinancialResponsibilityProvidedToRegulator:
    | string
    | undefined;
  ustCertificationOfFinancialResponsibilitySuppliedIn: string | undefined;
  ustCertificationOfFinancialResponsibilitySuppliedInExplanation:
    | string
    | undefined;
  ustCertificationOfFinancialResponsibilityExemptExplanation:
    | string
    | undefined;
  ustCertificationOfFinancialResponsibilityStoredAtFacilityCersId:
    | string
    | undefined;

  ustLetterFromChiefFinancialOfficerDocumentOption:
    | CA_DocumentOption
    | undefined;
  ustLetterFromChiefFinancialOfficerPublicInternetURL: string | undefined;
  ustLetterFromChiefFinancialOfficerProvidedToRegulator: string | undefined;
  ustLetterFromChiefFinancialOfficerSuppliedIn: string | undefined;
  ustLetterFromChiefFinancialOfficerSuppliedInExplanation: string | undefined;
  ustLetterFromChiefFinancialOfficerExemptExplanation: string | undefined;
  ustLetterFromChiefFinancialOfficerStoredAtFacilityCersId: string | undefined;

  ustMonitoringSitePlanDocumentOption: CA_DocumentOption | undefined;
  ustMonitoringSitePlanPublicInternetURL: string | undefined;
  ustMonitoringSitePlanProvidedToRegulator: string | undefined;
  ustMonitoringSitePlanSuppliedIn: string | undefined;
  ustMonitoringSitePlanSuppliedInExplanation: string | undefined;
  ustMonitoringSitePlanExemptExplanation: string | undefined;
  ustMonitoringSitePlanStoredAtFacilityCersId: string | undefined;

  ustOwnerOperatorWrittenAgreementDocumentOption: CA_DocumentOption | undefined;
  ustOwnerOperatorWrittenAgreementPublicInternetURL: string | undefined;
  ustOwnerOperatorWrittenAgreementProvidedToRegulator: string | undefined;
  ustOwnerOperatorWrittenAgreementSuppliedIn: string | undefined;
  ustOwnerOperatorWrittenAgreementSuppliedInExplanation: string | undefined;
  ustOwnerOperatorWrittenAgreementExemptExplanation: string | undefined;
  ustOwnerOperatorWrittenAgreementStoredAtFacilityCersId: string | undefined;

  ustResponsePlanDocumentOption: CA_DocumentOption | undefined;
  ustResponsePlanPublicInternetURL: string | undefined;
  ustResponsePlanProvidedToRegulator: string | undefined;
  ustResponsePlanSuppliedIn: string | undefined;
  ustResponsePlanSuppliedInExplanation: string | undefined;
  ustResponsePlanExemptExplanation: string | undefined;
  ustResponsePlanStoredAtFacilityCersId: string | undefined;

  ustPermitTypeOfAction: CA_UstPermitTypeOfAction | undefined;
  ustPermitFacilityType: CA_UstFacilityType | undefined;
  ustPermitBoeNumber: string | undefined;
  ustPermitIndianOrTrustLand: boolean | undefined;
  ustPermitHolderInfo: CA_UstPermitHolderInfo | undefined;
  ustPermitSdsOffice: string | undefined;
  ustPermitFinancialResponsibilityMechanisms: string[] | undefined;
  ustPermitFinancialResponsibilityMechanismOther: string | undefined;
};

export type CO_FACILITY_STATE_FIELDS = {
  facilityType: CO_FacilityType;
  oilAndGasType: CO_OilAndGasType[] | undefined;
  agricultureType: CO_AgricultureType[] | undefined;
};

export type DE_FACILITY_STATE_FIELDS = {
  developmentIndustrialPark: string | undefined;
  natureOfBusiness: string;
  sicCode: number | undefined;
  isGovernmentFacility: boolean | undefined;
};

export type FL_FACILITY_STATE_FIELDS = {
  hasWrittenEmergencyResponsePlan: boolean | undefined;
  hasHazardousMaterialsResponseTeam: boolean | undefined;
  hasLocalFireDepartmentReceivedPrePlan: boolean | undefined;

  // These three state fields are asked during the invoicing process in E-Plan after submitting the t2s file
  numberOfEmployees: number;
  isGasFacilityBelowTpq: boolean | undefined;
  isAgriculturalFacilityExemption: boolean | undefined;
};

export type GA_FACILITY_STATE_FIELDS = {
  hasTrainedFirePersonnel: boolean;
  comments: string;
};

export type HI_FACILITY_STATE_FIELDS = {
  facilityId: number;
  island: HI_Island;
  isMilitaryFacility: boolean | undefined;
};

export type ID_FACILITY_STATE_FIELDS = {
  email: string;
};

export type IL_FACILITY_STATE_FIELDS = {
  otherFireDistricts: string | undefined;
  natureOfBusiness: string | undefined;
  fullTimeEmployees: number | undefined;
  sicCode: number | undefined;
  ownerOperatorEin: string | undefined;
};

export type IN_FACILITY_STATE_FIELDS = {
  commonTransportationRoutes: string | undefined;
  evacuationProcess: string | undefined;
  alertProcess: string | undefined;
  trainingLevel: string | undefined;
  hazardousMaterialEquipmentList: string | undefined;
  hazmatTrainingSchedule: string | undefined;
  emergencyAllocationContact: string | undefined;
  hazmatExerciseSchedule: string | undefined;
  otherFireDistricts: string | undefined;
  natureOfBusiness: string | undefined;
  fullTimeEmployees: number | undefined;
  sicCode: number | undefined;
  isGovernmentFacility: boolean | undefined;
  isFarm: boolean | undefined;
};

export type KS_FACILITY_STATE_FIELDS = {
  isNewFacility: boolean | undefined;
  nearestStreetCorner: string | undefined;
  section: string | undefined;
  township: string | undefined;
  range: string | undefined;
  sicCode: number | undefined;
};

export type KY_FACILITY_STATE_FIELDS = {
  natureOfBusiness: string | undefined;
  sicCode: number | undefined;
  transportationModes: string | undefined;
  transportationRoutes: string | undefined;
  transportationHandling: string | undefined;
  shippingFrequency: string | undefined;
  primaryEhsSupplierName: string | undefined;
  primaryEhsSupplierPhone: string | undefined;
  primaryEhsSupplierAddress: string | undefined;
  emergencyEquipment: string | undefined;
  employeeHazmatTrainingSchedule: string | undefined;
  employeeHazmatExerciseSchedule: string | undefined;
  containmentProcedures: string | undefined;
  cleanupProcedures: string | undefined;
  disposalProcedures: string | undefined;
};

export type LA_FACILITY_STATE_FIELDS = {
  facilityStatus: LA_FacilityStatus;
  soldClosedPluggedAbandonedDate: string | undefined;
  newOwnerName: string | undefined;
  newOwnerAddress: string | undefined;
  newOwnerPhone: string | undefined;
  newOwnerEmail: string | undefined;
  facilityType: LA_FacilityType;
  adjoiningParishPrimary: string | undefined;
  adjoiningParishSecondary: string | undefined;
  adjoiningParishTertiary: string | undefined;
  sicCode: number;
  isGrossReceiptsUnderTwoMillion: boolean | undefined;
  isLpgDistributor: boolean | undefined;
};

export type MA_FACILITY_STATE_FIELDS = {
  natureOfBusiness: string | undefined;
  fullTimeEmployees: number | undefined;
  sicCode: number | undefined;
};

export type MD_FACILITY_STATE_FIELDS = {
  otherFireDistricts: string | undefined;
  natureOfBusiness: string | undefined;
  fullTimeEmployees: number | undefined;
  sicCode: number | undefined;
  isFederalFacility: boolean;
  isStateOrLocalFacility: boolean;
  isRetailGasStation: boolean;
  isFarm: boolean;
  isCharitableOrganization: boolean;
  isMarina: boolean;
};

export type ME_FACILITY_STATE_FIELDS = {
  erpReviewedAt: Date | undefined;
  erpExercisedAt: Date | undefined;
  erpExerciseType: ME_ErpExerciseType | undefined;
  erpExerciseContacted: string | undefined;
};

export type MI_FACILITY_STATE_FIELDS = {
  otherFireDistricts: string | undefined;
  natureOfBusiness: string;
  fullTimeEmployees: number | undefined;
  sicCode: number | undefined;
};

export type MN_FACILITY_STATE_FIELDS = {
  otherFireDistricts: string | undefined;
  natureOfBusiness: string;
  fullTimeEmployees: number | undefined;
  sicCode: number | undefined;
  isFederalFacility: boolean;
  isRetailGasStation: boolean;
};

export type MO_FACILITY_STATE_FIELDS = {
  hasExplosives: boolean | undefined;
  facilityType: MO_FacilityType;
};

export type NC_FACILITY_STATE_FIELDS = {
  facilityType: NC_FacilityType;
  isRegulatedUnderCFATS: boolean;
  isRegulatedUnderOSHA: boolean;
  isRegulatedUnderRCRA: NC_RcraGenerator;
  rcraSiteIdNumber: string | undefined;
  nearestHazardousMaterialsResponseTeam: string | undefined;
  responseTeam24hrPhone: string | undefined;
  responseTeamCity: string | undefined;
  facilityFloorPlanAttached: boolean;
  rcraContingencyPlanAttached: boolean;
};

export type ND_FACILITY_STATE_FIELDS = {
  natureOfBusiness: string;
  sicCode: number;
};

export type NE_FACILITY_STATE_FIELDS = {
  notes: string | undefined;
};

export type NH_FACILITY_STATE_FIELDS = {
  monitoredEmailAddress: string;
  safetyDataSheetsAttached: boolean;
};

export type NJ_FACILITY_STATE_FIELDS = {
  facilityId: string | undefined;
  ein: string;
  numberOfFacilities: number | undefined;
  hasEhs: boolean | undefined;
  hasEhsAboveThresholds: boolean | undefined;
  facilityStatus: NJ_FacilityStatus | undefined;
  descriptionOfOperations: string;
  unionName: string | undefined;
  representativeName: string | undefined;
  representativeEmail: string | undefined;
  representativePhone: string | undefined;
  companyContactName: string;
  companyContactEmailAddress: string;
  // this is only used in mailings, not in portal automation
  policeDepartment: number | undefined;
  numberOfEmployees: number | undefined;
};

export type NM_FACILITY_STATE_FIELDS = {
  isOnTribalLand: boolean | undefined;
  tribalNation: string | undefined;
  numberOfFacilities: number | undefined;
};

export type NV_FACILITY_STATE_FIELDS = {
  otherFireDistricts: string | undefined;
  natureOfBusiness: string;
  sicCode: number | undefined;
  businessIdNumber: string;
  isFederalFacility: boolean | undefined;
  isStateFacility: boolean | undefined;
  isMiningFacility: boolean | undefined;
};

export type NY_FACILITY_STATE_FIELDS = {
  borough: NY_Borough | undefined;
};

export enum OH_SQUARE_FOOTAGE {
  "0-100,000 sq. ft.",
  "100,001-400,000 sq. ft.",
  "400,001+ sq. ft.",
}

export type OH_FACILITY_STATE_FIELDS = {
  hasConfidentialLocationForms: boolean | undefined;
  hasFacilityNameChanged: boolean | undefined;
  previousFacilityName: string | undefined;
  hasOwnerOperatorChanged: boolean | undefined;
  previousOwnerOperator: string | undefined;
};

export type OH_CLEVELAND_FACILITY_STATE_FIELDS = {
  numberOfEmployees: number;
  incorporationInformationName: string;
  incorporationInformationState: StateAbbreviation;
  incorporationInformationFederalIdNumber: number;
  squareFootage: OH_SQUARE_FOOTAGE;
};

export type OK_FACILITY_STATE_FIELDS = {
  isRegulatedByCfats: boolean | undefined;
  rcraGenerator: OK_RcraGenerator | undefined;
  isRegulatedByOshaPsm: boolean | undefined;
  hasReportedSpill: boolean | undefined;
  spillReportDates: string | undefined;
  nrcCaseNumbers: string | undefined;
};

export type OR_FACILITY_STATE_FIELDS = {
  taxIdNumber: string;
  hasAutomaticFireSuppressionSystem: boolean;
  hasEmergencyPlan: boolean;
  planLocation: string | undefined;
  isStorePlacarded: boolean | undefined;
  hasOtherPlacards: boolean;
  hasMultipleAddresses: boolean;
  isStateOrLocalFacility: boolean;
  isAgriculturalFacility: boolean;
  facilityNoChemicalsExemptionStorageDate: Date | undefined;
};

export type PA_FACILITY_STATE_FIELDS = {
  natureOfBusiness: string | undefined;
  sicCode: number;
  isFederalFacility: boolean | undefined;
  isStateOrLocalFacility: boolean | undefined;
  isWater: boolean | undefined;
  isFarm: boolean | undefined;
  isRetailGasStation: boolean | undefined;
  isVolunteerReport: boolean | undefined;
  faxNumber: string | undefined;
};

export type RI_FACILITY_STATE_FIELDS = {
  isLocatedInCommunityWaterSystemArea: boolean | undefined;
  waterDrinkingSystemOwner: string | undefined;
};

export type SD_FACILITY_STATE_FIELDS = {
  facilityId: string | undefined;
  sicCode: number | undefined;
  closedAt: Date | undefined;
};

export type TX_FACILITY_STATE_FIELDS = {
  isNewFacilityThisYear: boolean | undefined;
  startReportingDate: Date | undefined;
  isNoLongerReportable: boolean | undefined;
  closedAt: Date | undefined;
};

export type UT_FACILITY_STATE_FIELDS = {
  stateId: string;
};

export type WA_FACILITY_STATE_FIELDS = {
  ubi: number | undefined;
};

export type WI_FACILITY_STATE_FIELDS = {
  isFederalOrTribalFacility: boolean | undefined;
  natureOfBusiness: string | undefined;
  fullTimeEmployees: number;
  sicCode: number | undefined;
  einTaxId: string;
  hasOSHACommunicationExemption: boolean | undefined;
  hasOSHAHazComActExemption: boolean | undefined;
  has311Exemption: boolean | undefined;
  hasRetailGasStationExemption: boolean | undefined;
  hasNoChemicalsExemption: boolean | undefined;
};

export type WV_FACILITY_STATE_FIELDS = {
  natureOfBusiness: string;
  fullTimeEmployees: number | undefined;
  sicCode: number | undefined;
  isAst: boolean | undefined;
  astPermitId: string | undefined;
  isSubjectToSection15_5c: boolean;
  isRegulatedByCfats: boolean;
  isNonStandardAddress: boolean | undefined;
  isSitePlanProvidedAsHardcopy: boolean | undefined;
};

export type WY_FACILITY_STATE_FIELDS = {
  isOwnedOrOperatedByNonprofitOrGovernment: boolean | undefined;
};

export enum AR_RcraGenerator {
  "No",
  "Yes; Large Quantity Generator (LQG)",
  "Yes; Small Quantity Generator (SQG)",
  "Yes; Very Small Quantity Generator (VSQG)",
}

export enum AZ_TribalLand {
  "Not On Tribal Land",
  "Ak-Chin Indian Res.",
  "Cocopah Indian Res.",
  "Colorado River Indian Res.",
  "Fort McDowell Indian Res.",
  "Fort Yuma Indian Res.",
  "Fort-Mohave Indian Res.",
  "Gila Bend Indian Res.",
  "Gila River Indian Res.",
  "Havasupai Indian Res.",
  "Hohokam Pima N.M",
  "Hopi Indian Res.",
  "Hopi Trust Land",
  "Hualapai Indian Res.",
  "Indian Allotments",
  "Kaibab Indian Res.",
  "Navajo Indian Res.",
  "Pascua Yaqui Res.",
  "Salt River Indian Res.",
  "San Carlos Indian Res.",
  "San Xavier Indian Res.",
  "Tohono Indian Res.",
  "White Mtn Apache Indian Res.",
  "Yavapai Apache Indian Res.",
  "Yavapai Prescott Indian Res.",
  "Yavapai Tonto Apache Res.",
  "Zuni Indian Res.",
}

export enum CA_DocumentOption {
  "Upload",
  "Public Internet URL",
  "Provided to Regulator",
  "Provided Elsewhere",
  "Exempt",
  "Stored at Facility",
}

/** @deprecated("Use CaSections instead. This will be removed with the HMBP migration.") */
export enum CA_EmployeeTrainingPlanFiledElsewhere {
  "Within Facility Info",
  "Within Hazardous Materials Inventory",
  "Within Emergency Response and Training Plans",
}

export enum CA_UstPermitTypeOfAction {
  "Confirmed/Updated Information",
  "New Permit",
  "Renewal Permit",
  "Temporary Facility Closure",
  "Permanent Facility Closure",
  "Transfer Permit",
}

export enum CA_UstFacilityType {
  "Motor Vehicle Fueling",
  "Fuel Distribution",
  "Farm",
  "Processor",
  "Other",
}

export enum CA_UstPermitHolderInfo {
  "Facility Owner",
  "Facility Operator",
  "Tank Owner",
  "Tank Operator",
}

export enum CA_FinancialResponsibilityMechanisms {
  "Self-Insured",
  "Guarantee",
  "Insurance",
  "Surety Bond",
  "Letter of Credit",
  "Exemption",
  "State Fund and CFO Letter",
  "State Fund and CD",
  "Local Government Mechanism",
  "Other Mechanism",
}

export enum CO_FacilityType {
  "Federal, State, Local Government or University",
  "Retail Motor Fuel Outlet",
  "Federally Recognized Tribe",
  "Oil and Gas",
  "Agriculture",
  "Other Private Industry/Business",
}

export enum CO_OilAndGasType {
  "Well Head",
  "Storage Tanks",
  "Compressor Station",
  "Other",
}

export enum CO_AgricultureType {
  "Farm or Ranch",
  "Fertilizer or Pesticide",
  "Other",
}

export enum HI_Island {
  "Hawaii",
  "Kauai",
  "Lanai",
  "Maui",
  "Molokai",
  "Oahu",
}

export enum LA_FacilityStatus {
  "Active",
  "Sold",
  "Closed",
  "Plugged and Abandoned",
}

export enum LA_FacilityType {
  "Fixed",
  "Oilfield",
  "Pipeline",
}

export enum ME_ErpExerciseType {
  "Workshop",
  "Tabletop Exercise",
  "Functional",
  "Full Scale",
  "Other",
}

export enum MO_FacilityType {
  "Family Farm",
  "Local/Federal Government",
  "Pipeline",
  "Retail Petroleum",
  "Bulk Petroleum",
  "Other",
}

export enum NC_FacilityType {
  "Industrial/Other Private Business",
  "Government (Federal, State, or Local)",
  "Educational",
  "Tribal (Federally Recognized)",
  "Agricultural (Industrial/Commercial)",
  "Agricultural (Fertilizer/Pesticide Storage & Sales)",
  "Agricultural (Family Farm)",
  "Motor Vehicle Dealer",
  "Motor Vehicle Fuel Retailer",
}

export enum NC_RcraGenerator {
  "No",
  "Yes; Large Quantity Generator (LQG)",
  "Yes; Small Quantity Generator (SQG)",
  "Yes; Very Small Quantity Generator (VSQG)",
  "Unknown",
}

export enum NC_YesNoUnknown {
  "Yes",
  "No",
  "Unknown",
}

export enum NJ_FacilityStatus {
  "Active",
  "Out of Business",
}

export enum NY_Borough {
  "The Bronx",
  "Brooklyn",
  "Manhattan",
  "Queens",
  "Staten Island",
}

export enum OK_RcraGenerator {
  "No",
  "Yes; Large Quantity Generator (LQG)",
  "Yes; Small Quantity Generator (SQG)",
  "Yes; Very Small Quantity Generator (VSQG)",
}
