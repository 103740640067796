import { Box, IconButton, Tooltip } from "@mui/material";
import { GridColDef, GridRowId } from "@mui/x-data-grid-premium";
import { OmnisearchDataGrid } from "components/OmnisearchDataGrid";
import { gql } from "generated-graphql";
import { FacilitiesForCommunicationQuery } from "generated-graphql/graphql";
import { useMemo } from "react";
import OpenInNew from "@mui/icons-material/OpenInNew";
import { getFacilityName } from "../../utils";

type Row = FacilitiesForCommunicationQuery["facilities"]["items"][number];

const FACILITIES_FOR_COMMUNICATION = gql(`
  query FacilitiesForCommunication($search: String, $page: Int, $pageSize: Int, $sort: [SortModel!]) {
    facilities(search: $search, page: $page, pageSize: $pageSize, sort: $sort) {
      items {
        id
        name
        customerFacilityId
        lepc {
            name
        }
        fireDepartment {
            name
        }
        streetAddress1
        city
        state
        zip
      }
      count
    }
  }
`);

interface FacilitiesGridProps {
  disabled?: boolean;
  tenantId: string;
  facilityIds: string[];
  onFacilitySelect: (facilityIds: string[]) => void;
}

export const FacilitiesGrid = ({
  disabled = false,
  tenantId,
  facilityIds,
  onFacilitySelect,
}: FacilitiesGridProps) => {
  const columns: GridColDef<Row>[] = useMemo(() => {
    return [
      {
        field: "facility",
        headerName: "Facility",
        flex: 0.5,
        filterKeyType: "facility",
        valueGetter: (params) => {
          const formattedName = getFacilityName(params.row);
          return formattedName;
        },
      },
      {
        field: "state",
        headerName: "State",
        flex: 0.125,
      },
      {
        field: "streetAddress1",
        headerName: "Street Address",
        flex: 0.5,
        sortable: true,
        valueGetter(params) {
          if (!params.row.streetAddress1) return "";
          const { streetAddress1, city, state, zip } = params.row;
          return [streetAddress1, city, state, zip].filter((s) => s).join(", ");
        },
      },
      {
        field: "lepc",
        headerName: "LEPC",
        flex: 0.3,
        valueGetter(params) {
          return params.row?.lepc?.name || "";
        },
      },
      {
        field: "fireDepartment",
        headerName: "Fire Department",
        flex: 0.3,
        valueGetter(params) {
          return params.row?.fireDepartment?.name || "";
        },
      },
      {
        field: "actions",
        headerName: "",
        flex: 0.1,
        sortable: false,
        renderCell: (params) => (
          <Tooltip title="Open Facility">
            <IconButton
              size="small"
              onClick={(e) => {
                e.stopPropagation();
                window.open(
                  `/o/${tenantId}/facilities/${params.row.id}/profile`,
                  "_blank"
                );
              }}
            >
              <OpenInNew fontSize="small" />
            </IconButton>
          </Tooltip>
        ),
      },
    ];
  }, [tenantId]);

  const search = useMemo(() => {
    return disabled ? `id:${facilityIds.join("|")}` : `tenantId:${tenantId}`;
  }, [disabled, facilityIds, tenantId]);

  return (
    <Box>
      <OmnisearchDataGrid
        columns={columns}
        dataQuery={FACILITIES_FOR_COMMUNICATION}
        skip={!tenantId}
        initialSortModel={[
          { field: "state", sort: "asc" },
          { field: "facility", sort: "asc" },
        ]}
        defaultSearch={search}
        getItems={(data) => data.facilities.items}
        getCount={(data) => data.facilities.count}
        noDataMessage="No facilities found for this organization."
        isURLDriven={false}
        isRowSelectable={(params) => !disabled}
        selectedIds={facilityIds}
        disableRowSelectionOnClick={false}
        onSelectedIdsChanged={(newFacilityIds) => {
          onFacilitySelect(newFacilityIds as string[]);
        }}
        initialPageSize={10}
        additionalFilterColumns={[
          { header: "Facility ID", key: "customerFacilityId" },
          { header: "City", key: "city" },
          { header: "Zip", key: "zip" },
        ]}
      />
    </Box>
  );
};
