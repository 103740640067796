import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { NavLink } from "react-router-dom";
import { useAlerts } from "./Alerts/AlertProvider";
import _ from "lodash";

export type StandardNavItem = {
  kind: "Standard";
  text: string;
  path?: string;
  isDisabled?: boolean;
  textColor?: string;
  isExternal?: boolean;
  Icon: React.ElementType;
};

type NavItemProps = {
  navItem: StandardNavItem;
  isLargeScreen: boolean;
  onClose: () => void;
};

function isPathActive(path: string | undefined, text: string) {
  const currentPath = window.location.pathname;
  const pathToMatch = `/${
    path ? path : text.split("/").map(_.kebabCase).join("/")
  }`;
  return currentPath.includes(pathToMatch);
}

export function NavItem({ navItem, isLargeScreen, onClose }: NavItemProps) {
  const alerts = useAlerts();
  const { Icon, path, text, isDisabled, textColor, isExternal } = navItem;

  const isActive = isPathActive(path, text);

  let iconColor = "";

  if (textColor) {
    iconColor = textColor;
  } else {
    if (isActive) {
      iconColor = "white";
    } else {
      iconColor = "#8D98A6";
    }
  }

  let url = "";

  if (isExternal) {
    url = path ?? "";
  } else {
    url = `/${path ? path : text.split("/").map(_.kebabCase).join("/")}`;
  }

  return (
    <ListItem disablePadding>
      <NavLink
        onClick={(e) => {
          if (isDisabled) {
            e.preventDefault();
            alerts.info("This section is not yet available.");
          } else if (!isLargeScreen) {
            onClose();
          }
        }}
        to={url}
        target={isExternal ? "_blank" : undefined}
        rel={isExternal ? "noopener noreferrer" : undefined}
        style={({ isActive }) => ({
          textDecoration: "none",
          color: "inherit",

          width: "100%",
          background: isActive ? "#4A88D0" : "none",
        })}
      >
        <ListItemButton
          sx={{
            "&:hover": {
              ".MuiSvgIcon-root": {
                color: isActive ? "white" : "#4A88D0",
              },
              ".MuiTypography-root": {
                color: isActive ? "white" : "#4A88D0",
              },
            },
          }}
        >
          <ListItemIcon
            sx={{
              paddingRight: {
                md: "5px",
                xs: "100px",
              },
              paddingLeft: {
                md: "10px",
                xs: "30px",
              },
            }}
          >
            <Icon
              sx={{
                fontSize: { xs: "60px", md: "24px" },
                color: iconColor,
                opacity: textColor && !isActive ? 0.5 : 1,
              }}
            />
          </ListItemIcon>
          <ListItemText
            primary={text}
            primaryTypographyProps={{
              fontSize: { xs: "40px", md: "16px" },
              color: textColor ? textColor : "white",
              marginLeft: {
                md: "-10px",
                xs: "-60px",
              },
            }}
          />
        </ListItemButton>
      </NavLink>
    </ListItem>
  );
}
