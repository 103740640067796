import { gql } from "generated-graphql";
import {
  PoliceDepartmentPickerFragment,
  PoliceDepartmentsPickerQuery,
} from "generated-graphql/graphql";
import { forwardRef } from "react";
import { ControllerFieldState } from "react-hook-form";
import EncampAutocomplete from "./Forms/EncampAutocomplete";

// Base props for the picker
type PoliceDepartmentPickerProps = {
  defaultSearch?: string;
  onChange?: (value: PoliceDepartmentPickerFragment | null) => void;
  value?: PoliceDepartmentPickerFragment | null;
  disabled?: boolean;
} & ControllerFieldState;

// GraphQL fragments and queries
const POLICE_DEPARTMENT_PICKER_FRAGMENT = gql(`
  fragment PoliceDepartmentPicker on PoliceDepartment {
    id
    name
    address
    city
    state
    zip
    email
    contactName
    attn
    additionalComments
  }
`);

const POLICE_DEPARTMENTS_SEARCH_QUERY = gql(`
  query PoliceDepartmentsPicker($search: String, $page: Int, $pageSize: Int, $sort: [SortModel!], $tenantId: String) {
    policeDepartments(search: $search, page: $page, pageSize: $pageSize, sort: $sort, tenantId: $tenantId) {
      items {
        ...PoliceDepartmentPicker
      }
      count
    }
  }
`);

// Function to format Police Department option labels
const getPoliceDepartmentOptionLabel = (
  policeDepartment: PoliceDepartmentPickerFragment
) => {
  return `${policeDepartment.name} ${policeDepartment.city}, ${policeDepartment.state}`;
};

// Function to get items from query response
const getPoliceDepartmentItems = (data: PoliceDepartmentsPickerQuery) =>
  data.policeDepartments?.items ?? [];

// Main picker component
export const PoliceDepartmentPicker = forwardRef<
  HTMLDivElement,
  PoliceDepartmentPickerProps
>(function PoliceDepartmentPicker(
  { onChange, value, error, disabled, defaultSearch },
  ref
) {
  return (
    <EncampAutocomplete
      query={POLICE_DEPARTMENTS_SEARCH_QUERY}
      variables={{
        search: defaultSearch,
      }}
      getItems={getPoliceDepartmentItems}
      getOptionLabel={getPoliceDepartmentOptionLabel}
      onChange={onChange}
      value={value}
      error={error}
      disabled={disabled}
    />
  );
});
