import {
  Button,
  DialogContent,
  DialogActions,
  Stack,
  Typography,
  useTheme,
  Skeleton,
} from "@mui/material";
import { FormSelect } from "components/Forms/FormSelect";
import { SaveButton } from "components/SaveButton";
import { FacilityAlternateIdKnownKind } from "encamp-shared/src/facility/alternateId";
import { Facility } from "generated-graphql/graphql";
import { useFormContext } from "react-hook-form";
import { Fragment } from "react/jsx-runtime";
import { useCersFacilities } from "routes/Customer/Chemicals/Report/useHmbp";

type StoredAtFacilityProps = {
  handleBack: () => void;
  loading?: boolean;
  facilityId: string;
};

export function StoredAtFacility({
  loading,
  handleBack,
  facilityId,
}: StoredAtFacilityProps) {
  const theme = useTheme();
  const { control, watch } = useFormContext();
  const { data: cersFacilities, loading: cersFacilitiesLoading } =
    useCersFacilities(facilityId);

  const storedAtFacilityCersId: string | null = watch("storedAtFacilityCersId");

  return (
    <Fragment>
      <DialogContent>
        <Typography variant="body1" marginBottom={theme.spacing(2)}>
          Stored at Facility
        </Typography>

        <Typography variant="body2" marginBottom={theme.spacing(2)}>
          If previously approved by your local regulator, you can use this
          document option to indicate the document(s) is stored at one of your
          Facilities. Normally this option should only be used when the
          document(s) are sensitive and should not be made readily available to
          the public, such as the Emergency Response Plan for a refinery or
          large chemical manufacturing plant. Indicate the facility where the
          document(s) is stored.
        </Typography>

        {cersFacilitiesLoading ? (
          <Skeleton variant="rectangular" height={50} />
        ) : (
          <FormSelect
            control={control}
            name="storedAtFacilityCersId"
            label="Facility"
            selectItems={cersFacilities
              .filter((f: Partial<Facility>) =>
                f.facilityAlternateIds?.some(
                  (id) => id.type === FacilityAlternateIdKnownKind.CERS
                )
              )
              .map((facility: Partial<Facility>) => ({
                value: facility.facilityAlternateIds?.find(
                  (id) => id.type === FacilityAlternateIdKnownKind.CERS
                )?.value,
                display: facility.name ?? "",
              }))}
          />
        )}
      </DialogContent>

      <DialogActions>
        <Stack
          direction="row"
          gap={theme.spacing(2)}
          justifyContent="flex-end"
          sx={{ marginTop: theme.spacing(2) }}
        >
          <Button onClick={handleBack} variant="outlined">
            Back
          </Button>
          <SaveButton
            loading={loading ?? false}
            disabled={storedAtFacilityCersId === null}
          />
        </Stack>
      </DialogActions>
    </Fragment>
  );
}
