import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
} from "@mui/material";
import { Dialog } from "components/Dialog";
import { IssueListButton } from "components/Forms/IssueListButton";
import { SaveButton } from "components/SaveButton";
import { FacilityChemicalStorageLocationForm } from "routes/Customer/Chemicals/Inventory/Facility/FacilityChemicalStorageLocationForm";
import { StorageLocationForm } from "./StorageLocationForm";

import { FacilityChemicalStorageLocationInputWithIssues } from "components/StorageLocationsDataGrid";
import {
  FacilityChemicalStorageLocationInput,
  Issue,
  Pressure,
  Temperature,
  UnitType,
} from "generated-graphql/graphql";
import { useValidatingForm } from "hooks/useValidatingForm";
import { useMemo } from "react";
import { FormProvider } from "react-hook-form";
import { useFacility } from "routes/Customer/Facility/useFacility";
import invariant from "tiny-invariant";
import { hasCriticalIssues } from "util/forms";
import { v4 as uuidv4 } from "uuid";
import { useFacilityChemicalStorageLocationInputValidation } from "../../Report/validationHooks/useFacilityChemicalStorageLocationValidation";
import { FacilityChemicalDetailFormType } from "./FacilityChemicalAddEditDialog";

export type FacilityChemicalStorageLocationDialogProps = {
  facilityId: string;
  facilityChemicalId: string;
  facilityChemical?: FacilityChemicalDetailFormType;
  initialValues: FacilityChemicalStorageLocationInputWithIssues | null;
  onSubmit: (newValue: FacilityChemicalStorageLocationInput) => void;
  open: boolean;
  loading: boolean;
  onClose: () => void;
  disabled?: boolean;
};

export function FacilityChemicalStorageLocationDialog(
  props: FacilityChemicalStorageLocationDialogProps
) {
  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <StorageLocationForms {...props} />
    </Dialog>
  );
}

function StorageLocationForms(
  props: FacilityChemicalStorageLocationDialogProps
) {
  const { data: facilityData } = useFacility(props.facilityId);
  const {
    facilityId,
    disabled,
    initialValues,
    facilityChemicalId,
    facilityChemical,
  } = props;
  invariant(facilityId);
  const isEditMode = initialValues !== null;

  const defaultValues: FacilityChemicalStorageLocationInput = useMemo(
    () => ({
      id: props.initialValues?.id ?? uuidv4(),
      maxAmount: props.initialValues?.maxAmount,
      pressure:
        props.initialValues?.pressure ??
        (isEditMode ? null : Pressure.AmbientPressure),
      otherPressureValue: props.initialValues?.otherPressureValue ?? null,
      temperature:
        props.initialValues?.temperature ??
        (isEditMode ? null : Temperature.AmbientTemperature),
      storageType: props.initialValues?.storageType ?? "",
      storageTypeDescription:
        props.initialValues?.storageTypeDescription ?? null,
      storageLocation: props.initialValues?.storageLocation
        ? {
            ...props.initialValues?.storageLocation,
            latitude:
              props.initialValues.storageLocation.latitude ??
              facilityData?.facility?.latitude,
            longitude:
              props.initialValues.storageLocation.longitude ??
              facilityData?.facility?.longitude,
          }
        : {
            description: "",
            facilityId,
            id: uuidv4(),
            OR_insideOutside: null,
            OR_storageArea: "",
            OR_storageBuilding: "",
            OR_storageQuadrant: null,
            OR_storageFloor: "",
            OR_storageRoom: "",
            latitude: facilityData?.facility?.latitude,
            longitude: facilityData?.facility?.longitude,
          },
      unit: props.initialValues?.unit ?? (isEditMode ? null : UnitType.Pounds),
    }),
    [
      facilityData?.facility?.latitude,
      facilityData?.facility?.longitude,
      facilityId,
      props.initialValues,
      isEditMode,
    ]
  );

  /**
   * This component depends on issues for the storage location to be included
   * directly on the object itself. Sometimes those issues come from other
   * schemas and have a key that looks like: key 'storageLocations.0.unit`. This
   * transform will ensure we convert the issues to look like 'unit' which
   * allows react-hook-form to properly map the issues to the inputs in this
   * dialog
   */
  const transformIssueKeys = (issues: Issue[]): Issue[] => {
    return issues.map((issue) => {
      if (issue.modelName === "FacilityChemicalStorageLocation") {
        return {
          ...issue,
          key: issue.key ? issue.key.split(".").pop() || issue.key : issue.key,
        };
      }
      return issue;
    });
  };

  const form = useValidatingForm<FacilityChemicalStorageLocationInput>(
    defaultValues,
    transformIssueKeys(initialValues?.issues ?? []),
    useFacilityChemicalStorageLocationInputValidation(
      facilityId,
      facilityChemicalId,
      facilityChemical
    )
  );

  const {
    handleSubmit,
    issues,
    formState: { isDirty },
  } = form;

  const context = isEditMode ? "Save" : "Add";

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(props.onSubmit)}>
        <DialogTitle>{`${context} Storage Location`}</DialogTitle>
        <DialogContent>
          <StorageLocationForm facilityId={facilityId} disabled={disabled} />
          <FacilityChemicalStorageLocationForm disabled={disabled} />
        </DialogContent>
        <DialogActions>
          <IssueListButton issues={issues} />
          <FormControl>
            <Button variant="outlined" onClick={props.onClose}>
              Cancel
            </Button>
          </FormControl>
          <FormControl>
            <SaveButton
              loading={props.loading}
              saveText={context}
              disabled={
                hasCriticalIssues(issues) || (!isDirty && !initialValues?.id)
              }
            />
          </FormControl>
        </DialogActions>
      </form>
    </FormProvider>
  );
}
