import { useQuery } from "@apollo/client";
import Delete from "@mui/icons-material/Delete";
import { Box, Button, Typography } from "@mui/material";
import { GridActionsCellItem, GridSortModel } from "@mui/x-data-grid-premium";
import { DataGrid } from "components/DataGrid";
import {
  AssociatedFacilitiesQuery,
  AssociatedTierIiReportsQuery,
} from "generated-graphql/graphql";
import { usePaginationModel } from "hooks/usePaginationModel";
import pluralize from "pluralize";
import { useCallback, useState } from "react";
import { Control, useFieldArray, useWatch } from "react-hook-form";
import { AddAssociation } from "./AddAssociation";
import { PayeeFormInputs } from "./PaymentDialog";
import {
  FACILITY_ASSOCIATIONS_QUERY,
  TIER_II_REPORT_ASSOCIATIONS_QUERY,
} from "./schema";
import { Association, FacilityAssociation, ReportAssociation } from "./types";

const AssociatedEntities = ({
  control,
  tenantId,
  type,
  disabled,
  nonChampionIds,
}: {
  control: Control<PayeeFormInputs>;
  tenantId: string;
  type: "Report" | "Facility";
  disabled?: boolean;
  nonChampionIds?: string[];
}) => {
  const [showAddModal, setShowAddModal] = useState(false);
  const { update, remove, append, fields } = useFieldArray({
    control,
    name: "associations",
  });
  const associations = useWatch({ control, name: "associations" });

  const handleGetCohortReportIds = useCallback(async () => {
    const reportIds = nonChampionIds ?? [];

    const existingReportIds = new Set(fields.map((a) => a.reportId));
    for (const reportId of reportIds) {
      if (!existingReportIds.has(reportId)) {
        append({
          reportId,
          amount: 0,
        });
      }
    }
  }, [nonChampionIds, append, fields]);

  const getAssociationIndex = useCallback(
    (association: Omit<Association, "amount">) =>
      associations.findIndex(
        (a) =>
          a.reportId === association.reportId &&
          a.facilityId === association.facilityId
      ),
    [associations]
  );

  const updateAssociation = (association: Association) => {
    const index = getAssociationIndex(association);
    update(index, association);
  };

  return (
    <Box>
      {showAddModal && (
        <AddAssociation
          onClose={() => setShowAddModal(false)}
          addAssociation={append}
          disabledFacilityIds={associations.map((a) => a.facilityId ?? "")}
          disabledReportIds={associations.map((a) => a.reportId ?? "")}
          tenantId={tenantId}
          type={type}
        />
      )}
      <Box
        sx={{
          justifyContent: "space-between",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Typography variant="subtitle2">
          Associated {pluralize(type, 2)}
        </Typography>

        <Box
          sx={{
            justifyContent: "space-between",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Button onClick={() => setShowAddModal(true)}>Add {type}</Button>

          {type === "Report" && (nonChampionIds?.length ?? 0) > 0 && (
            <Button onClick={handleGetCohortReportIds}>Add Whole Cohort</Button>
          )}
        </Box>
      </Box>
      {type === "Report" ? (
        <ReportTable
          associations={associations
            .filter((a) => a.reportId?.length)
            .map((a) => a as ReportAssociation)}
          deleteReport={(reportId) => remove(getAssociationIndex({ reportId }))}
          processRowUpdate={(reportAssociation) =>
            updateAssociation(reportAssociation)
          }
          disabled={disabled}
        />
      ) : (
        <FacilityTable
          associations={associations
            .filter((a) => a.facilityId?.length)
            .map((a) => a as FacilityAssociation)}
          deleteFacility={(facilityId) =>
            remove(getAssociationIndex({ facilityId }))
          }
          processRowUpdate={(facilityAssociation) =>
            updateAssociation(facilityAssociation)
          }
          disabled={disabled}
        />
      )}
    </Box>
  );
};

type ReportRow =
  AssociatedTierIiReportsQuery["tierIIReports"]["items"][number] & {
    amount: number;
  };

const ReportTable = ({
  associations,
  deleteReport,
  processRowUpdate,
  disabled,
}: {
  associations: ReportAssociation[];
  deleteReport: (reportId: string) => void;
  processRowUpdate: (reportAssociation: ReportAssociation) => void;
  disabled?: boolean;
}) => {
  const [paginationModel, setPaginationModel] = usePaginationModel();
  const [sortModel, setSortModel] = useState<GridSortModel>([]);
  const reportIds: string[] = associations.map((a) => a.reportId);

  const {
    data: reports,
    previousData: previousReports,
    loading,
  } = useQuery(TIER_II_REPORT_ASSOCIATIONS_QUERY, {
    variables: {
      search: `${reportIds.map((fid) => `id:${fid}`).join(" or ")}`,
      sort: sortModel,
      page: paginationModel.page,
      pageSize: paginationModel.pageSize,
    },
    skip: !reportIds.length,
  });

  const rows: ReportRow[] =
    (reports ?? previousReports)?.tierIIReports.items.map((f) => ({
      ...f,
      amount: associations.find((a) => a.reportId === f.id)?.amount ?? 0,
    })) ?? [];

  return (
    <DataGrid<ReportRow>
      columns={[
        {
          headerName: "Organization",
          field: "tenant.name",
          flex: 0.75,
          valueGetter: (params) => params.row.tenant.name,
        },
        {
          headerName: "Facility",
          field: "facility.name",
          flex: 1,
          valueGetter: (params) => params.row.facility.name,
        },
        {
          headerName: "Facility Address",
          field: "facilityAddress",
          sortable: false,
          flex: 1,
          valueGetter: (params) => {
            const address = params.row.facility.streetAddress1;
            if (address) {
              const { streetAddress1, city, state, zip } = params.row.facility;
              return `${streetAddress1}, ${city}, ${state} ${zip}`;
            }
            return "";
          },
        },
        {
          headerName: "Reporting Year",
          field: "reportingYear",
          valueGetter: (params) => params.row.reportingYear,
          flex: 0.65,
        },
        {
          headerName: "Type",
          field: "reportKind",
          valueGetter: (params) => params.row.reportKind,
          flex: 0.6,
        },
        {
          headerName: "Amount",
          field: "amount",
          flex: 0.6,
          editable: !disabled,
          valueFormatter(params) {
            return new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
            }).format(params.value);
          },
          sortable: false,
        },
        {
          field: "actions",
          type: "actions",
          getActions: (params) => [
            <GridActionsCellItem
              key="delete"
              icon={<Delete />}
              label="Delete"
              onClick={() => deleteReport(params.row.id)}
            />,
          ],
        },
      ]}
      loading={loading}
      rows={rows}
      rowCount={reports?.tierIIReports.count ?? 0}
      processRowUpdate={(newRow) => {
        processRowUpdate({ reportId: newRow.id, amount: newRow.amount });
        return newRow;
      }}
      sortModel={sortModel}
      onSortModelChange={setSortModel}
      pagination
      paginationMode="server"
      paginationModel={paginationModel}
      onPaginationModelChange={setPaginationModel}
    />
  );
};

type FacilityRow = AssociatedFacilitiesQuery["facilities"]["items"][number] & {
  amount: number;
};

const FacilityTable = ({
  associations,
  deleteFacility,
  processRowUpdate,
  disabled,
}: {
  associations: FacilityAssociation[];
  deleteFacility: (facilityId: string) => void;
  processRowUpdate: (facilityAssociation: FacilityAssociation) => void;
  disabled?: boolean;
}) => {
  const [paginationModel, setPaginationModel] = usePaginationModel();
  const [sortModel, setSortModel] = useState<GridSortModel>([]);
  const facilityIds: string[] = associations.map((a) => a.facilityId);
  const {
    data: facilities,
    previousData: previousFacilities,
    loading: facilitiesLoading,
  } = useQuery(FACILITY_ASSOCIATIONS_QUERY, {
    variables: {
      search: `${facilityIds.map((fid) => `id:${fid}`).join(" or ")}`,
      sort: sortModel,
      page: paginationModel.page,
      pageSize: paginationModel.pageSize,
    },
    skip: !facilityIds.length,
  });

  const rows: FacilityRow[] =
    (facilities ?? previousFacilities)?.facilities.items.map((f) => ({
      ...f,
      amount: associations.find((a) => a.facilityId === f.id)?.amount ?? 0,
    })) ?? [];

  return (
    <DataGrid<FacilityRow>
      columns={[
        {
          headerName: "Organization",
          field: "tenant.name",
          flex: 1,
          valueGetter: ({ row }) => row.tenant?.name,
        },
        {
          headerName: "Facility",
          field: "name",
          flex: 1,
          valueGetter: (params) => params.row.name,
        },
        {
          headerName: "Facility Address",
          field: "facilityAddress",
          sortable: false,
          flex: 1,
          valueGetter: (params) => {
            const facility = params.row;
            const address = facility?.streetAddress1;
            if (address) {
              const { streetAddress1, city, state, zip } = facility;
              return `${streetAddress1}, ${city}, ${state} ${zip}`;
            }
            return "";
          },
        },
        {
          headerName: "Amount",
          field: "amount",
          flex: 1,
          editable: !disabled,
          valueFormatter(params) {
            return new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
            }).format(params.value);
          },
          sortable: false,
        },
        {
          field: "actions",
          type: "actions",
          getActions: (params) => [
            <GridActionsCellItem
              key="delete"
              icon={<Delete />}
              label="Delete"
              onClick={() => deleteFacility(params.row.id)}
            />,
          ],
        },
      ]}
      loading={facilitiesLoading}
      rows={rows}
      rowCount={facilities?.facilities.count ?? 0}
      processRowUpdate={(newRow) => {
        processRowUpdate({ facilityId: newRow.id, amount: newRow.amount });
        return newRow;
      }}
      sortModel={sortModel}
      onSortModelChange={setSortModel}
      pagination
      paginationMode="server"
      paginationModel={paginationModel}
      onPaginationModelChange={setPaginationModel}
    />
  );
};

export { AssociatedEntities };
