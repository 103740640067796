import { Box, Stack, Typography } from "@mui/material";
import { Controller } from "react-hook-form";
import {
  DocumentType,
  DocumentsForCommunicationQuery,
} from "generated-graphql/graphql";
import { useAlerts } from "components/Alerts/AlertProvider";
import { gql } from "generated-graphql";
import { useLazyQuery } from "@apollo/client";
import { OmnisearchDataGrid } from "components/OmnisearchDataGrid";
import { GridActionsCellItem, useGridApiRef } from "@mui/x-data-grid-premium";
import Download from "@mui/icons-material/Download";
import { Tooltip } from "@mui/material";
import { useMemo } from "react";
import { prettyPrintDateMed } from "util/dates";
import { documentTypeToLabel } from "util/constants";
import { startCase, compact } from "lodash";
import GET_DOCUMENT_DOWNLOAD_LINK_QUERY from "queries/getDocumentDownloadLink";
import { OmnisearchGridColDef } from "hooks/useOmnisearchDatagridSettings";
import { useCommunicationFormContext } from "../hooks/useCommunicationForm";

type Row = DocumentsForCommunicationQuery["documents"]["items"][number];

export const DOCUMENTS_FOR_COMMUNICATION = gql(`
  query DocumentsForCommunication($search: String, $page: Int, $pageSize: Int, $sort: [SortModel!]) {
    documents(search: $search, page: $page, pageSize: $pageSize, sort: $sort) {
      items {
        id
        title
        documentType
        storageLink
        fileExtension
        documentTags{
            id
            name
        }
        createdAt
        facilities {
          id
          name
          tierIIReports {
            id
            reportKind
            reportingYear
          }
        }
      }
      count
    }
  }
`);

export const DocumentsStep = () => {
  const alerts = useAlerts();
  const { control, watch, setValue, mode } = useCommunicationFormContext();
  const disabled = mode === "view";

  const tenant = watch("tenant");
  const facilityIds = watch("facilityIds") || [];

  const apiRef = useGridApiRef();

  const [getDocumentDownloadLink] = useLazyQuery(
    GET_DOCUMENT_DOWNLOAD_LINK_QUERY,
    {
      onCompleted: (data) => {
        if (!data?.getDocumentDownloadLink) {
          return alerts.error("Error fetching download link");
        }
        window.open(data.getDocumentDownloadLink, "_blank");
      },
      onError: (e) => {
        alerts.error("Error fetching download link", e);
      },
    }
  );

  const columns: OmnisearchGridColDef<Row>[] = useMemo(
    () => [
      {
        field: "title",
        headerName: "Name",
        flex: 0.75,
        sortable: true,
        renderCell(params) {
          return (
            <Box
              sx={{
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "nowrap",
              }}
            >
              {params.row.title}
            </Box>
          );
        },
      },
      {
        field: "documentType",
        headerName: "Type",
        flex: 0.5,
        sortable: true,
        valueGetter(params) {
          return documentTypeToLabel(params.row.documentType);
        },
        filterKeyType: "enum",
        enumValues: Object.values(DocumentType),
        enumPresentationFunction: (enumValue) => startCase(enumValue),
      },
      {
        field: "fileExtension",
        headerName: "File Type",
        flex: 0.25,
        sortable: true,
      },
      {
        field: "facility",
        headerName: "Facility",
        filterKeyType: "facility",
        flex: 0.5,
        sortable: false,
        renderCell(params) {
          const facilities = params.row.facilities ?? [];
          return (
            <Stack>
              {facilities.slice(0, 3).map((facility) => (
                <Typography key={facility.id} variant="body2">
                  {facility.name}
                </Typography>
              ))}
              {facilities.length > 3 && (
                <Typography variant="body2">...</Typography>
              )}
            </Stack>
          );
        },
      },
      {
        field: "createdAt",
        headerName: "Uploaded On",
        flex: 0.25,
        sortable: true,
        valueGetter(params) {
          return prettyPrintDateMed(params.row.createdAt);
        },
      },
      {
        field: "actions",
        type: "actions",
        width: 50,
        getActions: ({ row }) => [
          <Tooltip title="Download Document" key="download">
            <GridActionsCellItem
              onClick={() =>
                getDocumentDownloadLink({
                  variables: { id: row.id },
                })
              }
              label="Download Document"
              icon={<Download />}
            />
          </Tooltip>,
        ],
      },
    ],
    []
  );

  const facilityFilter = useMemo(
    () => (facilityIds.length ? `facilityId:${facilityIds.join("|")}` : ""),
    [facilityIds]
  );

  return (
    <Stack spacing={2}>
      <Typography>
        Select documents to be sent to all recipients. Cover letters will be
        attached in the next step.
      </Typography>
      <Box>
        <Controller
          name="attachmentDocumentIds"
          control={control}
          render={({ field, fieldState }) => (
            <>
              <Typography variant="subtitle1" gutterBottom>
                Select Documents ({field.value?.length} selected)
              </Typography>
              <OmnisearchDataGrid
                apiRef={apiRef}
                columns={columns}
                dataQuery={DOCUMENTS_FOR_COMMUNICATION}
                skip={!tenant?.id}
                onSelectedIdsChanged={(ids) => field.onChange(ids as string[])}
                selectedIds={field.value}
                isURLDriven={false}
                initialSortModel={[{ field: "createdAt", sort: "desc" }]}
                defaultSearch={
                  disabled
                    ? `id:${field.value?.join("|")}`
                    : `tenantId:${tenant?.id} ${facilityFilter}`.trim()
                }
                excludeFilterColumns={["createdAt"]}
                getItems={(data) => data.documents.items}
                getCount={(data) => data.documents.count ?? 0}
                noDataMessage="No documents found for the selected facilities."
                initialState={{
                  pinnedColumns: {
                    right: ["actions"],
                  },
                }}
                rowHeight="auto"
                initialPageSize={10}
                isRowSelectable={(params) => !disabled}
              />
              {fieldState.error && (
                <Typography
                  color="error"
                  variant="caption"
                  sx={{ mt: 1, display: "block" }}
                >
                  {fieldState.error.message}
                </Typography>
              )}
            </>
          )}
        />
      </Box>
    </Stack>
  );
};
