import { Box, Stack, Typography } from "@mui/material";
import { Controller } from "react-hook-form";
import { ReportsGrid } from "../components/ReportsGrid";
import { useCommunicationFormContext } from "../hooks/useCommunicationForm";

export const ReportsStep = () => {
  const { control, watch, mode } = useCommunicationFormContext();
  const disabled = mode === "view";

  const facilityIds = watch("facilityIds") || [];

  return (
    <Stack spacing={3}>
      <Box>
        <Controller
          name="tierIIReportIds"
          control={control}
          render={({ field, fieldState }) => (
            <>
              <Typography variant="subtitle1" gutterBottom>
                Select Reports ({field.value.length} selected)
              </Typography>
              <ReportsGrid
                facilityIds={facilityIds}
                selectedReportIds={field.value}
                onReportSelect={(newReportIds) => {
                  field.onChange(newReportIds);
                }}
                disabled={disabled}
              />
              {fieldState.error && (
                <Typography
                  color="error"
                  variant="caption"
                  sx={{ mt: 1, display: "block" }}
                >
                  {fieldState.error.message}
                </Typography>
              )}
            </>
          )}
        />
      </Box>
    </Stack>
  );
};
