import { Stack } from "@mui/material";
import { OmnisearchDataGrid } from "components/OmnisearchDataGrid";
import {
  NotificationsQuery,
  NotificationsQueryVariables,
} from "generated-graphql/graphql";
import { useBreadcrumb } from "hooks/useBreadcrumbs";
import { GET_NOTIFICATIONS } from "hooks/useNotifications";

type NotificationRow = NotificationsQuery["notifications"]["items"][number];

export const Notifications = () => {
  useBreadcrumb({
    label: "Notifications",
  });

  return (
    <Stack py={3}>
      <OmnisearchDataGrid<
        NotificationsQuery,
        NotificationsQueryVariables,
        NotificationRow
      >
        persistenceKey="staffNotifications"
        columns={[
          {
            field: "message",
            headerName: "Message",
            flex: 1,
          },
          {
            field: "organization",
            headerName: "Organization",
            flex: 0.5,
            valueGetter(params) {
              return params.row.tenant?.name;
            },
          },
          {
            field: "facility",
            headerName: "Facility",
            flex: 0.5,
            valueGetter(params) {
              return params.row.facility?.name;
            },
          },
          {
            field: "tierIIReport",
            headerName: "Tier II Report",
            flex: 0.5,
            valueGetter(params) {
              const report = params.row.tierIIReport;

              if (!report) {
                return "";
              }

              return `${report.reportKind} - ${report.reportingYear}`;
            },
          },
        ]}
        dataQuery={GET_NOTIFICATIONS}
        getItems={(data) => data.notifications.items}
        getCount={(data) => data.notifications.count}
        excludeFilterColumns={["tierIIReport"]}
      />
    </Stack>
  );
};
