import {
  ActivityType,
  GetTierIiReportQuery,
} from "../generated-graphql/graphql";

type Activity = NonNullable<
  GetTierIiReportQuery["tierIIReport"]["currentWorkflow"]
>["activities"][0];

export function getActivityTitle(
  activity: Pick<Activity, "description" | "type">
): string {
  const activityDescription = activity.description
    ? ` - ${activity.description}`
    : "";
  return `${convertEnumToHumanReadable(activity.type)}${activityDescription}`;
}

export function convertEnumToHumanReadable(value: string): string {
  // Special cases for abbreviations
  const specialCases: Partial<Record<ActivityType, string>> = {
    WaitForAllCNReports: "Wait For All CN Reports",
    WaitForAllLEPC: "Wait For All LEPC Reports",
    WaitForAllFD: "Wait For All FD Reports",
  };

  if (value in specialCases) {
    return specialCases[value as ActivityType] ?? value;
  }

  return value
    .replace(/([A-Z])/g, " $1") // Insert space before each capital letter
    .replace(/^./, (str) => str.toUpperCase()); // Capitalize the first letter
}

export const WaitForAllActivityTypes: ActivityType[] = [
  ActivityType.WaitForAllCnReports,
  ActivityType.WaitForAllCredentialReports,
  ActivityType.WaitForAllAltIdReports,
  ActivityType.WaitForAllStateReports,
  ActivityType.WaitForAllFd,
  ActivityType.WaitForAllLepc,
];
