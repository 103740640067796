import { gql } from "generated-graphql";

export const FIRE_DEPARTMENT_SEARCH = gql(`
  query FireDepartmentSearch($search: String, $page: Int, $pageSize: Int, $sort: [SortModel!], $tenantId: String) {
    fireDepartmentSearch(search: $search, page: $page, pageSize: $pageSize, sort: $sort, tenantId: $tenantId) {
      items {
        id,
        name,
        mailingAddress,
        mailingAddressLine2,
        city,
        state,
        zip,
        county,
        phone,
        email,
        firstName,
        lastName,
        title,
        latitude,
        longitude,
        additionalComments,
        stateSubmitsToFireDept,
        needsT2sFile,
        isEmailed,
        isMailed,
        isSubmittedViaPortal
        portalId,
        portalName
        issues {
          ...issue
        }
      }
      count
    }
  }
`);

export const GET_FIRE_DEPARTMENT = gql(`
  query FireDepartment($id: String!) {
    fireDepartment(id: $id) {
        id,
        name,
        mailingAddress,
        mailingAddressLine2,
        city,
        state,
        zip,
        county,
        phone,
        email,
        firstName,
        lastName,
        title,
        latitude,
        longitude,
        additionalComments,
        stateSubmitsToFireDept,
        needsT2sFile,
        isEmailed,
        isMailed,
        isSubmittedViaPortal
        portalId,
        portalName
        deactivated
        issues {
          ...issue
        }
    }
  }
`);

export const UPSERT_FIRE_DEPARTMENT = gql(`
  mutation UpsertFireDepartment($id: String, $input: FireDepartmentInput!) {
    upsertFireDepartment(id: $id, input: $input) {
      id,
      name,
      mailingAddress,
      mailingAddressLine2,
      city,
      state,
      zip,
      county,
      phone,
      email,
      firstName,
      lastName,
      title,
      latitude,
      longitude,
      additionalComments,
      stateSubmitsToFireDept,
      needsT2sFile,
      isEmailed,
      isMailed,
      isSubmittedViaPortal
      portalId,
      portalName
    }
  }
`);

export const DELETE_FIRE_DEPARTMENT = gql(`
  mutation DeleteFireDepartment($id: String!) {
    deleteFireDepartment(id: $id) {
      id
    }
  }
`);

export const VALIDATE_FIRE_DEPARTMENT_INPUT = gql(`
  query ValidateFireDepartmentInput($id: String, $data: FireDepartmentInput!) {
    fireDepartmentInputValidator(id: $id, data: $data) {
      ...issue
    }
  }
`);
