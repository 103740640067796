import { useQuery } from "@apollo/client";
import {
  Checkbox,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { GridSortModel } from "@mui/x-data-grid-premium";
import { ConfirmPersonChangesModal } from "components/Contacts/ConfirmPersonChangesModal";
import { EditPerson } from "components/Contacts/EditPerson";
import { Dialog } from "components/Dialog";
import { FloatingSaveBar } from "components/FloatingSaveBar";
import { ErrorDisplay } from "components/Forms/ErrorDisplay";
import { PersonPicker } from "components/PersonPicker";
import { contactRoleToLabel } from "../../util/facilityContacts";
import { gql } from "generated-graphql";
import {
  ContactReportRole,
  FacilityContactsFragment,
  GetPersonQuery,
  Issue,
  Permission,
  PersonPickerFragment,
} from "generated-graphql/graphql";
import { useAuthorization } from "hooks/useAuthorization";
import { useEpcraContactRoles } from "hooks/useEpcraContactRoles";
import { usePaginationModel } from "hooks/usePaginationModel";
import { useValidatingForm } from "hooks/useValidatingForm";
import { useCallback, useMemo, useState } from "react";
import { Controller, FormProvider } from "react-hook-form";
import { useParams } from "react-router-dom";
import { useFacility } from "routes/Customer/Facility/useFacility";
import { hasCriticalIssues } from "util/forms";
import { v4 as uuid } from "uuid";
import { useFacilityContactInputValidation } from "../../routes/Customer/Chemicals/Report/validationHooks/useFacilityContactInputValidation";

type Row = NonNullable<FacilityContactsFragment["contacts"]>[number];

const GET_PERSON_FACILITIES = gql(`
  query GetPersonFacilities($personId: ID!, $page: Int, $pageSize: Int, $sort: [SortModel!]) {
    personFacilities(id: $personId, page: $page, pageSize: $pageSize, sort: $sort) {
      items {
        id
        name
        city
        state
      }
      count
    }
  }
`);

const GET_PERSON = gql(`
  query GetPerson($id: ID!) {
    person(id: $id) {
      id
      first
      last
      phones {
        number
        type
      }
      email
      title
      company

      streetAddress1
      streetAddress2
      city
      country
      state
      zip

      tankOwnerType

      user {
        id
        email
      }
    }
  }
`);

export type EditContactFormData = {
  id?: string;
  reportingRoles: ContactReportRole[];
  person: GetPersonQuery["person"];
  isPrimaryContact?: boolean;
};

export const EditFacilityContactDialog = ({
  onClose,
  onSave,
  data,
  contacts,
  addingContact,
  setEditRow,
  facilityId,
  initialIssues,
}: {
  onClose: () => void;
  onSave: (
    roles: ContactReportRole[],
    person: GetPersonQuery["person"],
    isPrimaryContact?: boolean
  ) => void;
  data: Row | null;
  contacts: Row[];
  addingContact: ContactReportRole | undefined;
  setEditRow: (row: Row | null) => void;
  facilityId: string;
  initialIssues: Issue[];
}) => {
  const [selectedPerson, setSelectedPerson] =
    useState<PersonPickerFragment | null>(data?.person ?? null);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [paginationModel, setPaginationModel] = usePaginationModel(5);
  const [sortModel, setSortModel] = useState<GridSortModel>([
    { field: "name", sort: "asc" },
  ]);
  const { tenantId } = useParams();
  const facility = useFacility(facilityId);

  const { hasPermissionForFacility } = useAuthorization();
  const readOnly = !hasPermissionForFacility(facilityId, [
    Permission.WriteFacilityContact,
  ]);
  const theme = useTheme();

  const { data: person, loading } = useQuery(GET_PERSON, {
    variables: { id: selectedPerson?.id ?? "" },
    skip: !selectedPerson || !selectedPerson.id,
    fetchPolicy: "cache-and-network",
  });

  const {
    data: personFacilitiesData,
    previousData,
    loading: facilityDataLoading,
  } = useQuery(GET_PERSON_FACILITIES, {
    variables: {
      personId: selectedPerson?.id ?? "",
      pageSize: paginationModel.pageSize,
      page: paginationModel.page,
      sort: sortModel,
    },
    skip: !(selectedPerson?.id ?? "").length,
    fetchPolicy: "cache-and-network",
  });
  const { items: facilities, count: facilityCount } =
    personFacilitiesData?.personFacilities ??
      previousData?.personFacilities ?? { count: 0, items: [] };

  const defaultValues: EditContactFormData = useMemo(() => {
    let reportingRoles = data?.reportingRoles ?? [];

    if (addingContact) {
      reportingRoles = data?.reportingRoles
        ? [...data.reportingRoles, addingContact]
        : [addingContact];
    }

    return {
      id: data?.id ?? "",
      reportingRoles,
      isPrimaryContact: data?.isPrimaryContact ?? undefined,
      person: selectedPerson
        ? person?.person ?? {
            id: selectedPerson.id,
            first: selectedPerson.first,
            last: selectedPerson.last,
            email: selectedPerson.email,
          }
        : { id: uuid() },
    };
  }, [
    selectedPerson,
    person?.person,
    data?.reportingRoles,
    data?.id,
    data?.isPrimaryContact,
    addingContact,
  ]);

  const changeSelectedPerson = (person: PersonPickerFragment | null) => {
    const contact = contacts?.find((c) => c.person.id === person?.id);
    const currentValues = getValues();

    if (contact) {
      setEditRow({
        ...contact,
        // this will add preselected roles to the contact with their existing roles
        reportingRoles:
          contact.reportingRoles.length > 0
            ? Array.from(
                new Set([
                  ...contact.reportingRoles,
                  ...currentValues.reportingRoles,
                ])
              )
            : currentValues.reportingRoles,
      });
    } else if (currentValues.reportingRoles.length > 0) {
      setEditRow({
        reportingRoles: currentValues.reportingRoles,
        id: data?.id ?? "",
        person: person
          ? {
              id: person.id,
              first: person.first,
              last: person.last,
              email: person.email,
              tenantId: tenantId ?? "",
            }
          : { id: uuid(), tenantId: tenantId ?? "" },
      });
    }

    setSelectedPerson(person);
  };

  const form = useValidatingForm<EditContactFormData>(
    defaultValues,
    initialIssues,
    useFacilityContactInputValidation(facilityId)
  );
  const {
    handleSubmit,
    formState,
    control,
    issues: formIssues,
    getValues,
  } = form;

  const onSubmit = useCallback(
    async (data: EditContactFormData) => {
      if (
        data?.person?.id.length &&
        Object.keys(formState.dirtyFields.person ?? {}).length &&
        facilityCount > 1
      ) {
        setShowConfirmModal(true);
      } else {
        onSave(data.reportingRoles, data.person, data.isPrimaryContact);
      }
    },
    [formState.dirtyFields.person, onSave, facilityCount]
  );

  const editingMode = useMemo(() => {
    if (readOnly) {
      return "displayOnly";
    }
    if (selectedPerson && selectedPerson.id) {
      return "edit";
    } else if (selectedPerson) {
      return "add";
    }
    return "displayOnly";
  }, [selectedPerson, readOnly]);

  const dialogTitle = useMemo(() => {
    if (editingMode === "displayOnly") {
      return "View Contact";
    }
    if (editingMode === "add") {
      return "Add Contact";
    }
    if (editingMode === "edit") {
      return "Edit Contact";
    }
  }, [editingMode]);

  const { roles } = useEpcraContactRoles(facility.data?.facility?.state);

  return (
    <Dialog
      open
      onClose={onClose}
      maxWidth="md"
      PaperProps={{ sx: { minHeight: "95vh" } }}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle minWidth={900}>{dialogTitle}</DialogTitle>
        <DialogContent>
          {editingMode === "edit" ? (
            <Typography variant="body1" sx={{ mb: theme.spacing(3) }}>
              You cannot update this contact's email or name because it refers
              to a specific person in your organization. If you'd like to change
              the person associated with this reporting role, please create or
              select a new person from contact catalog. Reach out to your Encamp
              Customer Service Manager if you have additional questions.
            </Typography>
          ) : (
            <Grid item pt={theme.spacing(1)}>
              <PersonPicker
                disabled={data !== null}
                value={selectedPerson}
                label="Select Existing or Create New Contact"
                onSelectPerson={changeSelectedPerson}
                helperText={(value) => (!value?.id ? "New Contact" : undefined)}
              />
            </Grid>
          )}

          <Stack
            direction="row"
            divider={<Divider orientation="vertical" flexItem />}
            spacing={theme.spacing(3)}
          >
            <Grid container spacing={theme.spacing(3)}>
              <Grid item xs={12}>
                <FormProvider {...form}>
                  <EditPerson
                    mode={editingMode}
                    isStepper={true}
                    issues={formIssues}
                    isUser={!!person?.person?.user}
                    loading={loading}
                    showTankOwnerType={form
                      .getValues()
                      .reportingRoles.some(
                        (rr) => rr === ContactReportRole.EpcraCaTankOwner
                      )}
                  />
                </FormProvider>
              </Grid>
            </Grid>

            <FormControl sx={{ width: { xs: theme.spacing(40) } }}>
              <Stack spacing={theme.spacing(1)}>
                <Controller
                  control={control}
                  name="reportingRoles"
                  render={({ field, fieldState: { error } }) => (
                    <FormGroup>
                      <Typography variant="subtitle2">
                        EPCRA Reporting Roles
                      </Typography>
                      {error && <ErrorDisplay error={error} sx={{ m: 0 }} />}
                      {roles.map((option) => (
                        <FormControlLabel
                          key={option}
                          disabled={editingMode === "displayOnly"}
                          label={
                            <Typography variant="body2">
                              {contactRoleToLabel(option)}
                            </Typography>
                          }
                          control={
                            <Checkbox
                              checked={!!field.value?.includes(option)}
                              onChange={(e) => {
                                if (e.target.checked) {
                                  field.onChange([
                                    ...(field.value ?? []),
                                    option,
                                  ]);
                                } else {
                                  field.onChange(
                                    field.value?.filter(
                                      (v: string) => v !== option
                                    )
                                  );
                                }
                              }}
                            />
                          }
                        />
                      ))}
                    </FormGroup>
                  )}
                />

                {facility.data?.facility?.state === "CA" && (
                  <>
                    <Divider sx={{ my: 2 }} />

                    <FormGroup>
                      <Controller
                        control={control}
                        name="isPrimaryContact"
                        render={({ field }) => (
                          <FormControlLabel
                            disabled={editingMode === "displayOnly"}
                            label={
                              <Typography variant="body2">
                                Primary Contact
                              </Typography>
                            }
                            control={
                              <Checkbox
                                checked={field.value}
                                onChange={(e) =>
                                  field.onChange(e.target.checked)
                                }
                              />
                            }
                          />
                        )}
                      />
                    </FormGroup>
                  </>
                )}
              </Stack>
            </FormControl>
          </Stack>

          {showConfirmModal && (
            <ConfirmPersonChangesModal
              pagination
              paginationMode="server"
              paginationModel={paginationModel}
              onPaginationModelChange={setPaginationModel}
              sortingMode="server"
              sortModel={sortModel}
              onSortModelChange={setSortModel}
              facilities={facilities}
              facilityCount={facilityCount}
              onClose={() => setShowConfirmModal(false)}
              onConfirm={() =>
                handleSubmit(async (data) => {
                  onSave(
                    data.reportingRoles,
                    data.person,
                    data.isPrimaryContact
                  );
                  setShowConfirmModal(false);
                })()
              }
              personFullName={`${selectedPerson?.first} ${selectedPerson?.last}`}
              saving={facilityDataLoading}
            />
          )}
        </DialogContent>
        <DialogActions sx={{ pr: theme.spacing(2) }}>
          <FloatingSaveBar
            onCancel={onClose}
            hideSave={editingMode === "displayOnly"}
            saveDisabled={
              editingMode === "displayOnly" ||
              hasCriticalIssues(formIssues) ||
              getValues().reportingRoles.length === 0
            }
            issues={formIssues}
            sx={{ m: 0, px: 0, py: 1 }}
          />
        </DialogActions>
      </form>
    </Dialog>
  );
};
