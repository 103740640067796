import { gql } from "generated-graphql";
import {
  LepcPickerFragment,
  LepcsQuery,
  LepcSearchForPickerQuery,
} from "generated-graphql/graphql";
import { forwardRef } from "react";
import { ControllerFieldState } from "react-hook-form";
import EncampAutocomplete from "./Forms/EncampAutocomplete";

// Base props shared between both picker variants
type BaseLepcPickerProps = {
  onChange?: (value: LepcPickerFragment | null) => void;
  value?: LepcPickerFragment | null;
  disabled?: boolean;
} & ControllerFieldState;

// Props specific to state/county based picker
type StateCountyLepcPickerProps = BaseLepcPickerProps & {
  facilityState?: string;
  facilityCounty?: string;
};

// Props specific to facility based picker
type FacilityLepcPickerProps = BaseLepcPickerProps & {
  facilityIds: string[];
};

// GraphQL fragments and queries
const LEPC_PICKER_FRAGMENT = gql(`
  fragment LepcPicker on Lepc {
    id
    name
    mailingAddress
    mailingAddressLine2
    contactName
    city
    state
    zip
    county
    phone
    email
    isEmailed
    isMailed
    preferUncertified
  }
`);

const LEPCS_BY_STATE_COUNTY_QUERY = gql(`
  query Lepcs($state: String, $county: String) {
    lepcs(state: $state, county: $county) {
      items {
        ...LepcPicker
      }
      count
    }
  }
`);

const LEPC_SEARCH_BY_FACILITY_QUERY = gql(`
  query LepcSearchForPicker($search: String, $page: Int, $pageSize: Int, $sort: [SortModel!], $tenantId: String) {
    lepcSearch(search: $search, page: $page, pageSize: $pageSize, sort: $sort, tenantId: $tenantId) {
      items {
        ...LepcPicker
      }
      count
    }
  }
`);

// Shared function to format LEPC option labels
const getLepcOptionLabel = (lepc: LepcPickerFragment) => {
  let label = `${lepc.name} ${lepc.city}, ${lepc.state}`;
  if (lepc.county) {
    label = `${lepc.name} ${lepc.city}, ${lepc.county}, ${lepc.state}`;
  }
  return label;
};

// Shared function to get items from query response
// State/County based picker
export const LepcPicker = forwardRef<
  HTMLDivElement,
  StateCountyLepcPickerProps
>(function LepcPicker(
  { facilityState, facilityCounty, onChange, value, error, disabled },
  ref
) {
  return (
    <EncampAutocomplete
      query={LEPCS_BY_STATE_COUNTY_QUERY}
      variables={{
        state: facilityState,
        county: facilityCounty,
      }}
      getItems={(data: LepcsQuery) => data.lepcs?.items ?? []}
      getOptionLabel={getLepcOptionLabel}
      onChange={onChange}
      value={value}
      error={error}
      disabled={disabled}
    />
  );
});

// Facility based picker
export const LepcPickerWithFacilityFilter = forwardRef<
  HTMLDivElement,
  FacilityLepcPickerProps
>(function LepcPickerWithFacilityFilter(
  { facilityIds, onChange, value, error, disabled },
  ref
) {
  return (
    <EncampAutocomplete
      query={LEPC_SEARCH_BY_FACILITY_QUERY}
      variables={{
        search: `facilityId:${facilityIds.join("|")}`,
      }}
      getItems={(data: LepcSearchForPickerQuery) =>
        data.lepcSearch?.items ?? []
      }
      getOptionLabel={getLepcOptionLabel}
      onChange={onChange}
      value={value}
      error={error}
      disabled={disabled}
    />
  );
});
