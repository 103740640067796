import { useMutation } from "@apollo/client";
import {
  Box,
  Checkbox,
  CircularProgress,
  Divider,
  FormControlLabel,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { gql } from "generated-graphql";
import {
  ActivityStatus,
  ActivityUserInput as ActivityUserInputGql,
  ActivityUserInputType,
  GetTierIiReportQuery,
} from "generated-graphql/graphql";
import { useDebounce } from "hooks/useDebounce";
import { useEffect, useState } from "react";

type Props = {
  activity: NonNullable<
    GetTierIiReportQuery["tierIIReport"]["currentWorkflow"]
  >["activities"][0];
};

const UPDATE_ACTIVITY_INPUT_MUTATION = gql(`
  mutation SetValueOnActivityUserInput($id: ID!, $value: String!) {
    setValueOnActivityUserInput(id: $id, value: $value) {
      id
      value
}}`);

export const ActivityInputs = ({ activity }: Props) => {
  const inputs = activity.inputs;
  if (!inputs || inputs?.length === 0) {
    return null;
  }
  const disabled = [
    ActivityStatus.Completed,
    ActivityStatus.StaffCompleted,
  ].includes(activity.status);

  return (
    <Box sx={{ paddingTop: 1 }}>
      <Divider />
      <Typography variant="h6">User Inputs</Typography>
      {inputs.map((input, index) => {
        if (!input) {
          return null;
        }
        return (
          <ActivityUserInput key={index} input={input} disabled={disabled} />
        );
      })}
      <Divider />
    </Box>
  );
};

const ActivityUserInput = ({
  input,
  disabled,
}: {
  input: ActivityUserInputGql;
  disabled: boolean;
}) => {
  const { id, label, required, type, value: initialValue } = input;
  const [setInput, { loading }] = useMutation(UPDATE_ACTIVITY_INPUT_MUTATION);
  const [value, setValue] = useState(initialValue ?? "");
  const [debouncedInput] = useDebounce(value, 1000);

  useEffect(() => {
    if (debouncedInput !== initialValue) {
      // only call mutation when value changes
      setInput({
        variables: {
          id,
          value: debouncedInput,
        },
      });
    }
  }, [debouncedInput, id, initialValue, setInput]);

  switch (type) {
    case ActivityUserInputType.Boolean:
      return (
        <Stack direction="row" alignItems="center">
          <FormControlLabel
            control={
              <Checkbox
                required={required}
                disabled={disabled}
                checked={value === "true"}
                onChange={(e) => {
                  setValue(e.target.checked ? "true" : "false");
                }}
              />
            }
            label={label}
          />
          {loading && <CircularProgress size={20} />}
        </Stack>
      );
    case ActivityUserInputType.Number:
      return (
        <TextField
          sx={{ my: 1 }}
          disabled={disabled}
          required={required}
          label={label}
          value={value}
          onChange={(e) => {
            setValue(e.target.value);
          }}
          error={!!value && isNaN(Number(value))}
          helperText={
            !!value && isNaN(Number(value))
              ? "Value must be a plain number without commas, spaces, or symbols."
              : ""
          }
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {loading ? (
                  <CircularProgress size={20} />
                ) : (
                  <Box sx={{ width: 20 }} />
                )}
              </InputAdornment>
            ),
          }}
        />
      );
    default:
      return null;
  }
};
