import { Authenticator } from "@aws-amplify/ui-react";
import { Worker } from "@react-pdf-viewer/core";
import { AlertProvider } from "components/Alerts/AlertProvider";
import { HasFeature } from "components/HasFeature";
import { HasFlag } from "components/HasFlag";
import { HasPermission } from "components/HasPermission";
import { LocalizationProviderWrapper } from "components/LocalizationProvider";
import { Permission } from "generated-graphql/graphql";
import { ScreenWidthProvider } from "providers/screenWidth";
import { TokenProviderEnabler } from "providers/token";
import React from "react";
import ReactDOM from "react-dom/client";
import {
  createBrowserRouter,
  Navigate,
  Outlet,
  redirect,
  RouterProvider,
} from "react-router-dom";
import { ChemicalDetail } from "routes/Customer/Chemicals/Catalog/Chemical";
import { TenantChemicalsTable } from "routes/Customer/Chemicals/Catalog/TenantCatalog/TenantChemicalsTable";
import { TenantProductsTable } from "routes/Customer/Chemicals/Catalog/TenantCatalog/TenantProductsTable";
import { ContactsFacilitiesTable } from "routes/Customer/Chemicals/Contacts/facilities";
import { DocumentFacilitiesTable } from "routes/Customer/Chemicals/DocumentFacilitiesTable";
import { FacilitiesTable } from "routes/Customer/Chemicals/Facilities";
import { FacilityInventoryTable } from "routes/Customer/Chemicals/Inventory/facilities";
import { ChemicalSummary } from "routes/Customer/Chemicals/Inventory/Facility/ChemicalSummary";
import { FacilityChemical } from "routes/Customer/Chemicals/Inventory/Facility/FacilityChemical";
import { FacilityChemicalMeasurementDetails } from "routes/Customer/Chemicals/Inventory/Facility/FacilityChemicalMeasurementDetails";
import { FacilityProduct } from "routes/Customer/Chemicals/Inventory/Facility/FacilityProduct";
import { FacilityProductMeasurements } from "routes/Customer/Chemicals/Inventory/Facility/FacilityProductMeasurements";
import { InventoryDefaultNavigation } from "routes/Customer/Chemicals/Inventory/Facility/InventoryDefaultNavigation";
import TierIIReport from "routes/Customer/Chemicals/Report";
import { ChemicalsReportStep } from "routes/Customer/Chemicals/Report/Chemicals";
import { Summary } from "routes/Customer/Chemicals/Report/Chemicals/Summary";
import { ReportFacilityContacts } from "routes/Customer/Chemicals/Report/Contacts/ReportFacilityContacts";
import { Documents as ReportDocuments } from "routes/Customer/Chemicals/Report/Documents/Documents";
import { Facility } from "routes/Customer/Chemicals/Report/Facility";
import { Overview } from "routes/Customer/Chemicals/Report/Overview";
import { ProductsReportStep } from "routes/Customer/Chemicals/Report/Products/index";
import { ProductMeasurements } from "routes/Customer/Chemicals/Report/Products/ProductMeasurements";
import { Regulatory } from "routes/Customer/Chemicals/Report/Regulatory";
import { StateInformation } from "routes/Customer/Chemicals/Report/StateInformation";
import { VerifyAndSubmit } from "routes/Customer/Chemicals/Report/VerifyAndSubmit";
import { ReportsTable } from "routes/Customer/Chemicals/Reports";
import { Credentials as TenantCredentials } from "routes/Customer/Credentials";
import { Documents as TenantDocuments } from "routes/Customer/Documents";
import { Facility as FacilityIndex } from "routes/Customer/Facility";
import { Contacts as FacilityContacts } from "routes/Customer/Facility/Contacts";
import { Documents as FacilityDocuments } from "routes/Customer/Facility/Documents";
import { FacilityProfile } from "routes/Customer/Facility/FacilityProfile";
import { Organizations } from "routes/Customer/Organizations";
import { Tasks } from "routes/Customer/Tasks";
import { Users as TenantUsers } from "routes/Customer/Users";
import { WasteFacility } from "routes/Customer/Waste/Facilities/Facility/WasteFacility";
import { WasteFacilities } from "routes/Customer/Waste/Facilities/WasteFacilities";
import { Manifests } from "routes/Customer/Waste/Manifests";
import { NotFound } from "routes/NotFound";
import { Agencies } from "routes/Staff/Agencies";
import { FireDepartments } from "routes/Staff/Agencies/FireDepartments";
import { Lepcs } from "routes/Staff/Agencies/Lepcs";
import { NJLeadAgencies } from "routes/Staff/Agencies/NJLeadAgencies";
import { NJPoliceDepartments } from "routes/Staff/Agencies/NJPoliceDepartments";
import { Activities } from "routes/Staff/Fulfillment/ReportDetails/Activities";
import { ActivityDetails } from "routes/Staff/Fulfillment/ReportDetails/Activities/ActivityDetails";
import { Documents as StaffDocuments } from "routes/Staff/Fulfillment/ReportDetails/Documents";
import { Payments as ReportPayments } from "routes/Staff/Fulfillment/ReportDetails/Payments";
import { WebAutomationJobInfo } from "routes/Staff/Fulfillment/ReportDetails/WebAutomationJobInfo";
import { WorkflowLogs } from "routes/Staff/Fulfillment/ReportDetails/WorkflowLogs";
import { ActivityLogs } from "routes/Staff/Fulfillment/ReportDetails/WorkflowLogs/ActivityLogs";
import { Jobs } from "routes/Staff/Jobs";
import { Notifications } from "routes/Staff/Notifications";
import { Payments } from "routes/Staff/Payments";
import { WorkflowConfig } from "routes/Staff/WorkflowConfig";
import configureSentry from "sentry";
import { App } from "./App";
import configureMuiInstallation from "./mui-config";
import { ApolloProvider } from "./providers/apollo";
import { AuthProvider } from "./providers/auth";
import { ThemeModeProvider } from "./providers/themeMode";
import { CurrentUserProvider } from "./providers/user";
import { Home } from "./routes";
import { Customer } from "./routes/Customer";
import { Chemicals as ChemicalsIndex } from "./routes/Customer/Chemicals";
import { FacilityInventoryDetail } from "./routes/Customer/Chemicals/Inventory/Facility";
import { Overview as ChemicalsOverview } from "./routes/Customer/Chemicals/Overview/index";
import { Contacts as TenantContacts } from "./routes/Customer/Contacts";
import { EchoProfile } from "./routes/Customer/Facility/EchoProfile";
import { NpdesProfile } from "./routes/Customer/Facility/NpdesProfile";
import { RcraProfile } from "./routes/Customer/Facility/RcraProfile";
import { Insights as InsightsIndex } from "./routes/Customer/Insights";
import { Benchmark as InsightsBenchmark } from "./routes/Customer/Insights/Benchmark";
import { Echo as InsightsEcho } from "./routes/Customer/Insights/Echo";
import { EchoDetail } from "./routes/Customer/Insights/Echo/EchoDetail";
import { Npdes as InsightsNpdes } from "./routes/Customer/Insights/Npdes/Npdes";
import { NpdesDetail } from "./routes/Customer/Insights/Npdes/NpdesDetail";
import { Overview as InsightsOverview } from "./routes/Customer/Insights/Overview";
import { RcraInfo as InsightsRcraInfo } from "./routes/Customer/Insights/RcraInfo/RcraInfo";
import { RcraInfoDetail } from "./routes/Customer/Insights/RcraInfo/RcraInfoDetail";
import { Waste as WasteIndex } from "./routes/Customer/Waste";
import { Oops } from "./routes/Oops";
import { PrivateRoute } from "./routes/PrivateRoute";
import { Staff } from "./routes/Staff";
import { DemoOrganizationCreator } from "./routes/Staff/DemoOrganizationCreator";
import { FulfillmentDashboard } from "./routes/Staff/Fulfillment";
import { ReportDetails } from "./routes/Staff/Fulfillment/ReportDetails";
import { Implementation } from "./routes/Staff/Implementation";
import { ImplementationTabs } from "./routes/Staff/Implementation/OrganizationDetails";
import { SubmissionErrorsDashboard } from "./routes/Staff/SubmissionErrors";
import { Communications } from "./routes/Staff/MailRoom/Communications";
import { CommunicationDetails } from "./routes/Staff/MailRoom/Communications/CommunicationDetails";
import { CommunicationsTable } from "./routes/Staff/MailRoom/Communications/CommunicationsTable";
import { CreateCommunications } from "./routes/Staff/MailRoom/Communications/CreateCommunications";

// Set up Sentry integration
configureSentry();

// Set up the MUI theme and license key
configureMuiInstallation();

const router = createBrowserRouter([
  {
    element: <PrivateRoute />,
    errorElement: <Oops />,
    children: [
      {
        path: "/",
        element: <App />,
        children: [
          {
            errorElement: <Oops />,
            path: "staff",
            element: <Staff />,
            children: [
              {
                index: true,
                element: <Home name={"Hero"} />,
              },
              {
                path: "fulfillment",
                element: <FulfillmentDashboard />,
                children: [
                  {
                    path: ":reportId",
                    element: <ReportDetails />,
                    children: [
                      {
                        path: "activities",
                        element: <Activities />,
                        children: [
                          {
                            path: ":activityId",
                            element: <ActivityDetails />,
                          },
                        ],
                      },
                      {
                        path: "documents",
                        element: <StaffDocuments />,
                      },
                      {
                        path: "payments",
                        element: <ReportPayments />,
                      },
                      {
                        path: "workflow-logs",
                        element: <WorkflowLogs />,
                        children: [
                          {
                            path: ":activityId",
                            element: <ActivityLogs />,
                          },
                        ],
                      },
                      {
                        path: "job",
                        element: <WebAutomationJobInfo />,
                      },
                    ],
                  },
                ],
              },
              {
                path: "implementation",
                element: <Implementation />,
                children: [
                  {
                    path: ":tenantId",
                    element: <ImplementationTabs />,
                  },
                ],
              },
              {
                path: "agencies",
                element: <Agencies />,
                children: [
                  {
                    path: "lepcs",
                    element: <Lepcs />,
                  },
                  {
                    path: "fireDepartments",
                    element: <FireDepartments />,
                  },
                  {
                    path: "njPoliceDepartments",
                    element: <NJPoliceDepartments />,
                  },
                  {
                    path: "njLeadAgencies",
                    element: <NJLeadAgencies />,
                  },
                ],
              },
              {
                path: "submission-errors",
                element: <SubmissionErrorsDashboard />,
                children: [
                  {
                    path: ":reportId",
                    element: <ReportDetails />,
                  },
                ],
              },
              {
                path: "mail-room",
                element: <Communications />,
                children: [
                  {
                    index: true,
                    element: <Navigate to="status" replace />,
                  },
                  {
                    path: "status",
                    element: <CommunicationsTable />,
                  },
                  {
                    path: "send",
                    element: <CreateCommunications />,
                  },
                  {
                    path: "status/:communicationId",
                    element: <CommunicationDetails />,
                  },
                ],
              },
              {
                path: "workflow-config",
                element: <WorkflowConfig />,
              },
              {
                path: "notifications",
                element: <Notifications />,
              },
              {
                path: "payments",
                element: <Payments />,
              },
              {
                path: "jobs/:jobId?",
                element: <Jobs />,
              },
              {
                path: "demoOrganizationCreator",
                element: <DemoOrganizationCreator />,
              },
            ],
          },
          {
            path: "o/:tenantId",
            element: <Customer />,
            errorElement: <Oops />,
            children: [
              {
                path: "organizations",
                element: <Organizations />,
              },
              {
                path: "facilities",
                element: (
                  <HasPermission permissions={[Permission.RouteFacilities]}>
                    <FacilitiesTable />
                  </HasPermission>
                ),
              },
              {
                path: "insights",
                element: (
                  <HasPermission permissions={[Permission.RouteInsights]}>
                    <InsightsIndex />
                  </HasPermission>
                ),
                children: [
                  {
                    path: "overview",
                    element: <InsightsOverview />,
                  },
                  {
                    path: "benchmark",
                    element: <InsightsBenchmark />,
                  },
                  {
                    path: "echo",
                    element: <InsightsEcho />,
                  },
                  {
                    path: "rcra-info",
                    element: <InsightsRcraInfo />,
                  },
                  {
                    path: "npdes",
                    element: <InsightsNpdes />,
                  },
                  {
                    path: "rcra-info/:handlerId",
                    element: <RcraInfoDetail />,
                  },
                  {
                    path: "echo/:registryId",
                    element: <EchoDetail />,
                  },
                  {
                    path: "npdes/:npdesId",
                    element: <NpdesDetail />,
                  },
                ],
              },
              {
                path: "facilities/:facilityId",
                element: <FacilityIndex />,
                children: [
                  {
                    path: "profile",
                    element: <FacilityProfile />,
                  },
                  {
                    path: "echoInsights",
                    element: <EchoProfile />,
                  },
                  {
                    path: "rcraInsights",
                    element: <RcraProfile />,
                  },
                  {
                    path: "npdesInsights",
                    element: <NpdesProfile />,
                  },
                  {
                    path: "contacts",
                    element: (
                      <HasPermission permissions={[Permission.RouteContacts]}>
                        <FacilityContacts />
                      </HasPermission>
                    ),
                  },
                  {
                    path: "documents",
                    element: (
                      <HasPermission permissions={[Permission.RouteDocuments]}>
                        <FacilityDocuments />
                      </HasPermission>
                    ),
                  },
                  {
                    path: "credentials/:credentialId?",
                    element: (
                      <HasPermission
                        permissions={[Permission.RouteEpcraCredentials]}
                      >
                        <TenantCredentials />
                      </HasPermission>
                    ),
                  },
                ],
              },
              {
                path: "chemicals",
                element: <ChemicalsIndex />,
                children: [
                  {
                    path: "overview",
                    element: (
                      <HasFlag flag="chemicals-overview" fallback="../reports">
                        <ChemicalsOverview />
                      </HasFlag>
                    ),
                  },
                  {
                    path: "reports",
                    element: (
                      <HasPermission
                        permissions={[Permission.RouteEpcraReports]}
                      >
                        <Outlet />
                      </HasPermission>
                    ),
                    children: [
                      {
                        index: true,
                        element: <ReportsTable />,
                      },
                      {
                        path: ":reportId/*",
                        element: <TierIIReport />,
                        children: [
                          {
                            index: true,
                            element: <Overview />,
                          },
                          {
                            path: "facility",
                            element: <Facility />,
                          },
                          {
                            path: "regulatory",
                            element: <Regulatory />,
                          },
                          {
                            path: "chemicals/:facilityChemicalId?",
                            element: (
                              <HasFeature not products fallback="../products">
                                <ChemicalsReportStep />
                              </HasFeature>
                            ),
                          },
                          {
                            path: "chemicals/measurements/:chemicalId",
                            element: (
                              <HasFeature not products fallback="../chemicals">
                                <FacilityChemicalMeasurementDetails />
                              </HasFeature>
                            ),
                          },
                          {
                            path: "products",
                            element: (
                              <HasFeature products fallback="../chemicals">
                                <ProductsReportStep />
                              </HasFeature>
                            ),
                          },
                          {
                            path: "products/:productId",
                            element: <ProductMeasurements />,
                          },
                          {
                            path: "other-chemicals/:facilityChemicalId?",
                            element: (
                              <HasFeature products fallback="../chemicals">
                                <ChemicalsReportStep />
                              </HasFeature>
                            ),
                          },
                          {
                            path: "other-chemicals/measurements/:chemicalId",
                            element: (
                              <HasFeature products fallback="../chemicals">
                                <FacilityChemicalMeasurementDetails />
                              </HasFeature>
                            ),
                          },
                          {
                            path: "chemical-summary",
                            element: <Summary />,
                          },
                          {
                            path: "contacts",
                            element: <ReportFacilityContacts />,
                          },
                          {
                            path: "documents",
                            element: <ReportDocuments />,
                          },
                          {
                            path: "state-information",
                            element: <StateInformation />,
                          },
                          {
                            path: "review",
                            element: <Overview />,
                          },
                          {
                            path: "verify-submit",
                            element: <VerifyAndSubmit />,
                          },
                        ],
                      },
                    ],
                  },
                  {
                    path: "catalog",
                    element: (
                      <HasPermission
                        permissions={[Permission.RouteEpcraProducts]}
                      >
                        <Outlet />
                      </HasPermission>
                    ),
                    children: [
                      {
                        index: true,
                        element: <Navigate to="chemicals" />,
                      },
                      {
                        path: "chemicals",
                        element: <TenantChemicalsTable />,
                      },
                      {
                        path: "chemicals/:chemicalId",
                        element: <ChemicalDetail />,
                      },
                    ],
                  },
                  {
                    path: "inventory",
                    children: [
                      {
                        index: true,
                        element: <FacilityInventoryTable />,
                      },
                      {
                        path: ":facilityId",
                        element: <FacilityInventoryDetail />,
                        children: [
                          {
                            index: true,
                            element: <InventoryDefaultNavigation />,
                          },
                          {
                            path: "chemicals/:facilityChemicalId?",
                            children: [
                              {
                                index: true,
                                element: (
                                  <HasFeature
                                    not
                                    products
                                    fallback="../products"
                                  >
                                    <FacilityChemical />
                                  </HasFeature>
                                ),
                              },
                              {
                                path: "measurements/:chemicalId",
                                element: <FacilityChemicalMeasurementDetails />,
                              },
                            ],
                          },
                          {
                            path: "products",
                            element: (
                              <HasFeature products fallback="../chemicals">
                                <FacilityProduct />
                              </HasFeature>
                            ),
                          },
                          {
                            path: "products/:productId",
                            element: <FacilityProductMeasurements />,
                          },
                          {
                            path: "other-chemicals/:facilityChemicalId?",
                            element: (
                              <HasFeature products fallback="../chemicals">
                                <FacilityChemical />
                              </HasFeature>
                            ),
                          },
                          {
                            path: "other-chemicals/measurements/:chemicalId",
                            element: (
                              <HasFeature products fallback="../chemicals">
                                <FacilityChemicalMeasurementDetails />
                              </HasFeature>
                            ),
                          },
                          {
                            path: "chemical-summary",
                            element: <ChemicalSummary />,
                          },
                        ],
                      },
                    ],
                  },
                  {
                    path: "contacts",
                    children: [
                      {
                        index: true,
                        element: (
                          <HasPermission
                            permissions={[Permission.RouteContacts]}
                          >
                            <ContactsFacilitiesTable />
                          </HasPermission>
                        ),
                      },
                      {
                        path: ":facilityId",
                        element: (
                          <HasPermission
                            permissions={[Permission.RouteContacts]}
                          >
                            <FacilityContacts />
                          </HasPermission>
                        ),
                      },
                    ],
                  },
                  {
                    path: "documents",
                    children: [
                      {
                        index: true,
                        element: (
                          <HasPermission
                            permissions={[Permission.RouteDocuments]}
                          >
                            <DocumentFacilitiesTable />
                          </HasPermission>
                        ),
                      },
                      {
                        path: ":facilityId",
                        element: (
                          <HasPermission
                            permissions={[Permission.RouteDocuments]}
                          >
                            <FacilityDocuments />
                          </HasPermission>
                        ),
                      },
                    ],
                  },
                  {
                    path: "products/:productId?",
                    children: [
                      {
                        index: true,
                        element: (
                          <HasPermission
                            permissions={[Permission.RouteEpcraProducts]}
                          >
                            <HasFeature
                              products
                              fallback="../catalog/chemicals"
                            >
                              <TenantProductsTable />
                            </HasFeature>
                          </HasPermission>
                        ),
                      },
                    ],
                  },
                ],
              },
              {
                path: "waste",
                element: <WasteIndex />,
                children: [
                  {
                    path: "facilities",
                    element: <WasteFacilities />,
                  },
                  {
                    path: "facilities/:facilityId",
                    element: <WasteFacility />,
                  },
                  {
                    path: "manifests",
                    element: <Manifests />,
                  },
                ],
              },
              {
                path: "contacts",
                element: (
                  <HasPermission permissions={[Permission.RouteContacts]}>
                    <TenantContacts />
                  </HasPermission>
                ),
              },
              {
                path: "documents/:documentId?",
                element: (
                  <HasPermission permissions={[Permission.RouteDocuments]}>
                    <TenantDocuments />
                  </HasPermission>
                ),
              },
              {
                path: "credentials/:credentialId?",
                element: (
                  <HasPermission
                    permissions={[Permission.RouteEpcraCredentials]}
                  >
                    <TenantCredentials />
                  </HasPermission>
                ),
              },
              {
                path: "tasks/:virtualEventId?",
                element: <Tasks />,
              },
              {
                path: "account/users",
                element: (
                  <HasPermission permissions={[Permission.WriteAllUserTenant]}>
                    <TenantUsers />
                  </HasPermission>
                ),
              },
            ],
          },
          {
            path: "home", // Legacy path commonly used as a bookmark
            loader: async () => redirect("/"),
          },
          {
            path: "*",
            element: <NotFound />,
          },
        ],
      },
    ],
  },
]);

const cognitoError = new URLSearchParams(location.search).get(
  "error_description"
);
if (cognitoError) {
  localStorage.setItem("error_description", cognitoError);
}

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <Authenticator.Provider>
      <AlertProvider>
        <TokenProviderEnabler>
          <ApolloProvider>
            <AuthProvider>
              <CurrentUserProvider>
                <ThemeModeProvider>
                  <ScreenWidthProvider>
                    <LocalizationProviderWrapper>
                      <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.16.105/build/pdf.worker.min.js">
                        <RouterProvider router={router} />
                      </Worker>
                    </LocalizationProviderWrapper>
                  </ScreenWidthProvider>
                </ThemeModeProvider>
              </CurrentUserProvider>
            </AuthProvider>
          </ApolloProvider>
        </TokenProviderEnabler>
      </AlertProvider>
    </Authenticator.Provider>
  </React.StrictMode>
);
