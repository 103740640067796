import { useMutation } from "@apollo/client";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from "@mui/material";
import { Dialog } from "components/Dialog";
import { gql } from "generated-graphql/gql";
import { uniqBy } from "lodash";
import pluralize from "pluralize";
import { REPORTS_QUERY, ReportRow } from ".";

const MARK_AS_REPORTING_MUTATION = gql(`
  mutation MarkAsReporting($facilityIds: [ID!]!) {
    markAsReporting(facilityIds: $facilityIds) {
      tierIIReports {
        id
        encampStatus
        organizationStatus
      }
    }
  }
`);

export const MarkReportingModal = ({
  onClose,
  reports,
}: {
  onClose: () => void;
  reports: ReportRow[];
}) => {
  const [mutate, { loading }] = useMutation(MARK_AS_REPORTING_MUTATION, {
    variables: { facilityIds: reports.map((r) => r.facilityId) },
    refetchQueries: [REPORTS_QUERY],
    onCompleted() {
      onClose();
    },
    onError(err) {
      console.error("Could not mark facilities as reporting", err);
    },
  });

  const wordsToPluralize = ["this", "location", "facility"] as const;
  type PluralizeKey = (typeof wordsToPluralize)[number];
  const pluralized = Object.fromEntries(
    new Map<PluralizeKey, string>(
      wordsToPluralize.map((word) => [
        word,
        pluralize(word, uniqBy(reports, (r) => r.facilityId).length),
      ])
    )
  ) as {
    [key in PluralizeKey]: string;
  };

  return (
    <Dialog open={true}>
      <DialogTitle>{`Set ${pluralized.facility.replace(/^\w/, (w) =>
        w.toUpperCase()
      )} as Reporting`}</DialogTitle>
      <DialogContent>
        <Stack spacing={3}>
          <Typography>
            {`By marking ${pluralized.this} as ${
              pluralized.this === "this" ? "a" : ""
            } reporting ${pluralized.facility},
             we'll file an Annual Tier II report for ${pluralized.this} ${
              pluralized.location
            }.`}
          </Typography>
          <Typography>
            {`Non-reporting facilities may start reporting for various reasons, like an 
            increase in previously under-threshold chemical amounts or because an inactive 
            facility is reopened. Note that for co-located facilities, only one Tier II report 
            is required for all facilities at the same physical address.`}
          </Typography>
          <Typography>
            {`Are you sure you want to mark ${pluralized.this} ${pluralized.facility}
             as Reporting?`}
          </Typography>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <LoadingButton
          variant="contained"
          onClick={() => mutate()}
          loading={loading}
        >
          Save
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
