import invariant from "tiny-invariant";
import federalEHSListRaw from "../constants/ehs.json";
import njEHSListRaw from "../constants/nj_ehs_list.json";
import { hasValue } from "./hasValue";
import {
  Chemical,
  ChemicalComponent,
  ChemicalStateField,
  PureOrMixture,
  StateOfMatter,
} from "@prisma/client";
import { DeepPartial } from "./types";
import { robustBool } from "./robustBool";

type EHSData = {
  cas: number;
  name: string;
  rq: number;
  tpq_low: number;
  tpq_high: number;
};

type NJEHSData = {
  cas: number;
  name: string;
  threshold: number;
};

// These represent cas numbers that are sometimes EHS, but only under certain conditions.
export const SOMETIMES_EHS_NAMES_AND_CAS_NUMBERS = {
  // 7647-01-0 Hydrogen chloride/Hydrochloric acid, only an EHS when it's a gas
  HydrogenChloride: 7647010,

  // 7722-84-1 Hydrogen peroxide (Conc.> 52%), / only an EHS when the concentration is greater than or equal to 52%
  HydrogenPeroxide: 7722841,

  // Nicotine, Strychnine, Dinitrocresol, and Warfarin are EHS when they don't have "and salt" in the name
  // slack lore: https://encamp.slack.com/archives/C02MJFRHG4W/p1667249646941499?thread_ts=1667248989.704739&cid=C02MJFRHG4W
  // list of lists: https://www.epa.gov/epcra/what-reactive-and-non-reactive-solid-ehs
  Nicotine: 54115, //54-11-5 Nicotine/Nicotine and Salts
  Strychnine: 57249, //57-24-9 Strychnine/Strychnine and Salts
  Dinitrocresol: 534521, // 534-52-1 Dinitrocresol/Dinitrocresol and Salts
  Warfarin: 129066, // 129-06-6 Warfarin/Warfarin and Salts
};

export const SOMETIMES_EHS_LIST = Object.values(
  SOMETIMES_EHS_NAMES_AND_CAS_NUMBERS
);

export function normalizeCas(
  cas: string | number | null | undefined
): number | undefined {
  if (!cas) return;
  if (typeof cas === "number") return cas;
  const deHyphenated = cas.replace(/-|\u2013|\u2014|\u2212/g, "");
  if (!/^\d+$/.test(deHyphenated)) return;
  const result = parseInt(deHyphenated);
  if (result) return result;
}

export const EHS_BY_CAS: Map<number, EHSData> = new Map(
  federalEHSListRaw.map((ehs) => [ehs.cas, ehs])
);

export const NJ_EHS_BY_CAS: Map<number, NJEHSData> = new Map(
  njEHSListRaw.map((ehs) => {
    const casNo = normalizeCas(ehs.casNo);
    invariant(casNo);
    return [
      casNo,
      { cas: casNo, name: ehs.chemicalName, threshold: ehs.threshold },
    ];
  })
);

export function getEhsInfo(cas: number | undefined): EHSData | undefined {
  return cas ? EHS_BY_CAS.get(cas) : undefined;
}

export function isEhs(cas: number | undefined): boolean {
  return !!getEhsInfo(cas);
}

export function getNjEhsInfo(cas: number | undefined): NJEHSData | undefined {
  return cas ? NJ_EHS_BY_CAS.get(cas) : undefined;
}

export function isNjEhs(cas: number | undefined): boolean {
  return !!getNjEhsInfo(cas);
}

// For more info on reactive vs non-reactive solids see
// https://www.epa.gov/epcra/what-reactive-and-non-reactive-solid-ehs
export function isNonReactiveSolid(cas: number | undefined): boolean {
  const ehsDatum = getEhsInfo(cas);
  if (!ehsDatum) return false;
  return ehsDatum.tpq_low !== ehsDatum.tpq_high;
}

export function isValidCas(cas: string | number | null | undefined): boolean {
  // format derived from:
  //   https://www.cas.org/support/documentation/chemical-substances/checkdig
  if (!cas) return false;
  const casString =
    typeof cas === "number"
      ? cas.toString()
      : cas.replace(/-|\u2013|\u2014|\u2212/g, "");
  if (!/^\d{5,10}$/g.test(casString)) {
    // check for length constraints and presence of unknown characters in one fell swoop
    return false;
  }
  const digits = Array.from(casString) // Array.from() passed a string returns an array of that string's characters
    .reverse() // .reverse() mutates in place but also returns the array
    .map((digit) => parseInt(digit)); // cannot just do `.map(parseInt)` - https://medium.com/dailyjs/parseint-mystery-7c4368ef7b21
  const checksum = digits.reduce(
    (sum, currentDigit, currentIndex) => currentDigit * currentIndex + sum,
    0
  );
  return checksum % 10 === digits[0];
}

export function normalizeCasStringHyphenated(
  cas: string | number | null | undefined
): string | undefined {
  if (!cas) return;
  const deHyphenated = String(cas).replace(/-|\u2013|\u2014|\u2212/g, "");
  if (!/\d{5,10}/.test(deHyphenated)) {
    // this is not a CAS number if it's not between 5 and 10 digits with no other junk in it (besides the hyphens we've already stripped)
    return;
  }
  let reHyphenated: string | undefined;
  try {
    const [, first, second, third] =
      deHyphenated.match(/^(\d+)(\d{2})(\d{1})$/) ?? [];
    if (first && second && third) {
      reHyphenated = `${first}-${second}-${third}`;
    }
  } catch {
    // no-op - we will return undefined
  }
  return reHyphenated;
}

/**
 *
 * @param product - a reference to an object whose EHS properties we want to correct based on CAS numbers
 * @returns whether the newly corrected product is an EHS
 * @deprecated use isChemicalEhs / isComponentEhs instead
 */
export function doEhsCorrectionsInPlace(product: {
  casNumber?: string | null | undefined;
  ehs?: boolean | null | undefined;
  components?:
    | Array<{
        casNumber?: string | null | undefined;
        ehs?: boolean | null | undefined;
      } | null>
    | null
    | undefined;
}): boolean {
  const topLevelCas = normalizeCas(product.casNumber);
  if (!SOMETIMES_EHS_LIST.includes(topLevelCas!)) {
    product.ehs = isEhs(topLevelCas);
  }

  product.components?.filter(hasValue).forEach((mixComp) => {
    const cas = normalizeCas(mixComp.casNumber);
    if (!SOMETIMES_EHS_LIST.includes(cas!)) {
      mixComp.ehs = isEhs(cas);
    }
  });

  return isProductEhs(product);
}

/**
 * WARNING: this function will trust the incoming values of the `ehs` attributes - it will NOT validate the CAS numbers.
 * @param product - a reference to a product we want to check the EHS booleans of
 * @returns whether the `product` param thinks it is an EHS
 * @deprecated use `isChemicalEhs` instead
 */
export function isProductEhs(product: {
  ehs?: boolean | null | undefined;
  components?:
    | Array<{
        ehs?: boolean | null | undefined;
      } | null>
    | null
    | undefined;
}): boolean {
  return (
    product.ehs || product.components?.some((mixComp) => mixComp?.ehs) || false
  );
}

export function isUnavailableCasValue(casNumber: string | null | undefined) {
  const normalizedCas = normalizeCas(casNumber)?.toString();
  // Some values are used when CAS numbers are unavailable (there may be more that are unknown at this time):
  // * "N/A" is used by a few states
  // * "1" is used by LA
  // * "000000-00-0" is used by WA
  // When the allow-blank-cas-number LD flag goes away, we will no longer need
  // to check for these in the CAS number field - tyler 2022-03-30
  return (
    !casNumber ||
    ["n/a", "1", "none"].includes(casNumber?.toLowerCase()) ||
    // If CAS number is all zeros e.g. 000000-00-0, normalizedCas might be falsy while casNumber is not
    !normalizedCas ||
    // This checks if all characters in the CAS number are the same e.g. 1111-11-1
    normalizedCas.split("").every((char) => char === normalizedCas[0])
  );
}

export function getExtractionCasNumberValues(
  extractedCasNumber: string | null | undefined
) {
  return extractedCasNumber?.trim() || "N/A";
}

/**
 * Returns true if the chemical has an EHS CAS number and should be marked as isEhs = true
 */
export const isChemicalEhs = (
  chemical: DeepPartial<
    Pick<Chemical, "pureOrMixture" | "casNumber" | "name" | "stateOfMatter">
  > & {
    components?: DeepPartial<ChemicalComponent>[];
    stateFields?: DeepPartial<ChemicalStateField>[];
  }
): isEhsReturnType => {
  const pureOrMixture = isPureOrMixture(chemical);

  const isPure =
    pureOrMixture === PureOrMixture.PURE ||
    (chemical.components?.length ?? 0) === 0;

  if (isPure) {
    return isTopLevelChemicalEhs(chemical);
  }
  return {
    isEhs:
      chemical?.components?.some((c) => isComponentEhs(chemical, c!).isEhs) ||
      false,
  };
};

/**
 * This function determines if the chemical is a pure or mixture.
 * It adds some additional logic to handle the case where the chemical is a waste.
 */
export function isPureOrMixture(
  chemical: DeepPartial<Pick<Chemical, "pureOrMixture">> & {
    components?: DeepPartial<ChemicalComponent>[];
    stateFields?: DeepPartial<ChemicalStateField>[];
  }
): PureOrMixture | undefined | null {
  const isWaste = robustBool(
    chemical.stateFields?.find((stateField) => stateField.key === "isWaste")
      ?.value as string
  );

  // If this is set, use it
  if (chemical.pureOrMixture) return chemical.pureOrMixture;

  // If not, we have additional logic to handle the case where the chemical is a waste
  if (isWaste) {
    if (isPureWaste(chemical.components)) return PureOrMixture.PURE;
    return PureOrMixture.MIXTURE;
  }

  return chemical.pureOrMixture;
}

function isPureWaste(
  components: DeepPartial<ChemicalComponent>[] | undefined
): boolean {
  // Has no components or has one component that has a component percentage of 100%
  return (
    (components?.length ?? 0) === 0 ||
    ((components?.length ?? 0) === 1 &&
      components?.[0]?.componentPercentage === 100)
  );
}

type isEhsReturnType = { isEhs: boolean; conditionalEhsReasonMessage?: string };

/**
 * This function determines if the chemical itself is an EHS, does not look at components.
 * @param chemical Chemical we're checking
 * @returns true if the chemical is an EHS, false otherwise
 */
export const isTopLevelChemicalEhs = (
  chemical: DeepPartial<
    Pick<Chemical, "casNumber" | "name" | "stateOfMatter" | "pureOrMixture">
  >
): isEhsReturnType => {
  return isChemicalOrComponentEhs({
    casNumber: chemical.casNumber,
    name: chemical.name,
    stateOfMatter: chemical.stateOfMatter,
    pureOrMixture: isPureOrMixture(chemical),
  });
};

/**
 * This function determines if the component is an EHS.
 * @param chemical Chemical we're checking
 * @param component Component we're checking
 * @returns true if the component is an EHS, false otherwise
 */
export const isComponentEhs = (
  chemical: DeepPartial<Pick<Chemical, "stateOfMatter">>,
  component: DeepPartial<
    Pick<ChemicalComponent, "componentPercentage" | "name" | "casNumber">
  >
): isEhsReturnType => {
  return isChemicalOrComponentEhs({
    casNumber: component.casNumber,
    name: component.name,
    stateOfMatter: chemical.stateOfMatter,
    componentPercentage: component.componentPercentage,
    pureOrMixture: PureOrMixture.MIXTURE, // always mixture with components
  });
};

/**
 * This function determines if the chemical or component is an EHS. There are a few edge cases
 * where a given casNumber is sometimes an EHS. These are handled in the switch statement, specifically:
 * - Hydrogen peroxide (cas number 7722-84-1) is an EHS if the concentration is greater than or equal to 52%.
 * - Hydrogen chloride (cas number 7647-01-0) is an EHS if it's a gas.
 * - Nicotine, strychnine, dinitrocresol, and Warfarin are EHS when they don't have "and salt" in the name.
 * @param chemical Chemical we're checking
 * @param component Component we're checking
 * @returns true if the chemical or component is an EHS, false otherwise
 */
const isChemicalOrComponentEhs = ({
  casNumber,
  name,
  stateOfMatter,
  componentPercentage,
  pureOrMixture,
}: {
  casNumber: string | null | undefined;
  name: string | null | undefined;
  stateOfMatter?: (StateOfMatter | undefined)[] | undefined;
  componentPercentage?: number | null | undefined;
  pureOrMixture?: PureOrMixture | null | undefined;
}): isEhsReturnType => {
  // If we have a mixture chemical but it has no components, then basically treat it as a pure chemical
  // Update with new lore: https://encamp.slack.com/archives/C02MJFRHG4W/p1732546943198869
  // old lore, sorta outdated but good to keep: https://encamp.slack.com/archives/C01HAPX44MT/p1706814648653039?thread_ts=1706736857.657029&cid=C01HAPX44MT

  const pureOrNoComponents =
    pureOrMixture === PureOrMixture.PURE ||
    (pureOrMixture === PureOrMixture.MIXTURE && !componentPercentage);

  const normalizedCas = normalizeCas(casNumber);

  switch (normalizedCas) {
    // hydrogen chloride is only an EHS when it's a gas
    case SOMETIMES_EHS_NAMES_AND_CAS_NUMBERS.HydrogenChloride:
      const isEhs = !!stateOfMatter?.includes(StateOfMatter.GAS);
      return {
        isEhs,
        conditionalEhsReasonMessage:
          "Hydrogen chloride is only an EHS when it's in gas form.",
      };

    // hydrogen peroxide is only an EHS when the concentration is greater than or equal to 52%
    case SOMETIMES_EHS_NAMES_AND_CAS_NUMBERS.HydrogenPeroxide:
      return {
        isEhs:
          pureOrNoComponents ||
          !!(
            pureOrMixture === PureOrMixture.MIXTURE &&
            componentPercentage &&
            componentPercentage >= 52
          ),
        conditionalEhsReasonMessage:
          "Hydrogen peroxide is only an EHS when the concentration is greater than or equal to 52%.",
      };

    // nicotine, strychnine, dinitrocresol, and Warfarin are EHS when they don't have "and salt" in the name
    // slack lore: https://encamp.slack.com/archives/C02MJFRHG4W/p1667249646941499?thread_ts=1667248989.704739&cid=C02MJFRHG4W
    // list of lists: https://www.epa.gov/system/files/documents/2024-05/epcra-cercla-caa-112r-consolidated-list-of-lists-updated-may-2024_0.pdf
    case SOMETIMES_EHS_NAMES_AND_CAS_NUMBERS.Nicotine:
    case SOMETIMES_EHS_NAMES_AND_CAS_NUMBERS.Strychnine:
    case SOMETIMES_EHS_NAMES_AND_CAS_NUMBERS.Dinitrocresol:
    case SOMETIMES_EHS_NAMES_AND_CAS_NUMBERS.Warfarin:
      return {
        isEhs: !!(name && name.toLowerCase().indexOf("and salt") === -1),
        conditionalEhsReasonMessage: `${casNumber} is only an EHS when it is not a salt.`,
      };
  }

  return { isEhs: isEhs(normalizedCas) };
};
