import { useApolloClient, useQuery } from "@apollo/client";
import OneSchemaImporter from "@oneschema/react";
import { ImportButton } from "components/ImportButton";
import { OneSchemaTemplateType } from "encamp-shared/src/oneSchema/types";
import { gql } from "generated-graphql";
import { startCase } from "lodash";
import { useToken } from "providers/token";
import { useEffect, useRef, useState } from "react";
import { useAlerts } from "../Alerts/AlertProvider";
import { useParams } from "react-router-dom";

export interface ImporterProps {
  templateType: OneSchemaTemplateType;
  refetchQueries?: string[];
  label?: string;
  style?: React.CSSProperties;
  getImportConfigFunction?: () => Promise<ImportConfig>;
  postProcessingFunction?: (
    handleSuccessfulImport: (message: string) => Promise<void>,
    importConfig: ImportConfig | null
  ) => Promise<void>;
}

interface WebhookImportConfig {
  type: "webhook";
  key: string;
}
interface LocalImportConfig {
  type: "local";
  metadataOnly?: boolean;
}
export interface FileUploadImportConfig {
  type: "file-upload";
  url: string;
  format?: "json" | "csv";
  headers?: {
    [headerName: string]: string;
  };
}

export type ImportConfig =
  | WebhookImportConfig
  | LocalImportConfig
  | FileUploadImportConfig;

const GET_ONESCHEMA_JWT = gql(`
  query GetOneSchemaJwt($tenantId: String) {
    getOneSchemaJwt(tenantId: $tenantId)
  }
`);

export function Importer({
  templateType,
  refetchQueries = [],
  label,
  style,
  postProcessingFunction,
  getImportConfigFunction,
}: ImporterProps) {
  const { tenantId } = useParams();
  const initialized = useRef(false);
  const alerts = useAlerts();
  const apolloClient = useApolloClient();

  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [templateName, setTemplateName] = useState("");
  const { getIdTokenRefreshIfExpired } = useToken();
  const [isSuccess, setIsSuccess] = useState(false);
  const [importConfig, setImportConfig] = useState<ImportConfig | null>(null);

  const { data: oneschemaJwtQueryData } = useQuery(GET_ONESCHEMA_JWT, {
    variables: { tenantId },
  });

  const getImportConfig = async (): Promise<ImportConfig> => {
    if (getImportConfigFunction) {
      return await getImportConfigFunction();
    }

    return {
      type: "webhook",
      key: `${import.meta.env.VITE_ENV}-import`,
    };
  };

  useEffect(() => {
    async function initializeImporter() {
      if (!initialized.current) {
        initialized.current = true;
        setIsLoading(true);

        const idToken = await getIdTokenRefreshIfExpired();
        try {
          const response = await fetch(
            import.meta.env.MODE === "development"
              ? `http://localhost:4000/oneschema/templates/${templateType}`
              : `${
                  import.meta.env.VITE_API_URL
                }/oneschema/templates/${templateType}`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                authorization: `Bearer ${idToken}`,
              },
            }
          );
          const template = (await response.json()) as { template_key: string };
          setTemplateName(template.template_key);
        } catch (error) {
          console.error(error);
          setIsError(true);
        } finally {
          setIsLoading(false);
        }
      }
    }

    initializeImporter();
  }, [templateType, getIdTokenRefreshIfExpired]);

  const handleOpenImporter = async () => {
    setIsLoading(true);
    try {
      const config = await getImportConfig();
      setImportConfig(config);
      setIsOpen(true);
    } catch (error) {
      console.error("Error opening importer:", error);
      alerts.error("Failed to open importer. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const onSuccess = async () => {
    const handleSuccessfulImport = async (message: any) => {
      alerts.success(message);
      if (refetchQueries.length) {
        await apolloClient.refetchQueries({
          include: refetchQueries,
        });
      }
      setIsSuccess(true);
      setIsOpen(false);
    };

    if (postProcessingFunction) {
      await postProcessingFunction(handleSuccessfulImport, importConfig);
    } else {
      await handleSuccessfulImport("Successfully imported");
    }
  };

  const onError = (message: any) => {
    console.error(message);
    alerts.error("An error occurred during the import");
  };

  const importer = templateName &&
    oneschemaJwtQueryData?.getOneSchemaJwt &&
    importConfig && (
      <OneSchemaImporter
        isOpen={isOpen}
        onRequestClose={() => setIsOpen(false)}
        clientId={import.meta.env.VITE_ONE_SCHEMA_CLIENT_ID}
        userJwt={oneschemaJwtQueryData?.getOneSchemaJwt}
        templateKey={templateName}
        importConfig={importConfig}
        devMode={import.meta.env.MODE === "development"}
        className="oneschema-importer"
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100vw",
          height: "100vh",
          zIndex: 1300,
        }}
        customizationOverrides={{
          hideLogo: true,
          uploadPaneHeaderText: `Import ${startCase(templateType)}`,
          includeExcelTemplate: true,
          importExperience: "blockIfErrors",
          mappingStrategy: ["exact", "historical"],
          skipMapping: ["exact", "historical"],
          autofixAfterMapping: true,
        }}
        onSuccess={onSuccess}
        onError={onError}
      />
    );

  return (
    <>
      {importer}
      <ImportButton
        loading={isLoading}
        disabled={isOpen || isLoading || isError || !templateName}
        success={isSuccess}
        onClick={handleOpenImporter}
        label={label}
        style={{ ...style }}
      />
    </>
  );
}
