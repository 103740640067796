import { gql } from "generated-graphql";

export const NOTIFICATION_ROW_FRAGMENT = gql(`
  fragment NotificationRow on Notification {
    id
    message
  }
`);

export const GET_NOTIFICATIONS = gql(`
  query Notifications($search: String, $page: Int, $pageSize: Int, $sort: [SortModel!]) {
    notifications(search: $search, page: $page, pageSize: $pageSize, sort: $sort) {
      items {
        id
        message
        tenant {
          name
        }
        facility {
          name
        }
        tierIIReport {
          reportKind
          reportingYear
        }
      }
      count
    }
  }
`);
