import { useMutation, useQuery } from "@apollo/client";
import {
  PropsWithChildren,
  createContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { configureSentryUser } from "sentry";
import { gql } from "../generated-graphql";
import {
  GetUserQuery,
  GetUserQueryVariables,
} from "../generated-graphql/graphql";

export type CurrentUser = GetUserQuery["getCurrentUser"];

type CurrentUserProviderContext = {
  user: CurrentUser | undefined;
  loading: boolean;
  refetchUser: () => void;
};

export const currentUserContext = createContext<CurrentUserProviderContext>({
  user: undefined,
  loading: true,
  refetchUser: () => {},
});

const GET_USER_QUERY = gql(`
  query GetUser {
    getCurrentUser {
      id
      email
      type
      isPartner
      person {
        id
        first
        last
      }
      UserTenant {
        tenantId
        role {
          permissions
        }
        tenant {
          id
          partnersTenants {
            id
          }
        }
      }
      facilities {
        id
      }
      isEmulating
      originalUserType
    }
  }
`);

const CREATE_USER_LOGIN = gql(`
  mutation CreateUserLogin($userId: ID!) {
    createUserLogin(userId: $userId) {
      id
      loginAt
      userId
    }
  }
`);

export function CurrentUserProvider(props: PropsWithChildren<unknown>) {
  const { data, loading, error, refetch } = useQuery<
    GetUserQuery,
    GetUserQueryVariables
  >(GET_USER_QUERY);
  const [hasInitialLogin, setHasInitialLogin] = useState(false);

  const [createUserLogin] = useMutation(CREATE_USER_LOGIN);

  const contextValue = useMemo(
    () => ({
      user: data?.getCurrentUser,
      loading,
      refetchUser: refetch,
      originalUserType: data?.getCurrentUser?.originalUserType,
    }),
    [data?.getCurrentUser, loading, refetch]
  );

  useEffect(() => {
    const handlePostAuthLogin = async (id: string) => {
      try {
        await createUserLogin({
          variables: {
            userId: id,
          },
        });
        setHasInitialLogin(true);
      } catch (err) {
        console.error("Failed to create post-auth userLogin record:", err);
      }
    };

    if (
      loading === false &&
      hasInitialLogin === false &&
      contextValue?.user?.id
    ) {
      handlePostAuthLogin(contextValue.user.id);
    }
  }, [loading, hasInitialLogin, createUserLogin, contextValue?.user?.id]);

  if (error) {
    console.error(error);
    throw error;
  }

  configureSentryUser(data?.getCurrentUser ? { ...data.getCurrentUser } : null);

  return (
    <currentUserContext.Provider value={contextValue}>
      {props.children}
    </currentUserContext.Provider>
  );
}
