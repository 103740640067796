import { gql } from "generated-graphql";
import {
  LeadAgencyPickerFragment,
  LeadAgenciesPickerQuery,
} from "generated-graphql/graphql";
import { forwardRef } from "react";
import { ControllerFieldState } from "react-hook-form";
import EncampAutocomplete from "./Forms/EncampAutocomplete";

// Base props for the picker
type LeadAgencyPickerProps = {
  defaultSearch?: string;
  onChange?: (value: LeadAgencyPickerFragment | null) => void;
  value?: LeadAgencyPickerFragment | null;
  disabled?: boolean;
} & ControllerFieldState;

// GraphQL fragments and queries
const LEAD_AGENCY_PICKER_FRAGMENT = gql(`
  fragment LeadAgencyPicker on LeadAgency {
    id
    name
    address
    city
    state
    zip
    email
    attn
    consolidated
    phone
  }
`);

const LEAD_AGENCIES_SEARCH_QUERY = gql(`
  query LeadAgenciesPicker($search: String, $page: Int, $pageSize: Int, $sort: [SortModel!], $tenantId: String) {
    leadAgencies(search: $search, page: $page, pageSize: $pageSize, sort: $sort, tenantId: $tenantId) {
      items {
        ...LeadAgencyPicker
      }
      count
    }
  }
`);

// Function to format Lead Agency option labels
const getLeadAgencyOptionLabel = (leadAgency: LeadAgencyPickerFragment) => {
  return `${leadAgency.name} ${leadAgency.city}, ${leadAgency.state}`;
};

// Function to get items from query response
const getLeadAgencyItems = (data: LeadAgenciesPickerQuery) =>
  data.leadAgencies?.items ?? [];

// Main picker component
export const LeadAgencyPicker = forwardRef<
  HTMLDivElement,
  LeadAgencyPickerProps
>(function LeadAgencyPicker(
  { onChange, value, error, disabled, defaultSearch },
  ref
) {
  return (
    <EncampAutocomplete
      query={LEAD_AGENCIES_SEARCH_QUERY}
      variables={{
        search: defaultSearch,
      }}
      getItems={getLeadAgencyItems}
      getOptionLabel={getLeadAgencyOptionLabel}
      onChange={onChange}
      value={value}
      error={error}
      disabled={disabled}
    />
  );
});
