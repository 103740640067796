import {
  CircularProgress,
  Grid,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { Comments } from "components/Comments";
import { TabLinks } from "components/TabLinks";
import { useTenant } from "hooks/useTenant";
import { useEffect, useMemo } from "react";
import {
  Outlet,
  useHref,
  useMatch,
  useNavigate,
  useParams,
} from "react-router-dom";
import { WaitForAllActivityTypes, getActivityTitle } from "util/activity";
import { useBreadcrumb } from "../../../../hooks/useBreadcrumbs";
import { kebabToTitleCase } from "../../../../util";
import { GeppettoCommand } from "./Activities/GeppettoCommand";
import { WaitForAllCohortTable } from "./Activities/WaitForAllCohortTable";
import { Agencies } from "./Agencies";
import { ContactDetails } from "./ContactDetails";
import { Extensions } from "./Extensions";
import { Facility } from "./Facility";
import { ImportantIdentifiers } from "./ImportantIdentifiers";
import { StatePortal } from "./StatePortal";
import { Tags } from "./Tags";
import { useReportDetails } from "./useReportDetails";

export function ReportDetails() {
  const navigate = useNavigate();
  const theme = useTheme();
  const { reportId } = useParams<{ reportId: string }>();
  const { data, loading } = useReportDetails();
  const { tenantId, setCurrentTenantId } = useTenant();

  useEffect(() => {
    if (
      !loading &&
      data?.tierIIReport.tenantId &&
      data.tierIIReport.tenantId !== tenantId
    ) {
      setCurrentTenantId(data.tierIIReport.tenantId);
    }
  }, [data?.tierIIReport.tenantId, loading, setCurrentTenantId, tenantId]);

  const cohorts: { waitForAllType: string; cohortFacilityIds: string[] }[] =
    useMemo(() => {
      const waitForAllActivities =
        data?.tierIIReport.currentWorkflow?.activities.filter((a) =>
          WaitForAllActivityTypes.includes(a.type)
        ) ?? [];

      return waitForAllActivities
        .map((activity) => {
          return {
            waitForAllType: getActivityTitle(activity),
            cohortFacilityIds: activity.cohortFacilityIds ?? [],
          };
        })
        .filter(({ cohortFacilityIds }) => cohortFacilityIds.length);
    }, [data?.tierIIReport.currentWorkflow?.activities]);

  const reportUrl = useHref(reportId ?? "");

  const reportKindString = kebabToTitleCase(
    data?.tierIIReport?.reportKind ?? ""
  );

  const facility = data?.tierIIReport.facility;
  const label = facility?.customerFacilityId
    ? `${facility?.name} - ${facility?.customerFacilityId}`
    : facility?.name;

  useBreadcrumb({
    label: label ?? "",
    to: reportUrl,
    addendum: reportKindString,
  });

  const shouldDefaultToActivities = useMatch("/staff/fulfillment/:reportId");

  useEffect(() => {
    if (shouldDefaultToActivities) {
      navigate("activities", { replace: true });
    }
  }, [navigate, shouldDefaultToActivities]);

  const activityTabs = [
    {
      title: "Activities",
      to: "activities",
    },
    {
      title: "Documents",
      to: "documents",
    },
    {
      title: "Payments",
      to: "payments",
    },
    {
      title: "Workflow Logs",
      to: "workflow-logs",
    },
    {
      title: "Jobs",
      to: "job",
    },
  ];

  const match = useMatch({
    path: "/staff/fulfillment/:reportId/:tab",
    end: false,
  });

  const selectedActivityTab =
    (match && activityTabs.find((t) => t.to === match.params.tab)?.to) ??
    activityTabs[0].to;

  return (
    <>
      <TabLinks value={selectedActivityTab} tabs={activityTabs} />
      <Stack p={3}>
        {!data || loading ? (
          <CircularProgress
            sx={{
              marginLeft: "auto",
              marginRight: "auto",
            }}
          />
        ) : (
          <Grid container spacing={2}>
            <Grid
              item
              container
              rowGap={theme.spacing(2)}
              xl={8}
              lg={12}
              flexDirection="column"
            >
              <Outlet />
              <Comments />
              {cohorts.length > 0 && (
                <Grid container rowGap={2}>
                  <Grid item xs={12}>
                    <Typography variant="h5">Cohorts</Typography>
                  </Grid>
                  {cohorts.map((cohort) => (
                    <Grid item xs={12} key={cohort.waitForAllType}>
                      <WaitForAllCohortTable
                        cohortType={cohort.waitForAllType}
                        cohortFacilityIds={cohort.cohortFacilityIds}
                      />
                    </Grid>
                  ))}
                </Grid>
              )}
            </Grid>
            <Grid item xl={4} lg={12}>
              <Stack spacing={2}>
                <StatePortal />
                <Facility />
                <Agencies />
                <Tags />
                <GeppettoCommand />
                <ImportantIdentifiers />
                <Extensions />
                <ContactDetails />
              </Stack>
            </Grid>
          </Grid>
        )}
      </Stack>
    </>
  );
}
