import {
  Box,
  Card,
  CardContent,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  Typography,
  Button,
} from "@mui/material";
import { FormTextField } from "components/Forms/FormTextField";
import { Controller } from "react-hook-form";
import { CommunicationType, DocumentType } from "generated-graphql/graphql";
import { RecipientType } from "../../utils";
import { DocumentItem } from "./DocumentItem";
import { useRecipientForm } from "../hooks/useRecipientForm";
import { useCommunicationFormContext } from "../hooks/useCommunicationForm";
import { useMemo, useState } from "react";
import { RadioGroupField } from "components/Forms/RadioGroupField";
import {
  AddEditDocumentDialog,
  AddEditDocumentDialogState,
  defaultAddEditDocumentDialogState,
  EditMode,
} from "components/AddEditDocumentDialog";
import { useAlerts } from "components/Alerts/AlertProvider";
import { useLazyQuery } from "@apollo/client";
import GET_DOCUMENT_DOWNLOAD_LINK_QUERY from "queries/getDocumentDownloadLink";
import { RecipientPicker } from "./RecipientPicker";

export const RecipientCard = () => {
  const { watch, mode } = useCommunicationFormContext();
  const disabled = mode === "view";
  const {
    formContext,
    recipientType,
    isEmailAllowed,
    isMailAllowed,
    preferUncertified,
    clearRecipientInfo,
  } = useRecipientForm();
  const { control } = formContext;
  const communicationType = watch("recipient.communicationType");
  const facilityIds = watch("facilityIds");
  const tenant = watch("tenant");
  const tenantId = useMemo(() => tenant?.id, [tenant]);
  const alerts = useAlerts();
  const [addEditDialogState, setAddEditDialogState] =
    useState<AddEditDocumentDialogState>(defaultAddEditDocumentDialogState);

  const [getDocumentDownloadLink] = useLazyQuery(
    GET_DOCUMENT_DOWNLOAD_LINK_QUERY,
    {
      onCompleted: (data) => {
        if (!data?.getDocumentDownloadLink) {
          alerts.error("Error fetching download link");
          return;
        }
        window.open(data.getDocumentDownloadLink, "_blank");
      },
      onError: (e) => {
        alerts.error("Error fetching download link", e);
      },
    }
  );

  return (
    <Card>
      <CardContent>
        <Stack gap={2}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <RadioGroupField
                label="Recipient Type"
                name="recipient.recipientType"
                control={control}
                rules={{ required: "Recipient type is required" }}
                disabled={disabled}
                onChange={(e) => {
                  clearRecipientInfo();
                }}
                radioOptions={[
                  {
                    label: "LEPC",
                    value: RecipientType.LEPC,
                  },
                  {
                    label: "Fire Department",
                    value: RecipientType.FIRE_DEPARTMENT,
                  },
                  {
                    label: "Police Department",
                    value: RecipientType.POLICE_DEPARTMENT,
                  },
                  {
                    label: "Lead Agency",
                    value: RecipientType.LEAD_AGENCY,
                  },
                  {
                    label: "Custom",
                    value: RecipientType.CUSTOM,
                  },
                ]}
              />
            </Grid>

            <Grid item xs={12}>
              <RecipientPicker
                recipientType={recipientType}
                facilityIds={facilityIds}
                control={control}
                disabled={disabled}
              />
            </Grid>

            <Grid item xs={12}>
              <Controller
                name="recipient.communicationType"
                control={control}
                render={({ field }) => {
                  const noContactAllowed = !isEmailAllowed && !isMailAllowed;

                  const enableEmail = isEmailAllowed || noContactAllowed;
                  const enableMail = isMailAllowed || noContactAllowed;
                  // Switch to the first allowed option
                  if (!noContactAllowed) {
                    if (
                      field.value === CommunicationType.Email &&
                      !isEmailAllowed
                    ) {
                      field.onChange(
                        preferUncertified
                          ? CommunicationType.FirstClassMail
                          : CommunicationType.CertifiedMail
                      );
                    } else if (
                      field.value !== CommunicationType.Email &&
                      !isMailAllowed
                    ) {
                      field.onChange(CommunicationType.Email);
                    }
                  }

                  return (
                    <FormControl component="fieldset">
                      <FormLabel>Communication Type</FormLabel>
                      <RadioGroup {...field} row>
                        <FormControlLabel
                          value={CommunicationType.Email}
                          control={
                            <Radio disabled={disabled || !enableEmail} />
                          }
                          label="Email"
                        />
                        <FormControlLabel
                          value={CommunicationType.CertifiedMail}
                          control={
                            <Radio
                              disabled={
                                disabled || !enableMail || preferUncertified
                              }
                            />
                          }
                          label="Certified Mail"
                        />
                        <FormControlLabel
                          value={CommunicationType.FirstClassMail}
                          control={<Radio disabled={disabled || !enableMail} />}
                          label="Uncertified Mail"
                        />
                      </RadioGroup>
                      {!isEmailAllowed && !isMailAllowed && (
                        <Typography color="error" variant="caption">
                          This recipient does not accept any form of
                          communication, pick one at your own discretion.
                        </Typography>
                      )}
                    </FormControl>
                  );
                }}
              />
            </Grid>

            {/* Cover Letter Section */}
            <Grid item xs={12}>
              <Controller
                name="recipient.coverLetterDocument"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Stack spacing={2}>
                    <Box display="flex" justifyContent="flex-start">
                      <Button
                        variant="contained"
                        sx={{
                          marginBottom: 1,
                        }}
                        disabled={disabled || !!value || !tenant}
                        onClick={() =>
                          setAddEditDialogState((state) => ({
                            ...state,
                            open: true,
                            mode: EditMode.ADD,
                            documentType: DocumentType.CoverLetter,
                          }))
                        }
                      >
                        Add Cover Letter
                      </Button>
                    </Box>
                    {value && (
                      <DocumentItem
                        document={value}
                        onDelete={() => {
                          onChange(null);
                        }}
                        onDownload={() => {
                          getDocumentDownloadLink({
                            variables: { id: value.id },
                          });
                        }}
                      />
                    )}
                    {addEditDialogState.open && (
                      <AddEditDocumentDialog
                        dialogState={addEditDialogState}
                        setDialogState={setAddEditDialogState}
                        tenantId={tenantId ?? ""}
                        documentTypeOptions={[DocumentType.CoverLetter]}
                        onSubmit={(document) => {
                          onChange(document);
                          setAddEditDialogState(
                            defaultAddEditDocumentDialogState
                          );
                        }}
                        refetchQueries={[]}
                      />
                    )}
                  </Stack>
                )}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormTextField
                label="Recipient Name"
                name="recipient.recipientName"
                disabled={disabled}
                control={control}
              />
            </Grid>

            {communicationType === CommunicationType.Email ? (
              <>
                <Grid item xs={12} sm={6}>
                  <FormTextField
                    label="Email Addresses"
                    name="recipient.emailRecipientAddresses"
                    disabled={disabled}
                    control={control}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormTextField
                    name="recipient.emailSubject"
                    disabled={disabled}
                    control={control}
                    label="Subject"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormTextField
                    name="recipient.emailReplyTo"
                    disabled={disabled}
                    control={control}
                    label="Reply To Email Address"
                  />
                </Grid>
              </>
            ) : (
              <>
                <Grid item xs={12} sm={6}>
                  <FormTextField
                    name="recipient.mailRecipientAddressLine1"
                    control={control}
                    label="Address Line 1"
                    disabled={disabled}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormTextField
                    name="recipient.mailRecipientAddressLine2"
                    control={control}
                    label="Address Line 2"
                    disabled={disabled}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormTextField
                    name="recipient.mailRecipientCity"
                    control={control}
                    label="City"
                    disabled={disabled}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <FormTextField
                    name="recipient.mailRecipientState"
                    control={control}
                    label="State"
                    disabled={disabled}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <FormTextField
                    name="recipient.mailRecipientZip"
                    control={control}
                    label="ZIP"
                    disabled={disabled}
                  />
                </Grid>
              </>
            )}
          </Grid>
        </Stack>
      </CardContent>
    </Card>
  );
};
