import { cloneElement, useCallback, useEffect } from "react";
import { useLongPollingForJob } from "hooks/useLongPollingForJob";
import { GetJobQuery } from "generated-graphql/graphql";

interface ChildWithOnClickAndLoadingProps extends React.ReactElement {
  props: {
    onClick: (event: React.MouseEvent) => void;
    loading: boolean;
  };
}

export type PollingResult = {
  job: GetJobQuery["job"] | undefined;
  pollingTimedOut: boolean;
};

export interface LongPollingJobWrapperProps {
  jobId: string | undefined;
  children: ChildWithOnClickAndLoadingProps;
  timeoutInSeconds?: number;
  pollingCompleted: (result: PollingResult) => Promise<void>;
}

export function LongPollingJobWrapper({
  jobId,
  children,
  timeoutInSeconds,
  pollingCompleted,
}: LongPollingJobWrapperProps) {
  const { polling, startPolling, result } = useLongPollingForJob({
    timeoutInSeconds,
  });

  const handlePollingComplete = useCallback(async () => {
    if (result.job) {
      await pollingCompleted(result);
    }
  }, [result, pollingCompleted]);

  useEffect(() => {
    if (jobId) {
      startPolling(jobId);
    }
  }, [jobId, startPolling]);

  useEffect(() => {
    if (result && !polling) {
      handlePollingComplete();
    }
  }, [result, polling, handlePollingComplete]);

  const handleClick = (event: React.MouseEvent) => {
    if (children.props.onClick) {
      children.props.onClick(event);
    }
  };

  return cloneElement(children, {
    onClick: handleClick,
    loading: polling,
  });
}
