import { useLazyQuery } from "@apollo/client";
import { gql } from "generated-graphql";
import { GetJobQuery, JobStatus } from "generated-graphql/graphql";
import { useCallback, useEffect, useState, useRef } from "react";

const GET_JOB = gql(`query GetJob($jobId: ID!) {
    job(id: $jobId) {
      status
      task
      output
    }
  }`);

const terminalJobStatuses: JobStatus[] = [
  JobStatus.Cancelled,
  JobStatus.Failed,
  JobStatus.AwaitingManualIntervention,
  JobStatus.FailedMayAutoRetry,
  JobStatus.FailedNeedsHuman,
  JobStatus.FailedProvisioningEcsTask,
  JobStatus.FailedQualityControlCheck,
  JobStatus.Promoted,
  JobStatus.Succeeded,
];

export const useLongPollingForJob = ({
  timeoutInSeconds = 60,
}: {
  timeoutInSeconds?: number;
}) => {
  const [polling, setPolling] = useState(false);
  const [jobId, setJobId] = useState<string | undefined>(undefined);
  const [result, setResult] = useState<{
    job: undefined | GetJobQuery["job"];
    pollingTimedOut: boolean;
  }>({
    job: undefined,
    pollingTimedOut: false,
  });
  const timeoutRef = useRef<NodeJS.Timeout>();

  const startPolling = useCallback((newJobId: string) => {
    setJobId(newJobId);
    setPolling(true);
    setResult({
      job: undefined,
      pollingTimedOut: false,
    });
  }, []);

  const [getJob, { data, startPolling: startPollingQuery, stopPolling }] =
    useLazyQuery(GET_JOB, {
      fetchPolicy: "network-only",
    });

  // Reset polling when jobId changes
  useEffect(() => {
    if (!jobId) {
      return;
    }

    // Clear any existing timeout
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    // Start polling
    getJob({ variables: { jobId } });
    startPollingQuery(1000);

    // Set new timeout
    timeoutRef.current = setTimeout(() => {
      stopPolling();
      setPolling(false);

      setResult({
        job: {
          status: JobStatus.Failed,
          task: null,
          __typename: "Job",
        },
        pollingTimedOut: true,
      });
    }, timeoutInSeconds * 1000);

    // Cleanup function
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      stopPolling();
    };
  }, [jobId, timeoutInSeconds, getJob, startPollingQuery, stopPolling]);

  // Handle job status updates
  useEffect(() => {
    if (data?.job && terminalJobStatuses.includes(data.job.status)) {
      setResult({
        job: data.job,
        pollingTimedOut: false,
      });
      setPolling(false);
      stopPolling();
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    }
  }, [data, stopPolling]);

  return {
    polling,
    result,
    startPolling,
  };
};
