import OpenInNew from "@mui/icons-material/OpenInNew";
import { Link, Stack } from "@mui/material";
import { DataGrid } from "components/DataGrid";
import { useParams } from "react-router-dom";
import { WaitForAllActivityTypes } from "util/activity";
import { useReportDetails } from "../useReportDetails";

export const WaitFor = () => {
  const { activityId } = useParams<{ activityId: string }>();
  const { data: reportData } = useReportDetails();
  const activity = reportData?.tierIIReport.currentWorkflow?.activities.find(
    (a) => a.id === activityId
  );

  if (activity?.type && WaitForAllActivityTypes.includes(activity?.type)) {
    if (reportData?.tierIIReport.id === activity?.championId) {
      return (
        <Stack spacing={1}>
          <DataGrid
            rows={(activity.nonChampionIds ?? []).map((id: string) => ({
              id,
            }))}
            noRowsMessage={`Non-champions won't be determined until this activity is completed. Check the cohort table below to view facilities in the cohort.`}
            rowSelection={false}
            initialState={{ pagination: { paginationModel: { pageSize: 25 } } }}
            pagination
            columns={[
              {
                field: "id",
                headerName: "Non Champion Id",
                flex: 1,
              },
              {
                field: "actions",
                type: "actions",
                getActions(params) {
                  return [
                    <Link
                      key={params.id}
                      href={`${window.location.origin}/staff/fulfillment/${params.id}`}
                      style={{ textDecoration: "none" }}
                      target="_blank"
                    >
                      <OpenInNew style={{ fontSize: "inherit" }} />
                    </Link>,
                  ];
                },
              },
            ]}
            sx={{
              "& .MuiDataGrid-virtualScroller": {
                minHeight: "110px",
              },
            }}
          />
        </Stack>
      );
    } else if (activity?.championId) {
      return (
        <Stack spacing={1}>
          <Link
            href={`${window.location.origin}/staff/fulfillment/${activity?.championId}`}
            style={{ textDecoration: "none" }}
            target="_blank"
          >
            Champion Report
            <OpenInNew style={{ fontSize: "inherit" }} />
          </Link>
        </Stack>
      );
    }
  }

  return null;
};
