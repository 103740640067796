import {
  AgencyInfo,
  Lepc,
  FireDepartment,
  PoliceDepartment,
  LeadAgency,
} from "../types";
import { RecipientType } from "../../utils";

export const getLepcInfo = (lepc: Lepc | null): AgencyInfo | null => {
  if (!lepc) return null;

  return {
    name: lepc.name ?? "",
    email: lepc.email ?? null,
    contactName: lepc.contactName ?? null,
    mailingAddress: lepc.mailingAddress ?? null,
    mailingAddressLine2: lepc.mailingAddressLine2 ?? null,
    city: lepc.city ?? null,
    state: lepc.state ?? null,
    zip: lepc.zip ?? null,
    isEmailed: lepc.isEmailed,
    isMailed: lepc.isMailed,
  };
};

export const getFireDepartmentInfo = (
  fireDepartment: FireDepartment | null
): AgencyInfo | null => {
  if (!fireDepartment) return null;

  return {
    name: fireDepartment.name ?? "",
    email: fireDepartment.email ?? null,
    contactName: `${fireDepartment.firstName ?? ""} ${
      fireDepartment.lastName ?? ""
    }`,
    mailingAddress: fireDepartment.mailingAddress ?? null,
    mailingAddressLine2: fireDepartment.mailingAddressLine2 ?? null,
    city: fireDepartment.city ?? null,
    state: fireDepartment.state ?? null,
    zip: fireDepartment.zip ?? null,
    isEmailed: fireDepartment.isEmailed,
    isMailed: fireDepartment.isMailed,
    preferUncertified: fireDepartment.preferUncertified,
  };
};

export const getPoliceDepartmentInfo = (
  policeDepartment: PoliceDepartment | null
): AgencyInfo | null => {
  if (!policeDepartment) return null;

  return {
    name: policeDepartment.name ?? "",
    email: policeDepartment.email ?? null,
    contactName: policeDepartment.contactName ?? null,
    mailingAddress: policeDepartment.address ?? null,
    city: policeDepartment.city ?? null,
    state: policeDepartment.state ?? null,
    zip: policeDepartment.zip ?? null,
    isEmailed: true, // We typically mail police deparments, but I'll leave this option since that's a softer convention and TPMs will make the decision.
    isMailed: true,
  };
};

export const getLeadAgencyInfo = (
  leadAgency: LeadAgency | null
): AgencyInfo | null => {
  if (!leadAgency) return null;

  return {
    name: leadAgency.name ?? "",
    email: leadAgency.email ?? null,
    contactName: leadAgency.attn ?? null,
    mailingAddress: leadAgency.address ?? null,
    city: leadAgency.city ?? null,
    state: leadAgency.state ?? null,
    zip: leadAgency.zip ?? null,
    isEmailed: true,
    isMailed: true,
  };
};

interface CommunicationRestrictions {
  isEmailAllowed: boolean;
  isMailAllowed: boolean;
  preferUncertified: boolean;
}

export const getCommunicationTypeRestrictions = (
  recipientType?: RecipientType | null,
  lepc?: Lepc | null,
  fireDepartment?: FireDepartment | null
): CommunicationRestrictions => {
  switch (recipientType) {
    case RecipientType.LEPC:
      return {
        isEmailAllowed: lepc?.isEmailed ?? true,
        isMailAllowed: lepc?.isMailed ?? true,
        preferUncertified: lepc?.preferUncertified ?? false,
      };
    case RecipientType.FIRE_DEPARTMENT:
      return {
        isEmailAllowed: fireDepartment?.isEmailed ?? true,
        isMailAllowed: fireDepartment?.isMailed ?? true,
        preferUncertified: fireDepartment?.preferUncertified ?? false,
      };
    case RecipientType.POLICE_DEPARTMENT:
    case RecipientType.LEAD_AGENCY:
    case RecipientType.CUSTOM:
      return {
        isEmailAllowed: true,
        isMailAllowed: true,
        preferUncertified: false,
      };
    default:
      return {
        isEmailAllowed: true,
        isMailAllowed: true,
        preferUncertified: false,
      };
  }
};
