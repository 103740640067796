import { useQuery } from "@apollo/client";
import { GridSortModel } from "@mui/x-data-grid-premium";
import { gql } from "generated-graphql";

const ACTIVITY_LOGS = gql(`
  query ActivityLogs($activityId: ID!,  $search: String, $page: Int, $pageSize: Int, $sort: [SortModel!]) {
    activityLogs(activityId: $activityId, search:$search, page: $page, pageSize: $pageSize, sort: $sort) {
      items {
        id
        activityId
        status
        errorType
        message
        time
      }
      count
    }
  }
`);

export const useActivityLogs = (
  activityId: string | undefined,
  search: string,
  page: number,
  pageSize: number,
  sort: GridSortModel
) => {
  return useQuery(ACTIVITY_LOGS, {
    variables: { activityId: activityId ?? "", search, page, pageSize, sort },
    skip: !activityId,
  });
};
