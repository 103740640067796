import { useEffect, useState, useCallback } from "react";
import { useDownloadReportPreview } from "routes/Customer/Chemicals/Report/useDownloadReportPreview";
import { LongPollingJobWrapper, PollingResult } from "./LongPollingJobWrapper";
import { LoadingButton, LoadingButtonProps } from "@mui/lab";
import Download from "@mui/icons-material/Download";
import { useCheckForFile } from "hooks/useCheckForFile";
import { useAlerts } from "./Alerts/AlertProvider";
import { GetJobQuery } from "generated-graphql/graphql";
import { JobStatus } from "encamp-shared/src/generated-graphql/oak-resolver-types";

export function GeneratePdfButton({
  reportId,
  label,
  ...loadingButtonProps
}: {
  reportId: string;
  label?: string;
} & LoadingButtonProps) {
  const { downloadReportPreview } = useDownloadReportPreview();
  const [jobId, setJobId] = useState<string | undefined>(undefined);
  const [reportPdfS3Key, setReportPdfS3Key] = useState<string | undefined>(
    undefined
  );
  const { fetchFile } = useCheckForFile(reportPdfS3Key);
  const alerts = useAlerts();

  const handleDownloadClick = useCallback(async () => {
    // clear old job state
    setJobId(undefined);
    setReportPdfS3Key(undefined);

    const result = await downloadReportPreview(reportId);
    setReportPdfS3Key(result?.s3Key);
    setJobId(result?.jobId);
  }, [downloadReportPreview, reportId]);

  const pollingCompleted = useCallback(
    async (result: PollingResult) => {
      if (!result.pollingTimedOut && result.job?.status === JobStatus.Failed) {
        alerts.error("Failed to generate PDF");
        return;
      }

      if (result.pollingTimedOut) {
        alerts.error("Polling for PDF generation job status timed out");
        return;
      }

      if (result.job?.status === JobStatus.Succeeded) {
        if (
          result.job.output.downloadKeys &&
          result.job.output.downloadKeys.length
        ) {
          for (const s3Key of result.job.output.downloadKeys) {
            const signedUrl = await fetchFile(s3Key);
            if (signedUrl) {
              window.open(signedUrl, "_blank");
              return;
            }
          }
        }
      }

      alerts.error("Failed to generate PDF. File not found");
    },
    [alerts, fetchFile]
  );

  return (
    <LongPollingJobWrapper
      key={jobId || "initial"}
      jobId={jobId}
      pollingCompleted={pollingCompleted}
      timeoutInSeconds={1200}
    >
      <LoadingButton
        startIcon={<Download />}
        color="primary"
        size="small"
        onClick={handleDownloadClick}
        {...loadingButtonProps}
      >
        {label ?? "Download Preview"}
      </LoadingButton>
    </LongPollingJobWrapper>
  );
}
