export const DEFAULT_MIN_AMOUNT = 0.001;
export const DEFAULT_MIN_AMOUNT_DECIMAL_PLACES = 3;

// The smallest non zero max amount I have seen was 0.000051 for Gamma Ray Cal at Halliburton Sperry AK
// - https://ehs.encamp.com/o/0b322147-3f77-4567-8b6a-98f84a84da5b/chemicals/inventory/34b38c77-17cd-455b-9281-db93020ea93d/chemical-summary?ry=2024
// - https://linear.app/encamp/issue/ENG-10779/bypass-maxavg-rule-for-radioactive-materials-in-alaska
// We can make this smaller of we need to although we do format to 15 decimal places
// in the chemical aggregation so if we go lower than 15 decimal places we need to
// adjust aggregation code accordingly and consider how we store amounts in the database
export const AK_MIN_AMOUNT: number = 0.000000000001;
export const AK_MIN_AMOUNT_DECIMAL_PLACES: number = 12;
