import { Alert, useTheme } from "@mui/material";
import { useQuery, gql } from "@apollo/client";
import { useReport } from "routes/Customer/Chemicals/Report/useReport";
import {
  GetReportDetailsQuery,
  TierIiReportStep,
} from "generated-graphql/graphql";

export const RFC_COUNTS_QUERY = gql(`
  query ReportingFacilityChemicalCounts($reportId: ID!) {
    reportingFacilityChemicalCounts(reportId: $reportId) {
      chemicalsToReportCount
      allChemicalsCount
    }
  }
`);

function useReportChemicals() {
  const { data: report } = useReport();
  const { data, loading } = useQuery(RFC_COUNTS_QUERY, {
    variables: {
      reportId: report?.tierIIReport?.id,
    },
    skip: !report,
    fetchPolicy: "cache-and-network",
  });

  return { data, loading };
}

export function checkChemicalSteps(report?: GetReportDetailsQuery) {
  const chemicalSteps: TierIiReportStep[] = [
    TierIiReportStep.Chemicals,
    TierIiReportStep.ChemicalSummary,
    TierIiReportStep.OtherChemicals,
    TierIiReportStep.Products,
  ];
  return report?.tierIIReport?.touchedSteps?.some((s) =>
    chemicalSteps.includes(s)
  );
}

export function useHasChemicalsToReport() {
  const { data, loading } = useReportChemicals();

  const hasChemicalsToReport =
    data?.reportingFacilityChemicalCounts?.chemicalsToReportCount > 0;
  return { hasChemicalsToReport, loading };
}

export function ReportHasNoChemicalsAlert() {
  const theme = useTheme();
  const { data: report } = useReport();
  const hasStepsTouched = checkChemicalSteps(report);
  const { hasChemicalsToReport, loading } = useHasChemicalsToReport();

  if (loading || hasChemicalsToReport === true || !hasStepsTouched) {
    return <></>;
  }

  return (
    <Alert sx={{ mb: theme.spacing(2) }} severity="info">
      This facility has no chemicals over threshold and may not need to file a
      Tier II report.
    </Alert>
  );
}
