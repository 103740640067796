import { useLazyQuery } from "@apollo/client";
import { gql } from "generated-graphql";
import { useCallback } from "react";

const CHECK_FOR_FILE = gql(`query CheckForFile($key: String!) {
    checkForFile(key: $key) {
      exists
      signedUrl
    }
  }`);

export const useCheckForFile = (defaultKey?: string) => {
  const [checkForFile] = useLazyQuery(CHECK_FOR_FILE, {
    fetchPolicy: "network-only",
  });

  const fetchFile = useCallback(
    async (overrideKey?: string) => {
      const fileKey = overrideKey ?? defaultKey;
      if (!fileKey) {
        return undefined;
      }

      const { data } = await checkForFile({ variables: { key: fileKey } });
      return data?.checkForFile?.signedUrl;
    },
    [defaultKey, checkForFile]
  );

  return { fetchFile };
};
