import { Box, Stack, Typography } from "@mui/material";
import { RecipientCard } from "../components/RecipientCard";

export const RecipientsStep = () => {
  return (
    <Stack spacing={3}>
      <Box>
        <Typography variant="h5" gutterBottom>
          Recipient Information
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Select a recipient type and provide their contact information. You can
          choose between LEPC, Fire Department, Police Department, Lead Agency,
          or a custom recipient.
        </Typography>
      </Box>

      <RecipientCard />
    </Stack>
  );
};
