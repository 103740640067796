import { useMutation } from "@apollo/client";
import { gql } from "generated-graphql";
import { useAlerts } from "components/Alerts/AlertProvider";
import { useParams } from "react-router-dom";
import { ProcessingFunction } from "generated-graphql/graphql";
import { v4 } from "uuid";
import invariant from "invariant";
import {
  FileUploadImportConfig,
  ImportConfig,
  Importer,
  ImporterProps,
} from "./Importer";

const GENERATE_IMPORTER_PRESIGNED_URL = gql(`
    mutation GenerateImporterPresignedUrl($fileName: String!, $tenantId: ID!, $facilityId: ID, $directory: String, $contentType: String!) {
      generatePresignedUrl(fileName: $fileName, tenantId: $tenantId, facilityId: $facilityId, directory: $directory, contentType: $contentType) {
        key
        presignedUrl
      }
    }
  `);

const PROCESS_ONESCHEMA_FILE_UPLOAD = gql(`
    mutation ProcessOneschemaFileUpload($input: ProcessOneschemaFileUploadInput!) {
      processOneschemaFileUpload(input: $input) {
        result
      }
    }
  `);

type WasteManifestImporterProps = Omit<
  ImporterProps,
  "postProcessingFunction" | "importConfig"
> & {
  processingFunction: ProcessingFunction;
};

export function WasteManifestImporter({
  templateType,
  refetchQueries = [],
  label,
  style,
  processingFunction,
}: WasteManifestImporterProps) {
  const { tenantId } = useParams();
  const alerts = useAlerts();

  const [generatePresignedUrl] = useMutation(GENERATE_IMPORTER_PRESIGNED_URL);
  const [processFileUpload] = useMutation(PROCESS_ONESCHEMA_FILE_UPLOAD);

  invariant(tenantId, "Tenant ID is required");

  const getImportConfig = async (): Promise<FileUploadImportConfig> => {
    const { data } = await generatePresignedUrl({
      variables: {
        fileName: `${templateType}_${v4()}.csv`,
        tenantId: tenantId,
        facilityId: undefined,
        directory: "one-schema-uploads",
        contentType: "text/csv",
      },
    });

    return {
      type: "file-upload",
      url: data?.generatePresignedUrl?.presignedUrl || "",
      format: "csv",
    };
  };

  const handlePostProcessing = async (
    handleSuccessfulImport: (message: string) => Promise<void>,
    importConfig: ImportConfig | null
  ) => {
    if (!importConfig || importConfig.type !== "file-upload") {
      throw new Error("Import config must be a file-upload");
    }
    try {
      const { data } = await processFileUpload({
        variables: {
          input: {
            tenantId: tenantId,
            url: importConfig.url,
            processingFunction,
          },
        },
      });
      await handleSuccessfulImport(
        `Successfully created job: ${data?.processOneschemaFileUpload.result}`
      );
    } catch (error) {
      console.error("Error processing file upload:", error);
      alerts.error("An error occurred during file processing");
    }
  };

  return (
    <Importer
      templateType={templateType}
      refetchQueries={refetchQueries}
      label={label}
      style={style}
      postProcessingFunction={handlePostProcessing}
      getImportConfigFunction={getImportConfig}
    />
  );
}
