import Check from "@mui/icons-material/Check";
import OpenInNew from "@mui/icons-material/OpenInNew";
import { Paper, Tooltip, Typography } from "@mui/material";
import { GridActionsCellItem } from "@mui/x-data-grid-premium";
import { useAlerts } from "components/Alerts/AlertProvider";
import { OmnisearchDataGrid } from "components/OmnisearchDataGrid";
import { gql } from "generated-graphql";
import {
  TierIiReportEncampStatus,
  TierIiReportOrgStatus,
  WaitForAllCohortQuery,
} from "generated-graphql/graphql";
import { OmnisearchGridColDef } from "hooks/useOmnisearchDatagridSettings";
import { useCallback, useMemo } from "react";
import { encampStatusToLabel, orgStatusToLabel } from "util/constants";

export type Row = WaitForAllCohortQuery["tierIIReportActivities"]["items"][0];

const WAIT_FOR_ALL_COHORT_QUERY = gql(`
  query WaitForAllCohort($search: String, $page: Int, $pageSize: Int, $sort: [SortModel!]) {
    tierIIReportActivities(search: $search, page: $page, pageSize: $pageSize, sort: $sort) {
      items {
        id
        tenantId
        tierIIReportId
        reportingYear
        productCount
        organizationStatus
        encampStatus
        state
        facilityName
        facilityId
        streetAddress1
        streetAddress2
        city
        state
        zip
        customerFacilityId
        organizationName
        workflowId
        activityId
        activityType
        activityDescription
        activityAssigneeFirst
        activityAssigneeLast
        activityAssigneeEmail
        activityExecutionPlan
        activityStatus
        hasIncompleteReviews
        tenantId
        isChampionReport
      }
      count
    }
  }
`);

export function WaitForAllCohortTable({
  cohortFacilityIds,
  cohortType,
}: {
  cohortFacilityIds: string[];
  cohortType: string;
}) {
  const alerts = useAlerts();

  const viewReport = useCallback(
    (id?: string) => {
      if (id) {
        const newTab = window.open(
          `${window.location.origin}/staff/fulfillment/${id}`,
          "_blank"
        );
        if (newTab) {
          newTab.focus();
        } else {
          alerts.error(
            "Unable to open the URL in a new tab. Please allow pop-ups for this website."
          );
        }
      }
    },
    [alerts]
  );

  const columns = useMemo((): OmnisearchGridColDef<Row>[] => {
    return [
      {
        field: "facilityName",
        headerName: "Facility",
        flex: 1,
        valueGetter: ({ row }) =>
          row.customerFacilityId
            ? `${row.facilityName} - ${row.customerFacilityId}`
            : row.facilityName,
      },
      {
        field: "streetAddress1",
        headerName: "Address",
        flex: 1,
        valueGetter: ({ row }) =>
          `${row.streetAddress1} ${row.streetAddress2} ${row.city}, ${row.state} ${row.zip}`,
      },
      {
        field: "hasReport",
        headerName: "Report?",
        flex: 1,
        renderCell: ({ row }) => (row.tierIIReportId ? <Check /> : <></>),
        filterKeyType: "boolean",
      },
      {
        field: "organizationStatus",
        headerName: "Organization Status",
        flex: 1,
        valueGetter: ({ row }) => {
          return row.organizationStatus
            ? orgStatusToLabel(row.organizationStatus)
            : undefined;
        },
        filterKeyType: "enum",
        enumValues: Object.values(TierIiReportOrgStatus),
      },
      {
        field: "encampStatus",
        headerName: "Encamp Status",
        flex: 1,
        valueGetter: ({ row }) => {
          return row.encampStatus
            ? encampStatusToLabel(row.encampStatus)
            : undefined;
        },
        filterKeyType: "enum",
        enumValues: Object.values(TierIiReportEncampStatus),
      },
      {
        field: "activityDescription",
        valueGetter: (params) => {
          if (!params.row.activityType) return "N/A";
          return params.row.activityDescription
            ? `${params.row.activityType}: ${params.row.activityDescription}`
            : `${params.row.activityType}`;
        },
        headerName: "Activities",
        flex: 1,
      },
      {
        field: "moreInfo",
        type: "actions",
        getActions: (params) => {
          const actions = [];
          if (params.row.tierIIReportId) {
            actions.push(
              <Tooltip title="View Submission" key={1}>
                <GridActionsCellItem
                  onClick={() => viewReport(params.row.tierIIReportId ?? "")}
                  label="View Submission"
                  icon={<OpenInNew />}
                />
              </Tooltip>
            );
          }
          return actions;
        },
      },
    ];
  }, [viewReport]);

  const orgStatuses = Object.values(TierIiReportOrgStatus)
    .sort((a, b) =>
      // Not reporting must be first due to overrideOrganizationStatusBaseSearch
      a === TierIiReportOrgStatus.NotReporting
        ? -1
        : b === TierIiReportOrgStatus.NotReporting
        ? 1
        : 0
    )
    .join("|");

  const defaultSearch = useMemo(
    () =>
      `organizationStatus:${orgStatuses} facilityId:${cohortFacilityIds.join(
        "|"
      )}`,
    [cohortFacilityIds, orgStatuses]
  );

  return (
    <Paper sx={{ p: 3 }}>
      <Typography variant="h6">Facilities in Cohort: {cohortType}</Typography>
      <OmnisearchDataGrid
        withPadding={false}
        isURLDriven={false}
        initialState={{
          pinnedColumns: {
            right: ["actions"],
          },
        }}
        initialSortModel={[{ field: "facilityName", sort: "asc" }]}
        defaultSearch={defaultSearch}
        columns={columns}
        dataQuery={WAIT_FOR_ALL_COHORT_QUERY}
        getItems={(data) => data.tierIIReportActivities.items}
        getCount={(data) => data.tierIIReportActivities.count}
        disableRowSelectionOnClick
        onRowClick={({ row }) => {
          viewReport(row.tierIIReportId ?? undefined);
        }}
        additionalFilterColumns={[
          {
            header: "Champ",
            key: "isChampionReport",
            filterKeyType: "boolean",
          },
        ]}
      />
    </Paper>
  );
}
