import {
  Button,
  DialogActions,
  DialogContent,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { DateField } from "components/Forms/DateField";
import { SaveButton } from "components/SaveButton";
import { useFormContext } from "react-hook-form";
import { Fragment } from "react/jsx-runtime";

type ExemptProps = {
  handleBack: () => void;
  loading?: boolean;
};

export function ProvidedToRegulator({ handleBack, loading }: ExemptProps) {
  const theme = useTheme();
  const { control, watch } = useFormContext();

  const providedToRegulator: string | null = watch("providedToRegulator");

  return (
    <Fragment>
      <DialogContent>
        <Typography variant="body1" marginBottom={theme.spacing(2)}>
          Stored at Regulatory Agency
        </Typography>

        <Typography variant="body2" marginBottom={theme.spacing(2)}>
          If previously approved by your local regulator, you can use this
          document option to indicate the document(s) has been provided to the
          Regulator. Normally this option should only be used when the
          document(s) are sensitive and should not be made readily available to
          the public, such as the Emergency Response Plan for a refinery or
          large chemical manufacturing plant. Enter the date the document was
          provided to the regulator.
        </Typography>

        <DateField
          control={control}
          name="providedToRegulator"
          label="Date Provided"
        />
      </DialogContent>
      <DialogActions>
        <Stack
          direction="row"
          gap={theme.spacing(2)}
          justifyContent="flex-end"
          sx={{ marginTop: theme.spacing(2) }}
        >
          <Button onClick={handleBack} variant="outlined">
            Back
          </Button>
          <SaveButton
            loading={loading ?? false}
            disabled={providedToRegulator === null}
          />
        </Stack>
      </DialogActions>
    </Fragment>
  );
}
