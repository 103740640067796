import { Stack, Tooltip, Typography } from "@mui/material";
import { GridColDef, GridSortModel } from "@mui/x-data-grid-premium";
import { DataGrid } from "components/DataGrid";
import { ActivityLog } from "generated-graphql/graphql";
import { useActivityLogs } from "hooks/useActivityLogs";
import { usePaginationModel } from "hooks/usePaginationModel";
import { useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { convertEnumToHumanReadable, getActivityTitle } from "util/activity";
import { prettyPrintDateTime, relativeLogTime } from "util/dates";
import { useReportDetails } from "../useReportDetails";

export function ActivityLogs() {
  const { data } = useReportDetails();
  const { activityId } = useParams<{ activityId: string }>();
  const activity = useMemo(
    () =>
      data?.tierIIReport.currentWorkflow?.activities.find(
        (act) => act.id === activityId
      ),
    [activityId, data?.tierIIReport.currentWorkflow?.activities]
  );

  const [paginationModel, setPaginationModel] = usePaginationModel();
  const [sortModel, setSortModel] = useState<GridSortModel>([
    { field: "time", sort: "desc" },
  ]);
  const {
    data: logData,
    previousData: previousLogData,
    loading,
  } = useActivityLogs(
    activity?.id,
    "",
    paginationModel.page,
    paginationModel.pageSize,
    sortModel
  );

  const columns: GridColDef<ActivityLog>[] = useMemo(
    () => [
      {
        field: "status",
        headerName: "Status",
        valueGetter(params) {
          return convertEnumToHumanReadable(params.row.status);
        },
      },
      {
        field: "time",
        headerName: "Logged At",
        flex: 0.5,
        renderCell: ({ row }) => (
          <Tooltip title={prettyPrintDateTime(row.time)}>
            <span>{relativeLogTime(row.time)}</span>
          </Tooltip>
        ),
      },
      {
        field: "message",
        headerName: "Message/Reason",
        flex: 1,
        cellClassName: "wordWrapCell",
      },
    ],
    []
  );

  const rows = useMemo(
    () =>
      logData?.activityLogs.items ?? previousLogData?.activityLogs.items ?? [],
    [logData?.activityLogs.items, previousLogData?.activityLogs.items]
  );

  const count = useMemo(
    () =>
      logData?.activityLogs.count ?? previousLogData?.activityLogs.count ?? 0,
    [logData?.activityLogs.count, previousLogData?.activityLogs.count]
  );

  return (
    <Stack
      sx={{
        height: "100%",
        position: "relative",
        pr: 2,
        pb: 2,
      }}
    >
      <Typography variant="h5" sx={{ mb: 2 }}>
        {activity && getActivityTitle(activity)}
      </Typography>
      <DataGrid
        loading={loading}
        columns={columns}
        rows={rows}
        rowCount={count}
        getRowHeight={() => "auto"}
        pagination
        paginationMode="server"
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        sortingMode="server"
        sortModel={sortModel}
        onSortModelChange={setSortModel}
      />
      <style>
        {`
        .wordWrapCell {
          white-space: normal !important;
          word-wrap: break-word !important;
        }
      `}
      </style>
    </Stack>
  );
}
