import AccountBalance from "@mui/icons-material/AccountBalance";
import AddToQueue from "@mui/icons-material/AddToQueue";
import EngineeringIcon from "@mui/icons-material/Engineering";
import HikingIcon from "@mui/icons-material/Hiking";
import Mail from "@mui/icons-material/Mail";
import MonetizationOn from "@mui/icons-material/MonetizationOn";
import Notifications from "@mui/icons-material/Notifications";
import SurfingIcon from "@mui/icons-material/Surfing";
import Work from "@mui/icons-material/Work";
import { Typography, useTheme } from "@mui/material";
import { useTenant } from "hooks/useTenant";
import { useMemo } from "react";
import { Outlet } from "react-router-dom";
import { useCustomerNavItems } from "routes/Customer";
import { Layout } from "../../components/Layout";
import { NavItem } from "../../components/LeftNavDrawer";
import { StaffOnly } from "../../components/StaffOnly";

export function useStaffNavItems(): NavItem[] {
  const theme = useTheme();
  return [
    {
      kind: "CustomElement",
      customElement: (
        <Typography
          sx={{
            fontWeight: "bold",
            fontSize: { xs: "40px", md: "16px" },
            color: theme.palette.warning.light,
            ml: {
              md: "30px",
              xs: "10px",
            },
            pt: theme.spacing(5),
          }}
        >
          Staff
        </Typography>
      ),
    },
    {
      text: "Fulfillment",
      path: "staff/fulfillment",
      Icon: HikingIcon,
      kind: "Standard",
      textColor: theme.palette.warning.light,
    },
    {
      kind: "Standard",
      text: "Organizations",
      path: "staff/implementation",
      Icon: SurfingIcon,
      textColor: theme.palette.warning.light,
    },
    {
      kind: "Standard",
      text: "Agencies",
      path: "staff/agencies",
      Icon: AccountBalance,
      textColor: theme.palette.warning.light,
    },
    {
      kind: "Standard",
      text: "Workflow Config",
      path: "staff/workflow-config",
      Icon: EngineeringIcon,
      textColor: theme.palette.warning.light,
    },
    /** uncomment when mail room is ready
    {
      kind: "Standard",
      text: "Mail Room",
      path: "staff/mail-room",
      Icon: Mail,
      textColor: theme.palette.warning.light,
    },
 */
    {
      kind: "Standard",
      text: "Notifications",
      path: "staff/notifications",
      Icon: Notifications,
      textColor: theme.palette.warning.light,
    },
    {
      kind: "Standard",
      text: "Payments",
      path: "staff/payments",
      Icon: MonetizationOn,
      textColor: theme.palette.warning.light,
    },
    {
      kind: "Standard",
      text: "Jobs",
      path: "staff/jobs",
      Icon: Work,
      textColor: theme.palette.warning.light,
    },
    {
      kind: "Standard",
      text: "Demo Organization Creator",
      path: "staff/demoOrganizationCreator",
      Icon: AddToQueue,
      textColor: theme.palette.warning.light,
    },
  ];
}

export function Staff() {
  const { tenant } = useTenant();

  const customerNavItems = useCustomerNavItems()["navItems"];
  const staffNavItems = useStaffNavItems();

  const navItems = useMemo(() => {
    if (tenant) {
      return customerNavItems;
    } else {
      return staffNavItems;
    }
  }, [tenant, customerNavItems, staffNavItems]);

  return (
    <StaffOnly>
      <Layout navItems={navItems}>
        <Outlet />
      </Layout>
    </StaffOnly>
  );
}
