import { Box, IconButton, Tooltip } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid-premium";
import { OmnisearchDataGrid } from "components/OmnisearchDataGrid";
import { prettyPrintEnumValue } from "encamp-shared/src/utils/prettyPrintEnumValue";
import { gql } from "generated-graphql";
import { TierIiReportsForCommunicationQuery } from "generated-graphql/graphql";
import { useCallback, useMemo, useState } from "react";
import { getFacilityName } from "../../utils";
import { useNavigate } from "react-router-dom";
import DescriptionIcon from "@mui/icons-material/Description";
import HikingIcon from "@mui/icons-material/Hiking";

type Row = TierIiReportsForCommunicationQuery["tierIIReports"]["items"][number];

const TIER_II_REPORTS_FOR_COMMUNICATION = gql(`
  query TierIIReportsForCommunication($search: String, $page: Int, $pageSize: Int, $sort: [SortModel!]) {
    tierIIReports(search: $search, page: $page, pageSize: $pageSize, sort: $sort) {
      items {
        id
        tenantId
        reportKind
        reportingYear
        facilityId
        facility {
          id
          name
          customerFacilityId
          streetAddress1
          city
          state
          zip
        }
      }
      count
    }
  }
`);

interface ReportsGridProps {
  disabled: boolean;
  facilityIds: string[];
  selectedReportIds: string[];
  onReportSelect: (reportIds: string[]) => void;
}

export const ReportsGrid = ({
  disabled,
  facilityIds,
  selectedReportIds,
  onReportSelect,
}: ReportsGridProps) => {
  const navigate = useNavigate();

  const columns: GridColDef<Row>[] = useMemo(() => {
    return [
      {
        field: "facility",
        headerName: "Facility",
        flex: 0.7,
        valueGetter: (params) => {
          const facilityName = getFacilityName(params.row.facility);
          return facilityName;
        },
      },
      {
        field: "reportingYear",
        headerName: "Year",
        flex: 0.15,
      },
      {
        field: "reportKind",
        headerName: "Report Type",
        flex: 0.3,
        valueGetter: (params) => {
          return params.row.reportKind;
        },
        valueFormatter: (params) => {
          return prettyPrintEnumValue(params.value);
        },
      },
      {
        field: "address",
        headerName: "Address",
        flex: 0.3,
        valueGetter: (params) => {
          return [
            params.row.facility.streetAddress1,
            params.row.facility.city,
            params.row.facility.state,
            params.row.facility.zip,
          ]
            .filter(Boolean)
            .join(", ");
        },
      },
      {
        field: "actions",
        headerName: "Actions",
        flex: 0.2,
        sortable: false,
        renderCell: (params) => (
          <Box>
            <Tooltip title="Go to Fulfillment">
              <IconButton
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  window.open(`/staff/fulfillment/${params.row.id}`, "_blank");
                }}
              >
                <HikingIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="View Customer Report">
              <IconButton
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  window.open(
                    `/o/${params.row.tenantId}/chemicals/reports/${params.row.id}`,
                    "_blank"
                  );
                }}
              >
                <DescriptionIcon />
              </IconButton>
            </Tooltip>
          </Box>
        ),
      },
    ];
  }, [navigate]);

  // Construct facility filter for the search query
  const facilityFilter = facilityIds.join("|");

  return (
    <Box>
      <OmnisearchDataGrid
        columns={columns}
        dataQuery={TIER_II_REPORTS_FOR_COMMUNICATION}
        skip={!facilityFilter}
        initialSortModel={[
          { field: "updatedAt", sort: "desc" },
          { field: "reportKind", sort: "asc" },
        ]}
        defaultSearch={
          disabled
            ? `id:${selectedReportIds.join("|")}`
            : `facilityId:${facilityFilter}`
        }
        getItems={(data) => data.tierIIReports.items}
        getCount={(data) => data.tierIIReports.count}
        noDataMessage="No reports found for the selected facilities."
        isURLDriven={false}
        selectedIds={selectedReportIds}
        disableRowSelectionOnClick={false}
        isRowSelectable={(params) => !disabled}
        initialPageSize={10}
        onSelectedIdsChanged={(ids) => onReportSelect(ids as string[])}
      />
    </Box>
  );
};
