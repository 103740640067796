import {
  PROVINCE_TERRITORY_NAMES,
  STATE_NAMES,
} from "encamp-shared/src/constants/states";
import { sortBy } from "lodash";
import { useEffect, useMemo } from "react";
import { UseControllerProps, useFormContext } from "react-hook-form";
import { FormSelect, SelectItem } from "./Forms/FormSelect";

type Props = {
  disabled?: boolean;
  countryFieldName?: string;
} & UseControllerProps;

export function StatePicker(props: Props) {
  const { watch, setValue, getFieldState } = useFormContext();
  const country = watch(props.countryFieldName || "country");
  const state = watch(props.name);

  const isCountryValid = useMemo(() => {
    return (
      !country ||
      (country === "USA" && STATE_NAMES[state as keyof typeof STATE_NAMES])
    );
  }, [country, state]);

  useEffect(() => {
    // reset the state if the country changed and the current state is not valid
    if (
      props.countryFieldName &&
      getFieldState(props.countryFieldName).isDirty &&
      !isCountryValid
    ) {
      setValue(props.name, null);
    }
  }, [
    country,
    setValue,
    getFieldState,
    props.name,
    props.countryFieldName,
    isCountryValid,
  ]);

  const selectItems: SelectItem[] = useMemo(
    () =>
      sortBy(
        Object.entries(
          country === "Canada" ? PROVINCE_TERRITORY_NAMES : STATE_NAMES
        ),
        1
      ).map(([abbr, name]) => ({
        display: name,
        value: abbr,
      })),
    [country]
  );

  const label = useMemo(() => {
    return country === "Canada" ? "Province/Territory" : "State";
  }, [country]);

  return <FormSelect {...props} label={label} selectItems={selectItems} />;
}
