import Close from "@mui/icons-material/Close";
import { Dialog, DialogTitle, IconButton, Stack } from "@mui/material";
import { useAlerts } from "components/Alerts/AlertProvider";
import {
  hmbpSectionDisplay,
  hmbpSectionToDocumentType,
  HmbpSectionWithoutRegulatory,
} from "encamp-shared/src/hmbp";
import { CaSections } from "encamp-shared/src/hmbp/sections";
import {
  DocumentType,
  DynamicField,
  Issue,
  TierIiReportCaStep,
} from "generated-graphql/graphql";
import { useReplaceReportDocumentsByType } from "hooks/useReplaceReportDocumentsByType";
import { useTenant } from "hooks/useTenant";
import { useValidatingForm } from "hooks/useValidatingForm";
import { useCallback, useMemo, useState } from "react";
import { FormProvider } from "react-hook-form";
import {
  useHmbpDynamicFields,
  useHmbpIssues,
  useHmbpStateFieldMutation,
  useHmbpValidator,
} from "../../../useHmbp";
import { useReport } from "../../../useReport";
import { useTouchReportCAStepMutation } from "../../../useTouchReportCAStepMutation";
import { DocumentOptions } from "./DocumentOptions";
import { Exempt } from "./Exempt";
import { ProvidedElsewhere } from "./ProvidedElsewhere";
import { ProvidedToRegulator } from "./ProvidedToRegulator";
import { PublicInternetURL } from "./PublicInternetURL";
import { StoredAtFacility } from "./StoredAtFacility";
import { DialogStep, DocumentOption, FormState, FormSubmitData } from "./types";
import { Upload } from "./Upload";

type HmbpDocumentFormProps = {
  section: HmbpSectionWithoutRegulatory;
  handleClose: () => void;
};

function calculateStep(documentOption: DocumentOption | null) {
  if (!documentOption) {
    return DialogStep.DocumentOptions;
  }

  switch (documentOption) {
    case DocumentOption.Upload:
      return DialogStep.Upload;
    case DocumentOption.PublicInternetURL:
      return DialogStep.PublicInternetURL;
    case DocumentOption.ProvidedToRegulator:
      return DialogStep.ProvidedToRegulator;
    case DocumentOption.ProvideElsewhere:
      return DialogStep.ProvidedElsewhere;
    case DocumentOption.Exempt:
      return DialogStep.Exempt;
    case DocumentOption.StoredAtFacility:
      return DialogStep.StoredAtFacility;
  }
}

const fieldKeys = [
  "documentOption",
  "publicInternetURL",
  "providedToRegulator",
  "suppliedIn",
  "suppliedInExplanation",
  "exemptExplanation",
  "documentIds",
  "storedAtFacilityCersId",
] as const;

export function HmbpDocumentForm(props: HmbpDocumentFormProps) {
  const title = hmbpSectionDisplay[props.section];
  const issues = useHmbpIssues(props.section);
  const fields = useHmbpDynamicFields(props.section);
  const validator = useHmbpValidator(props.section);
  const { data: reportData, refetch: refetchReport } = useReport();
  const { tenantId } = useTenant();
  const alerts = useAlerts();

  const {
    handleTouch,
    handleUntouch,
    loading: touchLoading,
  } = useTouchReportCAStepMutation(
    reportData?.tierIIReport.id ?? "",
    reportData?.tierIIReport.touchedCASteps ?? [],
    props.section as unknown as TierIiReportCaStep
  );

  const { mutation: mutateStateFields, loading: loadingStateFields } =
    useHmbpStateFieldMutation();
  const { replaceReportDocumentsByType, loading: loadingReplaceDocuments } =
    useReplaceReportDocumentsByType();

  const loading = loadingStateFields || loadingReplaceDocuments || touchLoading;

  const handleSave = useCallback(
    async ({ data, touch }: { data: FormSubmitData; touch: boolean }) => {
      try {
        await mutateStateFields(data.stateFields);
        // We're touching first since the save causes a refetch of the report
        // and we want to make sure the report is touched before the refetch
        // so there are no race conditions
        if (touch) {
          await handleTouch();
        } else {
          await handleUntouch();
        }

        await replaceReportDocumentsByType({
          documentsWithFile: data.documentUploads.map((document) => ({
            file: document.file,
            documentData: {
              id: document.document?.id,
              tenantId,
              title: document.document?.title ?? document.file?.name,
              documentType: hmbpSectionToDocumentType[props.section],
              authoredAt: document.document?.authoredAt ?? null,
              description: document.document?.description ?? undefined,
            },
          })),
          reportId: reportData?.tierIIReport.id ?? "",
          documentType: hmbpSectionToDocumentType[
            props.section
          ] as DocumentType,
        });
        alerts.success("State information saved successfully.");
        refetchReport();
      } catch (err) {
        alerts.error("Error saving state information.");
        console.error("Error saving state information.", err);
      }
    },
    [
      mutateStateFields,
      handleTouch,
      handleUntouch,
      replaceReportDocumentsByType,
      reportData?.tierIIReport.id,
      props.section,
      alerts,
      refetchReport,
      tenantId,
    ]
  );

  const { data } = useReport();
  const facilityId = data?.tierIIReport?.facility?.id ?? "";

  const formFields = useMemo(
    () => createFieldMapping(fields, fieldKeys),
    [fields]
  );

  const genericIssues = useMemo(
    () => translateToGenericIssues(issues, formFields),
    [issues, formFields]
  );
  const [step, setStep] = useState(() =>
    calculateStep(formFields.documentOption?.value)
  );

  const defaultFormValues: FormState = useMemo(
    () => ({
      documentOption: formFields.documentOption?.value ?? null,
      publicInternetURL: formFields.publicInternetURL?.value ?? null,
      providedToRegulator: formFields.providedToRegulator?.value ?? null,
      suppliedIn: formFields.suppliedIn?.value as CaSections | null,
      suppliedInExplanation: formFields.suppliedInExplanation?.value ?? null,
      exemptExplanation: formFields.exemptExplanation?.value ?? null,
      storedAtFacilityCersId: formFields.storedAtFacilityCersId?.value ?? null,
      documentIds: formFields.documentIds?.value ?? [],
      upload: formFields.upload?.value ?? [],
    }),
    [formFields]
  );

  const validationCallback = useCallback(
    async (form: FormState) => {
      const updatedFields = updateFieldsWithFormData(fields, formFields, {
        ...form,
        documentIds: form.upload?.map((upload) => upload.document?.id) ?? [],
      });
      const nonGenericIssues = await validator(updatedFields, form.upload);
      const genericIssues = translateToGenericIssues(
        nonGenericIssues,
        formFields
      );
      return genericIssues;
    },
    [fields, formFields, validator]
  );

  const form = useValidatingForm<FormState>(
    defaultFormValues,
    genericIssues,
    validationCallback
  );

  const { reset, watch } = form;

  const onSubmit = async (data: FormState) => {
    try {
      await handleSave({
        data: {
          documentUploads: data.upload ?? [],
          stateFields: updateFieldsWithFormData(fields, formFields, data),
        },
        touch: true,
      });
      props.handleClose();
    } catch (error) {
      console.error(error);
    }
  };

  const documentOption = watch("documentOption");

  const handleNext = useCallback(() => {
    if (documentOption !== defaultFormValues.documentOption) {
      reset(
        {
          documentOption,
          publicInternetURL: null,
          providedToRegulator: null,
          suppliedIn: null,
          suppliedInExplanation: null,
          exemptExplanation: null,
          storedAtFacilityCersId: null,
          documentIds: [],
          upload: [],
        },
        { keepErrors: true }
      );
    }
    setStep(calculateStep(documentOption));
  }, [defaultFormValues.documentOption, documentOption, reset]);

  const handleBack = useCallback(() => {
    setStep(DialogStep.DocumentOptions);
    reset(defaultFormValues);
  }, [defaultFormValues, reset]);

  const handleClearResponse = useCallback(async () => {
    try {
      await handleSave({
        data: {
          documentUploads: [],
          stateFields: updateFieldsWithFormData(fields, formFields, {
            documentOption: null,
            publicInternetURL: null,
            providedToRegulator: null,
            suppliedIn: null,
            suppliedInExplanation: null,
            exemptExplanation: null,
            storedAtFacilityCersId: null,
          }),
        },
        touch: false,
      });

      props.handleClose();
    } catch (error) {
      console.error(error);
    }
  }, [fields, formFields, handleSave, props]);

  return (
    <Dialog open={true} onClose={props.handleClose} fullWidth>
      <DialogTitle>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          {title}
          <IconButton onClick={props.handleClose}>
            <Close />
          </IconButton>
        </Stack>
      </DialogTitle>
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          {step === DialogStep.DocumentOptions && (
            <DocumentOptions
              handleClose={props.handleClose}
              handleNext={handleNext}
              handleClearResponse={handleClearResponse}
              loading={loading}
            />
          )}
          {step === DialogStep.Upload && (
            <Upload
              loading={loading}
              facilityId={facilityId}
              handleBack={handleBack}
              section={props.section}
              issues={form.issues}
            />
          )}
          {step === DialogStep.PublicInternetURL && (
            <PublicInternetURL loading={loading} handleBack={handleBack} />
          )}
          {step === DialogStep.ProvidedToRegulator && (
            <ProvidedToRegulator loading={loading} handleBack={handleBack} />
          )}
          {step === DialogStep.ProvidedElsewhere && (
            <ProvidedElsewhere loading={loading} handleBack={handleBack} />
          )}
          {step === DialogStep.Exempt && (
            <Exempt loading={loading} handleBack={handleBack} />
          )}
          {step === DialogStep.StoredAtFacility && (
            <StoredAtFacility
              loading={loading}
              handleBack={handleBack}
              facilityId={facilityId}
            />
          )}
        </form>
      </FormProvider>
    </Dialog>
  );
}

type FieldMapping = Record<string, { fieldKey: string; value: any }>;

export function getFieldByGenericKey(fields: DynamicField[], key: string) {
  return fields.find((field) =>
    field.key.toLowerCase().endsWith(key.toLowerCase())
  );
}

export function createFieldMapping(
  fields: DynamicField[],
  genericKeys: readonly string[]
): FieldMapping {
  return genericKeys.reduce((acc, key) => {
    const field = getFieldByGenericKey(fields, key);
    if (field) {
      acc[key] = {
        fieldKey: field.key,
        value: field.value,
      };
    }
    return acc;
  }, {} as FieldMapping);
}

export function translateToGenericIssues(
  issues: Issue[],
  fieldMapping: FieldMapping
): Issue[] {
  return issues.map((issue) => {
    const matchingField = Object.entries(fieldMapping).find(
      ([_, value]) => value.fieldKey === issue.key
    );
    return matchingField ? { ...issue, key: matchingField[0] } : issue;
  });
}

export function updateFieldsWithFormData(
  fields: DynamicField[],
  fieldMapping: FieldMapping,
  formData: Record<string, any>
): DynamicField[] {
  return fields.map((field) => {
    const formField = Object.entries(fieldMapping).find(
      ([_, value]) => value.fieldKey === field.key
    );
    return formField ? { ...field, value: formData[formField[0]] } : field;
  });
}
