import { Button } from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import { useCheckForFile } from "hooks/useCheckForFile";
import { useAlerts } from "components/Alerts/AlertProvider";
import { ButtonProps } from "@mui/material/Button";

interface JobDownloadButtonProps extends Omit<ButtonProps, "onClick"> {
  downloadKeys: string[];
}

export function JobDownloadButton({
  downloadKeys,
  ...buttonProps
}: JobDownloadButtonProps) {
  const { fetchFile } = useCheckForFile();
  const alerts = useAlerts();

  const handleDownload = async () => {
    if (downloadKeys && downloadKeys.length > 0) {
      try {
        for (const key of downloadKeys) {
          const signedUrl = await fetchFile(key);
          if (signedUrl) {
            window.open(signedUrl, "_blank");
          } else {
            throw new Error(`Failed to download file: ${key}`);
          }
        }
      } catch (error) {
        if (error instanceof Error) {
          alerts.error(error.message);
        } else {
          alerts.error("An unknown error occurred while downloading files");
        }
      }
    }
  };

  return (
    <Button
      startIcon={<DownloadIcon />}
      variant="outlined"
      onClick={handleDownload}
      {...buttonProps}
    >
      Download Output Files
    </Button>
  );
}
