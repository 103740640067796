import { useEffect, useCallback } from "react";
import { CommunicationType } from "generated-graphql/graphql";
import {
  DEFAULT_EMAIL_FROM,
  useCommunicationFormContext,
} from "./useCommunicationForm";
import {
  getLepcInfo,
  getFireDepartmentInfo,
  getPoliceDepartmentInfo,
  getLeadAgencyInfo,
  getCommunicationTypeRestrictions,
} from "../utils/agencyInfo";

interface RecipientUpdates {
  recipientName?: string;
  mailRecipientAddressLine1?: string;
  mailRecipientAddressLine2?: string;
  mailRecipientCity?: string;
  mailRecipientState?: string;
  mailRecipientZip?: string;
  emailRecipientAddresses?: string[];
  emailSubject?: string;
  emailFrom?: string;
  emailReplyTo?: string;
}

type RecipientField =
  | "recipient.recipientName"
  | "recipient.communicationType"
  | "recipient.lepc"
  | "recipient.fireDepartment"
  | "recipient.policeDepartment"
  | "recipient.leadAgency"
  | "recipient.mailRecipientAddressLine1"
  | "recipient.mailRecipientAddressLine2"
  | "recipient.mailRecipientCity"
  | "recipient.mailRecipientState"
  | "recipient.mailRecipientZip"
  | "recipient.emailRecipientAddresses"
  | "recipient.emailSubject"
  | "recipient.emailFrom"
  | "recipient.emailReplyTo";

export const useRecipientForm = () => {
  const formContext = useCommunicationFormContext();

  // Watch specific fields instead of the entire recipient object
  const recipientType = formContext.watch("recipient.recipientType");
  const lepc = formContext.watch("recipient.lepc");
  const fireDepartment = formContext.watch("recipient.fireDepartment");
  const policeDepartment = formContext.watch("recipient.policeDepartment");
  const leadAgency = formContext.watch("recipient.leadAgency");
  const communicationType = formContext.watch("recipient.communicationType");

  const { isEmailAllowed, isMailAllowed, preferUncertified } =
    getCommunicationTypeRestrictions(recipientType, lepc, fireDepartment);

  const clearRecipientInfo = useCallback(() => {
    const fieldsToClear: RecipientField[] = [
      "recipient.recipientName",
      "recipient.lepc",
      "recipient.fireDepartment",
      "recipient.policeDepartment",
      "recipient.leadAgency",
      "recipient.communicationType",
      "recipient.mailRecipientAddressLine1",
      "recipient.mailRecipientAddressLine2",
      "recipient.mailRecipientCity",
      "recipient.mailRecipientState",
      "recipient.mailRecipientZip",
      "recipient.emailRecipientAddresses",
      "recipient.emailSubject",
      "recipient.emailFrom",
      "recipient.emailReplyTo",
    ];

    fieldsToClear.forEach((field) => {
      formContext.setValue(field, null, {
        shouldValidate: false,
        shouldDirty: true,
        shouldTouch: true,
      });
    });
  }, []);

  useEffect(() => {
    // Only run if we have a recipient type and one of the agency fields is set
    if (!recipientType) {
      return;
    }

    // Get agency info based on which field changed
    let agencyInfo = null;
    if (lepc) {
      agencyInfo = getLepcInfo(lepc);
    } else if (fireDepartment) {
      agencyInfo = getFireDepartmentInfo(fireDepartment);
    } else if (policeDepartment) {
      agencyInfo = getPoliceDepartmentInfo(policeDepartment);
    } else if (leadAgency) {
      agencyInfo = getLeadAgencyInfo(leadAgency);
    }

    // Create updates object with all recipient fields
    const updates: RecipientUpdates = {
      recipientName: agencyInfo?.contactName ?? agencyInfo?.name,
    };

    // Handle email communication
    if (communicationType === CommunicationType.Email) {
      updates.mailRecipientAddressLine1 = "";
      updates.mailRecipientAddressLine2 = "";
      updates.mailRecipientCity = "";
      updates.mailRecipientState = "";
      updates.mailRecipientZip = "";
      updates.emailFrom = DEFAULT_EMAIL_FROM;
      updates.emailReplyTo = DEFAULT_EMAIL_FROM;
      if (agencyInfo?.email) {
        updates.emailRecipientAddresses = [agencyInfo.email];
      }
      if (agencyInfo?.contactName) {
        updates.emailSubject = `Attn: ${agencyInfo.contactName}`;
      }
    }

    // Handle mail communication
    if (communicationType !== CommunicationType.Email) {
      updates.emailRecipientAddresses = [];
      updates.emailSubject = "";
      updates.emailFrom = "";
      updates.emailReplyTo = "";
      if (agencyInfo?.mailingAddress) {
        updates.mailRecipientAddressLine1 = agencyInfo.mailingAddress;
      }
      if (agencyInfo?.mailingAddressLine2) {
        updates.mailRecipientAddressLine2 = agencyInfo.mailingAddressLine2;
      }
      if (agencyInfo?.city) {
        updates.mailRecipientCity = agencyInfo.city;
      }
      if (agencyInfo?.state) {
        updates.mailRecipientState = agencyInfo.state;
      }
      if (agencyInfo?.zip) {
        updates.mailRecipientZip = agencyInfo.zip;
      }
    }

    // Update only the changed fields
    Object.entries(updates).forEach(([key, value]) => {
      const fieldPath = `recipient.${key}` as RecipientField;
      formContext.setValue(fieldPath, value, {
        shouldValidate: false,
        shouldDirty: true,
        shouldTouch: true,
      });
    });
  }, [
    recipientType,
    lepc,
    fireDepartment,
    policeDepartment,
    leadAgency,
    communicationType,
  ]);

  return {
    formContext,
    recipientType,
    lepc,
    fireDepartment,
    policeDepartment,
    leadAgency,
    communicationType,
    isEmailAllowed,
    isMailAllowed,
    preferUncertified,
    clearRecipientInfo,
  };
};
