import { useLazyQuery } from "@apollo/client";
import { gql } from "generated-graphql";
import { useCallback } from "react";
import { useReport } from "../useReport";

const TIER_II_REPORT_REVIEW_STEP_INPUT_VALIDATION = gql(`
  query TierIIReportReviewStepInputValidation(
    $reportId: ID!
    $facilityId: ID!
    $reasonForReport: String
  ) {
    tierIIReportReviewStepValidator(
      reportId: $reportId
      facilityId: $facilityId
      reasonForReport: $reasonForReport
    ) {
      ...issue
    }
  }
`);

export function useTierIIReportReviewStepInputValidation() {
  const [query] = useLazyQuery(TIER_II_REPORT_REVIEW_STEP_INPUT_VALIDATION);

  const { data: reportDetailsData } = useReport();

  return useCallback(
    async (reasonForReport: string) => {
      const { data } = await query({
        variables: {
          reportId: reportDetailsData?.tierIIReport.id ?? "",
          facilityId: reportDetailsData?.tierIIReport.facility.id ?? "",
          reasonForReport: reasonForReport,
        },
        fetchPolicy: "network-only",
      });

      return [...(data?.tierIIReportReviewStepValidator ?? [])];
    },
    [
      query,
      reportDetailsData?.tierIIReport.facility.id,
      reportDetailsData?.tierIIReport.id,
    ]
  );
}
