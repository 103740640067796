import { useQuery } from "@apollo/client";
import { CircularProgress, TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { gql } from "generated-graphql";
import {
  ChemicalPickerFragment,
  ChemicalsQueryQuery,
} from "generated-graphql/graphql";
import { useDebounce } from "hooks/useDebounce";
import React, { forwardRef, useState } from "react";
import { ControllerFieldState } from "react-hook-form";
import { formatChemicalName } from "util/chemicalName";
import { ErrorDisplay } from "./Forms/ErrorDisplay";
import { FormTextField } from "./Forms/FormTextField";

gql(`
  fragment ChemicalPicker on Chemical {
    id
    name
    pureOrMixture
    stateOfMatter
    casNumber
    isEhs
    stateFields {
      key
      value
      jurisdiction
    }
    components {
      name
      casNumber
      componentPercentage
      isEhs
    }
  }
`);

type Chemical = ChemicalsQueryQuery["chemicals"]["items"][number];

const CHEMICALS_QUERY = gql(`
  query ChemicalsQuery($search: String, $page: Int, $pageSize: Int, $sort: [SortModel!], $facilityId: ID, $reportingYear: Int) {
    chemicals(search: $search, page: $page, pageSize: $pageSize, sort: $sort) {
      items {
        ...ChemicalPicker
        facilityChemicals(facilityId: $facilityId, reportingYear: $reportingYear) {
          id
        }
      }
      count
    }
  }
`);

interface ChemicalPickerProps extends ControllerFieldState {
  onChange: (chemical: Chemical | null) => void;
  defaultSearchTerm?: string;
  disabledChemicalsById?: string[];
  disabledOptionLabel?: string;
  value?: ChemicalPickerFragment | null;
  disabled?: boolean;
  required?: boolean;
  readOnly?: boolean;
  isOptionDisabled?: (chemical: Chemical) => boolean;
  facilityId?: string;
  reportingYear?: number;
}

export const ChemicalPicker: React.FC<ChemicalPickerProps> = forwardRef(
  function ChemicalPicker(
    {
      onChange,
      defaultSearchTerm = "",
      disabledChemicalsById = [],
      disabledOptionLabel = "",
      value: initialValue,
      error,
      disabled,
      required,
      readOnly,
      isOptionDisabled,
      facilityId,
      reportingYear,
    },
    ref
  ) {
    const [value, setValue] = useState(initialValue ?? null);
    const [search, setSearch] = useState(initialValue?.name ?? "");
    const [searchIsOpen, setSearchIsOpen] = useState(false);
    const [debouncedSearch] = useDebounce(
      `${defaultSearchTerm} ${search}`,
      150
    );

    const { data, loading, previousData } = useQuery(CHEMICALS_QUERY, {
      variables: {
        search: debouncedSearch,
        page: 0,
        pageSize: 25,
        facilityId,
        reportingYear,
        sort: [{ field: "name", sort: "asc" }],
      },
      skip: !searchIsOpen,
      fetchPolicy: "network-only",
    });

    const componentLabel = "Chemical Name";

    return (
      <>
        {readOnly ? (
          <FormTextField
            label={componentLabel}
            readOnly
            defaultValue={value ? formatChemicalName(value) : ""}
          />
        ) : (
          <Autocomplete<Chemical>
            ref={ref}
            options={
              data?.chemicals.items ?? previousData?.chemicals.items ?? []
            }
            isOptionEqualToValue={(o, v) => o.id === v.id}
            noOptionsText="Search for a chemical in your catalog"
            onOpen={() => setSearchIsOpen(true)}
            onClose={() => setSearchIsOpen(false)}
            inputValue={search}
            value={value}
            loading={loading}
            disabled={disabled}
            onInputChange={(_, val) => {
              setSearch(val);
            }}
            onChange={(_, val) => {
              setValue(val);
              onChange(val);
            }}
            getOptionLabel={(option: Chemical) => formatChemicalName(option)}
            getOptionDisabled={(option: Chemical) =>
              isOptionDisabled?.(option) ||
              disabledChemicalsById.includes(option?.id ?? "")
            }
            renderOption={(props, option) => (
              <li {...props}>
                {formatChemicalName(option)}
                {disabledChemicalsById.includes(option?.id ?? "") && (
                  <span>&nbsp;({disabledOptionLabel})</span>
                )}
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                error={!!error}
                label={componentLabel}
                variant="outlined"
                required={required}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {loading ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
          />
        )}
        <ErrorDisplay error={error} />
      </>
    );
  }
);
