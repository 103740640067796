import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { COMMUNICATION_QUERY } from "./api";
import { CreateCommunications } from "./CreateCommunications";
import { useBreadcrumb } from "hooks/useBreadcrumbs";
import { useMemo } from "react";
import { formatCommunicationType } from "./utils";

export const CommunicationDetails: React.FC = () => {
  const { communicationId } = useParams<{ communicationId: string }>();
  const { data, loading, error } = useQuery(COMMUNICATION_QUERY, {
    variables: { id: communicationId ?? "" },
    skip: !communicationId,
  });

  const { communicationType, recipientName, completedAt } =
    data?.communication ?? {};
  const formattedCommunicationType = formatCommunicationType(communicationType);
  const formattedDate = completedAt
    ? new Date(completedAt).toLocaleDateString()
    : "";

  const breadCrumb = useMemo(() => {
    if (!recipientName) {
      return "Communication Details";
    }
    let breadCrumb = `${formattedCommunicationType} to ${recipientName}`;
    if (formattedDate) {
      breadCrumb += `, ${formattedDate}`;
    }
    return breadCrumb;
  }, [recipientName, formattedCommunicationType, formattedDate]);

  useBreadcrumb({
    label: breadCrumb ?? "Communication Details",
  });

  if (loading) return null;
  if (error) return null;

  const communication = data?.communication;
  if (!communication) return null;

  return <CreateCommunications />;
};
