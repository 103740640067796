import {
  ApolloCache,
  DefaultContext,
  MutationUpdaterFunction,
  useMutation,
} from "@apollo/client";
import { Chip, Grid, Paper, Stack, Typography, useTheme } from "@mui/material";
import TierIIReportTagPicker from "components/TierIIReportTagPicker";
import { gql } from "generated-graphql";
import { Exact, UpdateReportTagsMutation } from "generated-graphql/graphql";
import { sortBy, uniq } from "lodash";
import { useCallback } from "react";
import { useReportDetails } from "./useReportDetails";

const UPDATE_REPORT_TAGS = gql(`
  mutation UpdateReportTags( $tierIIReportId: ID!, $tags: [String!]!) {
  updateTierIIReportTags(tierIIReportId: $tierIIReportId, tags: $tags) {
    id
    tags
  }
}`);

export function Tags() {
  const theme = useTheme();
  const { data } = useReportDetails();

  const [mutate, { loading }] = useMutation(UPDATE_REPORT_TAGS, {
    refetchQueries: ["GetTierIIReportTags"],
  });

  const reportTags = sortBy(data?.tierIIReport.tags);

  if (!data?.tierIIReport.id) {
    return <></>;
  }

  const updateTierIIReportActivityCache: MutationUpdaterFunction<
    UpdateReportTagsMutation,
    Exact<{
      tierIIReportId: string;
      tags: string[] | string; // This might be a bug in the generated types
    }>,
    DefaultContext,
    ApolloCache<any>
  > = (cache, { data: mutationResponse }) => {
    cache.modify({
      id: cache.identify({
        __typename: "TierIIReport",
        id: mutationResponse?.updateTierIIReportTags.id,
      }),
      fields: {
        tags: () => mutationResponse?.updateTierIIReportTags.tags ?? [],
      },
    });
  };

  const onAddTag = useCallback(
    (tag: string) => {
      const updatedTags = uniq([tag, ...(reportTags ?? [])]);
      mutate({
        variables: {
          tierIIReportId: data?.tierIIReport.id,
          tags: updatedTags,
        },
        update: updateTierIIReportActivityCache,
      });
    },
    [data?.tierIIReport.id, mutate, reportTags]
  );

  const handleDelete = useCallback(
    (tag: string) => {
      const updatedTags = reportTags.filter((t) => t !== tag);
      mutate({
        variables: {
          tierIIReportId: data?.tierIIReport.id,
          tags: updatedTags,
        },
        update: updateTierIIReportActivityCache,
      });
    },
    [data?.tierIIReport.id, mutate, reportTags]
  );

  return (
    <Stack
      direction={"column"}
      component={Paper}
      sx={{ border: `1px solid ${theme.palette.divider}`, padding: 1 }}
    >
      <Typography variant="h6">Tags</Typography>
      <Grid container spacing={2} paddingLeft={2}>
        <Grid item xs={12} md={6} marginY={theme.spacing(1)}>
          <TierIIReportTagPicker onAddTag={onAddTag} disabled={loading} />
        </Grid>
        <Grid item xs={12} md={6} marginY={theme.spacing(1)}>
          {reportTags.map((tag) => (
            <Chip
              key={tag}
              label={tag}
              onDelete={() => handleDelete(tag)}
              disabled={loading}
              clickable={false}
              sx={{
                marginRight: theme.spacing(1),
                marginBottom: theme.spacing(1),
              }}
            />
          ))}
        </Grid>
      </Grid>
    </Stack>
  );
}
