import { Box, Paper, Typography } from "@mui/material";
import { TruncateTypography } from "components/TruncateTypography";
import { getAmountCodes } from "encamp-shared/src/constants/storageAmounts";
import { prettyPrintQuantity } from "encamp-shared/src/utils/prettyPrintUnits";
import { ReportingFacilityChemicalQuery } from "generated-graphql/graphql";
import { useProducts } from "hooks/useProducts";

export const SummaryCard = ({
  rfc,
}: {
  rfc: ReportingFacilityChemicalQuery["reportingFacilityChemical"];
}) => {
  const { hasProducts } = useProducts();
  const maxWidthAmountBoxes = hasProducts ? "20%" : "30%";
  const maxWidthSummaryBoxes = hasProducts ? "27%" : "30%";
  return (
    <Paper
      variant="outlined"
      sx={{ p: 3, display: "flex", justifyContent: "space-between", gap: 2 }}
    >
      <Box maxWidth={maxWidthAmountBoxes}>
        <Typography variant="body2">Max Daily Amount</Typography>
        <TruncateTypography variant="h6" showTooltip>
          {prettyPrintQuantity({
            amount: rfc.maxAmount,
            unit: rfc.unit,
            state: rfc.facility?.state,
          })}
        </TruncateTypography>
        <TruncateTypography variant="body2" lines={1} showTooltip>
          {rfc.maxAmountCode && rfc.facility?.state
            ? getAmountCodes(rfc.facility.state)[rfc.maxAmountCode]
            : undefined}
        </TruncateTypography>
      </Box>
      <Box flex={1} />
      <Box maxWidth={maxWidthAmountBoxes}>
        <Typography variant="body2">Avg Daily Amount</Typography>
        <TruncateTypography variant="h6" showTooltip>
          {prettyPrintQuantity({
            amount: rfc.averageAmount,
            unit: rfc.unit,
            state: rfc.facility?.state,
          })}
        </TruncateTypography>
        <TruncateTypography variant="body2" showTooltip>
          {rfc.averageAmountCode && rfc.facility?.state
            ? getAmountCodes(rfc.facility.state)[rfc.averageAmountCode]
            : undefined}
        </TruncateTypography>
      </Box>

      <Box flex={1} />
      {hasProducts && (
        <>
          <Box
            display="flex"
            flexDirection="column"
            gap={1}
            maxWidth={maxWidthSummaryBoxes}
          >
            <LineItem
              label="Products"
              value={
                rfc.maxAmountProductChemicalBreakdown?.productMaxAmount
                  ? prettyPrintQuantity({
                      amount:
                        rfc.maxAmountProductChemicalBreakdown.productMaxAmount
                          .amount,
                      unit: rfc.maxAmountProductChemicalBreakdown
                        .productMaxAmount.unit,
                      state: rfc.facility?.state,
                    })
                  : prettyPrintQuantity({
                      amount: 0,
                      unit: rfc.unit,
                      state: rfc.facility?.state,
                    })
              }
              valueColumnAlign="left"
            />
            <LineItem
              label="Other Chemicals"
              value={
                rfc.maxAmountProductChemicalBreakdown?.chemicalMaxAmount
                  ? prettyPrintQuantity({
                      amount:
                        rfc.maxAmountProductChemicalBreakdown.chemicalMaxAmount
                          .amount,
                      unit: rfc.maxAmountProductChemicalBreakdown
                        .chemicalMaxAmount.unit,
                      state: rfc.facility?.state,
                    })
                  : prettyPrintQuantity({
                      amount: 0,
                      unit: rfc.unit,
                      state: rfc.facility?.state,
                    })
              }
              valueColumnAlign="left"
            />
            <LineItem
              label="Total"
              value={prettyPrintQuantity({
                amount: rfc.maxAmount,
                unit: rfc.unit,
                state: rfc.facility?.state,
              })}
              bold
              valueColumnAlign="left"
            />
          </Box>
          <Box flex={1} />
        </>
      )}
      <Box
        display="flex"
        flexDirection="column"
        gap={1}
        maxWidth={maxWidthSummaryBoxes}
      >
        <LineItem
          label="Largest Container"
          value={prettyPrintQuantity({
            amount: rfc.maxAmountLargestContainer,
            unit: rfc.unit,
            state: rfc.facility?.state,
          })}
          valueColumnAlign="right"
        />
        <LineItem
          label="Days on Site"
          value={`${rfc.daysOnSite} days`}
          valueColumnAlign="right"
        />
        <LineItem
          label={`Last Year (${rfc.reportingYear - 1})`}
          value={
            rfc.previousYearMaxAmount
              ? prettyPrintQuantity({
                  amount: rfc.previousYearMaxAmount.amount,
                  unit: rfc.previousYearMaxAmount.unit,
                  state: rfc.facility?.state,
                })
              : "N/A"
          }
          valueColumnAlign="right"
        />
      </Box>
    </Paper>
  );
};

const LineItem: React.FC<{
  label: string;
  value: string | number | undefined;
  bold?: boolean;
  valueColumnAlign?: "left" | "right";
}> = ({ label, value, bold }) => {
  const fontWeight = bold ? "bold" : undefined;
  return (
    <Box display="flex" alignItems="baseline" flexWrap="nowrap">
      <Typography fontWeight={fontWeight} variant="body2" noWrap flexShrink={0}>
        {label}
      </Typography>
      <Box
        sx={{
          minWidth: "1rem",
          flex: 1,
          borderBottom: value ? "1px dotted gray" : undefined,
          mx: 0.5,
        }}
      />
      <TruncateTypography fontWeight={fontWeight} variant="body2" showTooltip>
        {value}
      </TruncateTypography>
    </Box>
  );
};
