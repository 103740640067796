import { useCallback, useMemo, useState } from "react";
import { FormProvider, useForm, Controller } from "react-hook-form";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
  DialogActions,
  FormLabel,
} from "@mui/material";
import { currentTierIIReportingYear } from "encamp-shared/src/constants/tierii";
import { TierIIYearPicker } from "components/TierIIYearPicker";
import { RadioGroupField } from "components/Forms/RadioGroupField";
import {
  LongPollingJobWrapper,
  PollingResult,
} from "components/LongPollingJobWrapper";
import { JobStatus } from "encamp-shared/src/generated-graphql/oak-resolver-types";
import { useAlerts } from "components/Alerts/AlertProvider";
import { useMutation } from "@apollo/client";
import { gql } from "generated-graphql";
import { useCheckForFile } from "hooks/useCheckForFile";
import { LoadingButton } from "@mui/lab";

type GenerateDeliverablesDialogProps = {
  tenantId: string;
  open: boolean;
  onClose: () => void;
};

type FormValues = {
  allReportingYears: boolean;
  reportingYear?: number;
};

export function DownloadTierIIDeliverablesDialog({
  open,
  onClose,
  tenantId,
}: GenerateDeliverablesDialogProps) {
  const alerts = useAlerts();
  const [jobId, setJobId] = useState<string | undefined>(undefined);
  const [loading, setLoading] = useState(false);
  const [generateDeliverables] = useMutation(
    gql(`
    mutation DownloadTierIIDeliverables($tenantId: ID!, $reportingYear: Int) {
      downloadTierIIDeliverables(tenantId: $tenantId, reportingYear: $reportingYear) {
        jobId
      }
    }
  `)
  );

  const { fetchFile } = useCheckForFile();

  const defaultValues: FormValues = useMemo(() => {
    return {
      allReportingYears: true,
      reportingYear: currentTierIIReportingYear,
    };
  }, []);

  const form = useForm<FormValues>({ defaultValues });

  const allReportingYears = form.watch("allReportingYears");

  const handleSubmit = async (formData: FormValues) => {
    setLoading(true);
    const { data } = await generateDeliverables({
      variables: {
        tenantId,
        reportingYear: formData.allReportingYears
          ? undefined
          : formData.reportingYear,
      },
    });

    if (data?.downloadTierIIDeliverables.jobId) {
      setJobId(data.downloadTierIIDeliverables.jobId);
    }
  };

  const pollingCompleted = useCallback(
    async (result: PollingResult) => {
      const { job, pollingTimedOut } = result;
      try {
        if (pollingTimedOut) {
          throw new Error("Polling for PDF generation job status timed out");
        }

        if (job?.status.includes("FAILED")) {
          throw new Error("Failed to generate deliverables");
        }

        if (job?.status === JobStatus.Succeeded) {
          if (job.output.s3Keys && job.output.s3Keys.length) {
            for (const key of job.output.s3Keys) {
              const signedUrl = await fetchFile(key);
              if (signedUrl) {
                window.open(signedUrl, "_blank");
              } else {
                throw new Error("Failed to generate deliverables");
              }
            }
          } else {
            // @DEPRECATED: Remove this once we have fully transitioned to outputs containing
            // an array of S3 keys
            const signedUrl = await fetchFile(job.output.s3Key);

            if (signedUrl) {
              window.open(signedUrl, "_blank");
            } else {
              throw new Error("Failed to generate deliverables");
            }
          }
        }
      } catch (error) {
        if (error instanceof Error) {
          alerts.error(error.message);
        } else {
          alerts.error("An unknown error occurred");
        }
      } finally {
        onClose();
        setLoading(false);
      }
    },
    [alerts, onClose, fetchFile]
  );

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Download Deliverables</DialogTitle>
      <DialogContent>
        <FormProvider {...form}>
          <RadioGroupField
            label=""
            name="allReportingYears"
            radioOptions={[
              { label: "For all reporting years", value: true },
              { label: "For a specific reporting year", value: false },
            ]}
          />
          {!allReportingYears && (
            <Controller
              name="reportingYear"
              control={form.control}
              render={({ field }) => (
                <Stack direction="row" alignItems="center" spacing={2}>
                  <FormLabel>Please select a reporting year</FormLabel>
                  <TierIIYearPicker
                    selectProps={{
                      size: "small",
                      value: field.value,
                      onChange: (e) => field.onChange(Number(e.target.value)),
                    }}
                  />
                </Stack>
              )}
            />
          )}
        </FormProvider>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outlined">
          Cancel
        </Button>
        <LongPollingJobWrapper
          jobId={jobId}
          pollingCompleted={pollingCompleted}
          timeoutInSeconds={1200}
        >
          <LoadingButton
            onClick={form.handleSubmit(handleSubmit)}
            variant="contained"
            loading={loading}
          >
            Generate
          </LoadingButton>
        </LongPollingJobWrapper>
      </DialogActions>
    </Dialog>
  );
}
