import { gql } from "generated-graphql";
import {
  FireDepartmentPickerFragment,
  FireDepartmentsQuery,
} from "generated-graphql/graphql";
import React, { forwardRef } from "react";
import { ControllerFieldState } from "react-hook-form";
import EncampAutocomplete from "./Forms/EncampAutocomplete";

// Base props shared between both picker variants
type BaseFireDepartmentPickerProps = {
  onChange?: (value: FireDepartmentPickerFragment | null) => void;
  value?: FireDepartmentPickerFragment | null;
  disabled?: boolean;
} & ControllerFieldState;

// Props specific to state/county based picker
type StateCountyFireDepartmentPickerProps = BaseFireDepartmentPickerProps & {
  facilityState?: string;
  facilityCounty?: string;
};

// Props specific to facility based picker
type FacilityFireDepartmentPickerProps = BaseFireDepartmentPickerProps & {
  facilityIds: string[];
};

// GraphQL fragments and queries
const FIRE_DEPARTMENT_PICKER_FRAGMENT = gql(`
  fragment FireDepartmentPicker on FireDepartment {
    id
    name
    city
    state
    mailingAddress
    mailingAddressLine2
    zip
    county
    phone
    email
    isMailed
    isEmailed
    preferUncertified
    firstName
    lastName
  }
`);

const FIRE_DEPARTMENTS_BY_STATE_COUNTY_QUERY = gql(`
  query FireDepartments($state: String, $county: String) {
    fireDepartments(state: $state, county: $county) {
      items {
        ...FireDepartmentPicker
      }
      count
    }
  }
`);

const FIRE_DEPARTMENTS_SEARCH_BY_FACILITY_QUERY = gql(`
  query FireDepartmentSearchForPicker($search: String, $page: Int, $pageSize: Int, $sort: [SortModel!]) {
    fireDepartmentSearch(search: $search, page: $page, pageSize: $pageSize, sort: $sort) {
      items {
        ...FireDepartmentPicker
      }
      count
    }
  }
`);

// Shared function to format Fire Department option labels
const getFireDepartmentOptionLabel = (
  fireDepartment: FireDepartmentPickerFragment
) => {
  let label = `${fireDepartment.name} ${fireDepartment.city}, ${fireDepartment.state}`;
  if (fireDepartment.county) {
    label = `${fireDepartment.name} ${fireDepartment.city}, ${fireDepartment.county}, ${fireDepartment.state}`;
  }
  return label;
};

// State/County based picker
export const FireDepartmentPicker = forwardRef<
  HTMLDivElement,
  StateCountyFireDepartmentPickerProps
>(function FireDepartmentPicker(
  { onChange, value, facilityState, facilityCounty, error, disabled },
  ref
) {
  return (
    <EncampAutocomplete
      query={FIRE_DEPARTMENTS_BY_STATE_COUNTY_QUERY}
      variables={{
        state: facilityState,
        county: facilityCounty,
      }}
      getItems={(data) => data.fireDepartments?.items ?? []}
      getOptionLabel={getFireDepartmentOptionLabel}
      onChange={onChange}
      value={value}
      error={error}
      disabled={disabled}
    />
  );
});

// Facility based picker
export const FireDepartmentPickerWithFacilityFilter = forwardRef<
  HTMLDivElement,
  FacilityFireDepartmentPickerProps
>(function FireDepartmentPickerWithFacilityFilter(
  { facilityIds, onChange, value, error, disabled },
  ref
) {
  return (
    <EncampAutocomplete
      query={FIRE_DEPARTMENTS_SEARCH_BY_FACILITY_QUERY}
      variables={{
        search: `facilityId:${facilityIds.join("|")}`,
      }}
      getItems={(data) => data.fireDepartmentSearch?.items ?? []}
      getOptionLabel={getFireDepartmentOptionLabel}
      onChange={onChange}
      value={value}
      error={error}
      disabled={disabled}
    />
  );
});
