import { Stack, Typography } from "@mui/material";
import { DataGrid } from "components/DataGrid";
import { IssueCount } from "components/IssueCount";
import { prettyPrintQuantity } from "encamp-shared/src/utils/prettyPrintUnits";
import { ReportingFacilityChemicalQuery } from "generated-graphql/graphql";
import { useMeasurements } from "hooks/useMeasurements";
import { useProducts } from "hooks/useProducts";
import { maxBy } from "lodash";
import { NoRowsOverlay } from "../Inventory/Facility/NoRowsOverlay";

export const ProductsTable = ({
  rfc,
}: {
  rfc: ReportingFacilityChemicalQuery["reportingFacilityChemical"];
}) => {
  const { hasProducts } = useProducts();
  const { hasMeasurements } = useMeasurements(rfc.facilityId);

  if (!hasProducts) return null;

  const grouped =
    rfc.origin?.facilityProductMeasurements?.reduce(
      (acc, measurement) => {
        const productId = measurement.product?.id ?? "";
        if (!acc[productId]) {
          acc[productId] = { ...measurement.product, measurements: [] };
        }
        acc[productId].measurements =
          acc[productId].measurements?.concat(measurement);
        return acc;
      },
      {} as Record<
        string,
        Partial<
          NonNullable<
            NonNullable<
              ReportingFacilityChemicalQuery["reportingFacilityChemical"]["origin"]
            >["facilityProductMeasurements"]
          >[number]["product"]
        > & {
          measurements: NonNullable<
            ReportingFacilityChemicalQuery["reportingFacilityChemical"]["origin"]
          >["facilityProductMeasurements"];
        }
      >
    ) ?? [];

  return (
    <Stack spacing={1}>
      <Typography variant="h6">Contributions from Products</Typography>

      {hasMeasurements ? (
        <DataGrid
          pagination
          pageSizeOptions={[5]}
          disableRowSelectionOnClick
          initialState={{
            pagination: { paginationModel: { pageSize: 5 } },
          }}
          slots={{
            noRowsOverlay: () => (
              <NoRowsOverlay message="No product measurements found." />
            ),
          }}
          rows={Object.values(grouped)}
          columns={[
            {
              field: "product",
              headerName: "Product",
              flex: 1,
              valueGetter(params) {
                return params.row.name;
              },
            },
            {
              field: "measurements",
              headerName: "Measurements",
              flex: 1,
              valueGetter(params) {
                return params.row.measurements?.length;
              },
            },
            {
              field: "maxAmount",
              headerName: "Max Amount",
              flex: 1,
              valueGetter(params) {
                const maxMeasurement = maxBy(
                  params.row.measurements,
                  (m) => m.quantity
                );
                const productChemical = params.row.productChemicals?.find(
                  (pc) => pc.chemicalId === rfc.chemicalId
                );
                const maxAmount =
                  maxMeasurement && productChemical
                    ? maxMeasurement.quantity * productChemical.amount
                    : undefined;
                return prettyPrintQuantity({
                  amount: maxAmount,
                  unit: rfc.unit,
                });
              },
            },
            {
              field: "issues",
              headerName: "Issues",
              flex: 1,
              renderCell(params) {
                const issues = rfc.issues.filter(
                  (issue) =>
                    issue.modelName === "FacilityProductMeasurement" &&
                    issue.modelId === params.row.id
                );
                return <IssueCount issueCount={issues.length} />;
              },
            },
          ]}
        />
      ) : (
        <DataGrid
          pagination
          pageSizeOptions={[5]}
          disableRowSelectionOnClick
          initialState={{
            pagination: { paginationModel: { pageSize: 5 } },
          }}
          slots={{
            noRowsOverlay: () => <NoRowsOverlay message="No products found." />,
          }}
          rows={rfc.origin?.facilityProducts ?? []}
          columns={[
            {
              field: "product",
              headerName: "Product",
              flex: 1,
              valueGetter(params) {
                return params.row.product?.name;
              },
            },
            {
              field: "maxAmount",
              headerName: "Max Amount",
              flex: 1,
              valueGetter(params) {
                const maxAmountOfChemical = params.row.maxQuantity
                  ? params.row.maxQuantity *
                    (params.row.product?.productChemicals?.find(
                      (pc) => pc.chemicalId === rfc.chemicalId
                    )?.amount || 0)
                  : undefined;
                return prettyPrintQuantity({
                  amount: maxAmountOfChemical,
                  unit: rfc.unit,
                });
              },
            },
            {
              field: "avgAmount",
              headerName: "Avg Amount",
              flex: 1,
              valueGetter(params) {
                const avgAmountOfChemical = params.row.averageQuantity
                  ? params.row.averageQuantity *
                    (params.row.product?.productChemicals?.find(
                      (pc) => pc.chemicalId === rfc.chemicalId
                    )?.amount || 0)
                  : undefined;
                return prettyPrintQuantity({
                  amount: avgAmountOfChemical,
                  unit: rfc.unit,
                });
              },
            },
            {
              field: "issues",
              headerName: "Issues",
              flex: 1,
              renderCell(params) {
                const issues = rfc.issues.filter(
                  (issue) =>
                    issue.modelName === "FacilityProduct" &&
                    issue.modelId === params.row.id
                );
                return <IssueCount issueCount={issues.length} />;
              },
            },
          ]}
        />
      )}
    </Stack>
  );
};
