import { useMutation, useQuery } from "@apollo/client";
import { GetTenantQuery } from "generated-graphql/graphql";
import { isNil } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { gql } from "../generated-graphql";
import { useCurrentUser } from "./useCurrentUser";
import useSessionStorageState from "use-session-storage-state";

gql(`
  fragment tenant on Tenant {
    id
    name
    isDisabled
    isDemo
    isPartner
    partnerTenantId
  }
`);

const GET_TENANT_QUERY = gql(`
  query GetTenant($tenantId: ID!) {
    tenant(id: $tenantId) {
      ...tenant
      featureFlags
    }
  }
`);

export type Tenant = GetTenantQuery["tenant"] | undefined;

export function useTenant() {
  const { tenantId } = useParams<{ tenantId: string }>();
  const { user } = useCurrentUser();

  const [savedTenant, setSavedTenant] = useSessionStorageState<Tenant>(
    user ? `${user.id}-savedTenant` : ""
  );

  const [internalTenantId, setInternalTenantId] = useState<string | undefined>(
    tenantId ?? savedTenant?.id
  );

  useEffect(() => {
    setInternalTenantId(tenantId ?? savedTenant?.id);
  }, [savedTenant?.id, tenantId]);

  const { data, loading, error } = useQuery(GET_TENANT_QUERY, {
    variables: {
      tenantId: internalTenantId ?? "",
    },
    skip: isNil(internalTenantId),
    onCompleted(data) {
      setSavedTenant(data.tenant);
    },
  });

  const setCurrentTenantId = useCallback(
    async (newTenantId: string | null | undefined) => {
      if (newTenantId && newTenantId !== internalTenantId) {
        setInternalTenantId(newTenantId);
      }
    },
    [internalTenantId]
  );

  const tenant = useMemo(() => {
    return data?.tenant ?? savedTenant;
  }, [data?.tenant, savedTenant]);

  const isAmazon = internalTenantId === "6a92e397-bb74-44ed-afee-f84c7821f40e";

  return {
    tenantId: internalTenantId,
    isAmazon,
    tenant: tenant?.id === internalTenantId ? tenant : undefined,
    loading,
    error,
    setCurrentTenantId,
  };
}
