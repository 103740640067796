import { useMutation } from "@apollo/client";
import CheckCircleOutline from "@mui/icons-material/CheckCircleOutline";
import { Alert, Box, CircularProgress, Grid, useTheme } from "@mui/material";
import { useAlerts } from "components/Alerts/AlertProvider";
import { FormTextField } from "components/Forms/FormTextField";
import { gql } from "generated-graphql";
import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useParams } from "react-router-dom";

export const UPDATE_REASON_FOR_REPORT = gql(`
  mutation UpdateReasonForReport($reportId: ID!, $reason: String) {
    updateReasonForReport(id: $reportId, reason: $reason) {
      id
      organizationStatus
      reasonForReport
      touchedSteps
      assignees {
        id
        isComplete
      }
      issues {
        ...issue
      }
    }
  }
`);

type FormState = {
  reasonForReport?: string | null;
};

export function ReasonForRevisionForm() {
  const theme = useTheme();
  const alerts = useAlerts();
  const { reportId = "" } = useParams<{ reportId: string }>();
  const [saveSuccessful, setSaveSuccessful] = useState(false);

  const [updateReasonForReport, { loading: updatingReason }] = useMutation(
    UPDATE_REASON_FOR_REPORT
  );

  const {
    control,
    handleSubmit,
    formState: { isSubmitSuccessful },
  } = useFormContext<FormState>();

  const onReasonUpdated = handleSubmit(async (data) => {
    await updateReasonForReport({
      variables: {
        reportId,
        reason: data.reasonForReport,
      },
    });
    setSaveSuccessful(true);
    setTimeout(() => setSaveSuccessful(false), 3000);
  });

  useEffect(() => {
    if (!isSubmitSuccessful) return;
    alerts.success("Successfully updated Reason for Revision");
  }, [alerts, isSubmitSuccessful]);

  return (
    <>
      <Grid
        container
        spacing={1}
        marginTop={theme.spacing(3)}
        sx={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          height: "100%",
        }}
      >
        <Grid item xs={12}>
          <Alert severity="info" sx={{ mt: -2, mb: 1 }}>
            Enter the reason for this revision. This will be submitted along
            with the revision in the state's portal.
          </Alert>
          <FormTextField
            name="reasonForReport"
            fullWidth
            label={
              <Box sx={{ display: "flex", alignItems: "center" }}>
                Reason for Revision
                {updatingReason ? (
                  <CircularProgress size={16} style={{ marginLeft: 10 }} />
                ) : null}
                {saveSuccessful && !updatingReason ? (
                  <CheckCircleOutline
                    color="success"
                    fontSize="small"
                    style={{ marginLeft: 10 }}
                  />
                ) : null}
              </Box>
            }
            control={control}
            onBlur={onReasonUpdated}
            textFieldProps={{
              minRows: 6,
              multiline: true,
              variant: "outlined",
            }}
          />
        </Grid>
      </Grid>
    </>
  );
}
