import { useQuery } from "@apollo/client";
import Check from "@mui/icons-material/Check";
import Edit from "@mui/icons-material/Edit";
import ErrorOutline from "@mui/icons-material/ErrorOutline";
import {
  Box,
  Button,
  FormControlLabel,
  IconButton,
  Paper,
  Stack,
  Switch,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { GridRowSelectionModel } from "@mui/x-data-grid-premium";
import { useAlerts } from "components/Alerts/AlertProvider";
import { IssueCount } from "components/IssueCount";
import { TierIIReportEncampStatus } from "encamp-shared/src/generated-graphql";
import { prettyPrintEnumValue } from "encamp-shared/src/utils/prettyPrintEnumValue";
import { useCurrentUser } from "hooks/useCurrentUser";
import { useOmnisearchDatagrid } from "hooks/useOmnisearchDatagridSettings";
import { compact, sortBy } from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDebounce } from "use-debounce";
import { encampStatusToLabel, orgStatusToLabel } from "util/constants";
import { DataGrid } from "../../../../components/DataGrid";
import { Omnisearch } from "../../../../components/Omnisearch/OmnisearchV2";
import {
  ExecutionPlanAutomated,
  ExecutionPlanManual,
} from "../../../../components/icons";
import { gql } from "../../../../generated-graphql";
import {
  ActivityStatus,
  ActivityType,
  ExecutionPlanType,
  TierIiReportActivitiesQuery,
  TierIiReportEncampStatus,
  TierIiReportKind,
  TierIiReportOrgStatus,
} from "../../../../generated-graphql/graphql";
import { BulkActionsMenu } from "./BulkActionsMenu";
import { ExportGridToCsvButton } from "./ExportGridToCsvButton";
import { AssignmentManagementTableFilterKeys } from "./useAssignmentManagerOmnisearch";

export type Row =
  TierIiReportActivitiesQuery["tierIIReportActivities"]["items"][0];

const REPORT_ACTIVITIES_QUERY = gql(`
  query TierIIReportActivities($search: String, $page: Int, $pageSize: Int, $sort: [SortModel!]) {
    tierIIReportActivities(search: $search, page: $page, pageSize: $pageSize, sort: $sort) {
      items {
        id
        tenantId
        tierIIReportId
        reportingYear
        productCount
        organizationStatus
        encampStatus
        jobStatus
        reportKind
        state
        facilityName
        customerFacilityId
        facilityId
        organizationName
        workflowId
        activityId
        activityType
        activityDescription
        activityAssigneeFirst
        activityAssigneeLast
        activityAssigneeEmail
        activityExecutionPlan
        activityStatus
        activityErrorMessage
        hasIncompleteReviews
        tenantId
        isChampionReport
        partnerTenant
        issueCount
        tags
      }
      count
    }
  }
`);

export function AssignmentManagementTable() {
  const currentUser = useCurrentUser();
  const currentUserEmail = currentUser.user?.email;
  const theme = useTheme();
  const navigate = useNavigate();
  const [rowSelectionModel, setRowSelectionModel] =
    useState<GridRowSelectionModel>([]);

  const {
    omnisearch,
    setOmnisearch,
    getOmnisearchValue,
    editOmnisearchKeyValues,
    removeOmnisearchKeyValue,
    paginationModel,
    setPaginationModel,
    sortModel,
    setSortModel,
    columnVisibilityModel,
    setColumnVisibilityModel,
    setColumnWidthModel,
    resetColumnSettings,
    persistGridSettings,
    columns,
    columnFilterKeys,
  } = useOmnisearchDatagrid({
    persistenceKey: "fulfillment",
    columns: [
      {
        field: "organizationName",
        headerName: "Organization",
        flex: 0.75,
      },
      {
        field: "facilityName",
        headerName: "Facility",
        filterKeyType: "facility",
        flex: 1.75,
        valueGetter: ({ row }) =>
          row.customerFacilityId
            ? `${row.facilityName} - ${row.customerFacilityId}`
            : row.facilityName,
      },
      {
        field: "state",
        headerName: "State",
        flex: 0.25,
      },
      {
        field: "reportKind",
        headerName: "Report Kind",
        filterKeyType: "enum",
        enumValues: Object.values(TierIiReportKind),
        flex: 0.25,
      },
      {
        field: "organizationStatus",
        headerName: "Organization Status",
        filterKeyType: "enum",
        enumValues: Object.values(TierIiReportOrgStatus),
        flex: 0.75,
        valueGetter: ({ row }) => {
          return orgStatusToLabel(
            row.organizationStatus as TierIiReportOrgStatus
          );
        },
      },
      {
        field: "encampStatus",
        headerName: "Encamp Status",
        filterKeyType: "enum",
        enumValues: Object.values(TierIiReportEncampStatus),
        flex: 0.75,
        valueGetter: ({ row }) => {
          return encampStatusToLabel(
            row.encampStatus as TierIiReportEncampStatus
          );
        },
      },
      {
        field: "hasReport",
        headerName: "Report?",
        enumValues: ["hasReport", "isChampionReport"],
        filterKeyType: "boolean",
        flex: 0.5,
        renderCell: ({ row }) => (row.tierIIReportId ? <Check /> : <></>),
      },
      {
        field: "executionPlan",
        filterKeyType: "enum",
        enumValues: Object.values(ExecutionPlanType),
        renderCell: (params) => {
          const executionPlan = params.row?.activityExecutionPlan;
          if (!executionPlan) {
            return "";
          }
          return executionPlan === ExecutionPlanType.Automation ? (
            <Tooltip title={executionPlan}>
              <ExecutionPlanAutomated />
            </Tooltip>
          ) : (
            <Tooltip title={executionPlan}>
              <ExecutionPlanManual />
            </Tooltip>
          );
        },
        align: "center",
        headerAlign: "center",
        headerName: "Plan",
        flex: 0.25,
      },
      {
        type: "number",
        field: "productCount",
        headerName: "Chemicals",
        align: "center",
        width: 100,
      },
      {
        field: "activityType",
        headerName: "Activity",
        filterKeyType: "enum",
        enumValues: Object.values(ActivityType),
        flex: 0.6,
      },
      {
        field: "activityDescription",
        headerName: "Activity Description",
        flex: 1,
      },
      {
        field: "activityAssigneeEmail",
        headerName: "Assignee",

        flex: 1,
      },
      {
        field: "isChampionReport",
        headerName: "Champ",
        renderCell(params) {
          return params.value && <Check />;
        },
      },
      {
        field: "issueCount",
        headerName: "Issues",
        renderCell(params) {
          return <IssueCount issueCount={params.value} />;
        },
        flex: 0.35,
      },
      {
        field: "partnerTenant",
        headerName: "Partner Tenant",
        flex: 1,
      },
      {
        field: "jobStatus",
        headerName: "Job Status",
        flex: 1,
        valueGetter: ({ row }) => prettyPrintEnumValue(row.jobStatus),
      },
      {
        field: "activityStatus",
        headerName: "Error",
        filterKeyType: "enum",
        enumValues: Object.values(ActivityStatus),
        width: 90,
        renderCell(params) {
          return params.row.activityStatus === ActivityStatus.Error ? (
            <ErrorOutline style={{ color: theme.palette.error.main }} />
          ) : (
            <></>
          );
        },
      },
      {
        field: "activityErrorMessage",
        headerName: "Recent Error",
      },
      {
        field: "actions",
        type: "actions",
        flex: 0.5,
        getActions: (params) => {
          const actions = [];
          if (params.row.tierIIReportId) {
            actions.push(
              <Tooltip title="View Submission" key={1}>
                <IconButton
                  href={`${window.location.origin}${location.pathname}/${params.row.tierIIReportId}`}
                >
                  <Edit />
                </IconButton>
              </Tooltip>
            );
          }
          return actions;
        },
      },
      {
        field: "tags",
        headerName: "Tags",
        valueGetter: ({ row }) => sortBy(row.tags),
      },
    ],
    additionalFilters: [
      {
        key: "!tags",
        header: "Not Tags",
        filterKeyType: "text",
      },
      {
        key: "cersId",
        header: "CERS ID",
        filterKeyType: "text",
      },
      {
        key: "lepc",
        header: "LEPC",
        filterKeyType: "text",
      },
      {
        key: "fd",
        header: "Fire Department",
        filterKeyType: "text",
      },
      {
        key: "county",
        header: "County",
        filterKeyType: "text",
      },
    ],
  });

  // scroll the data grid into view when the omnisearch filters change
  // but prevent it from scrolling into view when manually searching via omnisearch input or clicking reset button
  const dataGridRef = useRef<HTMLDivElement>(null);
  const omnisearchInputRef = useRef<HTMLInputElement>(null);
  const omnisearchResetButtonRef = useRef<HTMLButtonElement>(null);
  useEffect(() => {
    if (
      dataGridRef.current &&
      document.activeElement &&
      document.activeElement !== omnisearchInputRef.current &&
      document.activeElement !== omnisearchResetButtonRef.current
    ) {
      dataGridRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [omnisearch]);

  const [debouncedSearch] = useDebounce(omnisearch, 1000);

  const alerts = useAlerts();
  const [searchError, setSearchError] = useState(false);

  const {
    data,
    error: _error,
    loading,
    previousData,
  } = useQuery(REPORT_ACTIVITIES_QUERY, {
    variables: {
      search: debouncedSearch,
      page: paginationModel.page,
      pageSize: paginationModel.pageSize,
      sort: [...sortModel],
    },
    onError: (error) => {
      // Check for Prisma UUID errors or other invalid search syntax
      if (error.message.includes("Inconsistent column data")) {
        setSearchError(true);
        return; // Don't throw the error
      }
      // For other errors, throw them as before
      throw error;
    },
  });

  // Reset search error when search changes
  useEffect(() => {
    setSearchError(false);
  }, [debouncedSearch]);

  const location = useLocation();

  const [selectedRows, setSelectedRows] = useState<Row[]>([]);

  useEffect(() => {
    setSelectedRows(
      compact(
        rowSelectionModel.map((id) => {
          return data?.tierIIReportActivities.items.find(
            (item) => item.id === id
          );
        })
      )
    );
  }, [rowSelectionModel, data]);

  const viewReport = useCallback(
    (id: string, event?: React.MouseEvent) => {
      // Open in a new tab if Cmd (metaKey) or Ctrl is pressed
      if (id && (event?.metaKey || event?.ctrlKey)) {
        window.open(`fulfillment/${id}`, "_blank");
      } else {
        id && navigate(`${id}`);
      }
    },
    [navigate]
  );

  if (!currentUserEmail) return <></>;

  return (
    <Paper
      sx={{
        display: "flex",
        flexDirection: "column",
        padding: 3,
        position: "relative",
        border: `1px solid ${theme.palette.divider}`,
      }}
    >
      <Stack
        sx={{ flexDirection: { xs: "column", sm: "row" } }}
        spacing={2}
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography variant="h6">Reporting Facilities</Typography>
        <Button
          variant="outlined"
          onClick={() => {
            persistGridSettings();
            alerts.success(`Grid settings saved.`);
          }}
        >
          Save Default Grid Settings
        </Button>
      </Stack>

      <Stack
        sx={{
          marginBottom: "1rem",
          marginTop: "1rem",
          flexDirection: {
            xs: "column",
            sm: "row",
          },
        }}
        spacing={2}
        alignItems="center"
      >
        <Omnisearch
          columnFilterKeys={columnFilterKeys}
          omnisearch={omnisearch}
          setOmnisearch={setOmnisearch}
          sx={{ flex: { sm: 1, lg: 3 }, mr: 1 }}
          inputRef={omnisearchInputRef}
          resetRef={omnisearchResetButtonRef}
          errorMessage={searchError ? "Invalid search syntax" : undefined}
        />
        <Stack
          direction={"row"}
          justifyContent={"flex-end"}
          marginTop={0}
          sx={{ flex: { sm: 1, lg: 2 }, alignItems: "center" }}
        >
          <Button
            sx={{ mr: theme.spacing(2), pb: "2px" }}
            onClick={resetColumnSettings}
          >
            Reset Columns
          </Button>

          {!!currentUserEmail && (
            <FormControlLabel
              sx={{ whiteSpace: "nowrap" }}
              control={
                <Switch
                  checked={
                    getOmnisearchValue(
                      AssignmentManagementTableFilterKeys.Assignee
                    )?.includes(currentUserEmail) ?? false
                  }
                  onChange={(event) => {
                    if (event.target.checked) {
                      editOmnisearchKeyValues([
                        [
                          AssignmentManagementTableFilterKeys.Assignee,
                          currentUserEmail,
                        ],
                        [
                          AssignmentManagementTableFilterKeys.EncampStatus,
                          TierIIReportEncampStatus.PROCESSING,
                        ],
                      ]);
                    } else {
                      removeOmnisearchKeyValue(
                        AssignmentManagementTableFilterKeys.Assignee
                      );
                    }
                  }}
                />
              }
              label="Assigned to me"
            />
          )}
          <Box sx={{ marginRight: 1 }}>
            <ExportGridToCsvButton search={omnisearch} sort={[...sortModel]} />
          </Box>
          <BulkActionsMenu selectedRows={selectedRows} />
        </Stack>
      </Stack>
      <Box position="relative" sx={{ height: "80vh" }}>
        <DataGrid
          innerRef={dataGridRef}
          sx={{
            minHeight: "500px",
            opacity:
              loading && previousData
                ? theme.palette.action.disabledOpacity
                : 1,
          }}
          checkboxSelection
          columnBuffer={10}
          loading={loading}
          rowCount={data?.tierIIReportActivities.count ?? 0}
          pagination
          paginationMode="server"
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          sortingMode="server"
          sortModel={sortModel}
          onSortModelChange={setSortModel}
          rowSelectionModel={rowSelectionModel}
          disableRowSelectionOnClick
          onRowClick={({ row }, event) => {
            viewReport(row.tierIIReportId, event);
          }}
          onRowSelectionModelChange={(newModel) =>
            setRowSelectionModel(newModel)
          }
          columns={columns ?? []}
          initialState={{
            pinnedColumns: {
              right: ["actions"],
            },
          }}
          columnVisibilityModel={columnVisibilityModel}
          onColumnVisibilityModelChange={setColumnVisibilityModel}
          onColumnWidthChange={setColumnWidthModel}
          rows={
            data?.tierIIReportActivities.items ??
            previousData?.tierIIReportActivities.items ??
            []
          }
        />
      </Box>
    </Paper>
  );
}
