import { useQuery } from "@apollo/client";
import { useMemo } from "react";
import { gql } from "../generated-graphql";

const GET_TENANT_PARTNER = gql(`
  query GetTenantPartner($tenantId: ID!) {
    tenant(id: $tenantId) {
      id
      partnerTenantId
    }
  }
`);

export function usePartnerTenant(tenantId: string) {
  const { data } = useQuery(GET_TENANT_PARTNER, {
    variables: { tenantId },
  });

  const partnerTenantId = useMemo(
    () => data?.tenant.partnerTenantId ?? null,
    [data?.tenant.partnerTenantId]
  );

  return {
    partnerTenantId,
  };
}
