import { useMutation, useQuery } from "@apollo/client";
import Check from "@mui/icons-material/Check";
import Delete from "@mui/icons-material/Delete";
import Edit from "@mui/icons-material/Edit";
import { Button, Tooltip, Typography } from "@mui/material";
import { GridActionsCellItem } from "@mui/x-data-grid-premium";
import { useAlerts } from "components/Alerts/AlertProvider";
import { ConfirmDialog } from "components/ConfirmDialog";
import { IssueCount } from "components/IssueCount";
import { OmnisearchDataGrid } from "components/OmnisearchDataGrid";
import {
  TierIIYearPicker,
  useSelectedReportingYear,
} from "components/TierIIYearPicker";
import { gql } from "generated-graphql";
import { FacilityChemicalsQuery } from "generated-graphql/graphql";
import { OmnisearchGridColDef } from "hooks/useOmnisearchDatagridSettings";
import { useProducts } from "hooks/useProducts";
import { ReactNode, useCallback, useMemo, useState } from "react";
import { formatChemicalName } from "util/chemicalName";
import {
  prettyPrintAmountCode,
  prettyPrintQuantity,
} from "encamp-shared/src/utils/prettyPrintUnits";
import { isChemicalReporting } from "encamp-shared/src/tierii/isChemicalReporting";
import { ChemicalsTableProps } from "./ChemicalsTable";
import { AddEditFacilityChemicalDialog } from "./Inventory/Facility/FacilityChemicalAddEditDialog";
import {
  GetReportDetailsQueryName,
  useIgnoredIssues,
} from "./Report/useReport";
import { useSelectedIdInUrl } from "hooks/useSelectedIdInUrl";
import { useFacility } from "../Facility/useFacility";

export type FacilityChemicalRow =
  FacilityChemicalsQuery["facilityChemicals"]["items"][0];

const FACILITY_CHEMICALS_QUERY = gql(/* GraphQL */ `
  query FacilityChemicals(
    $search: String
    $page: Int
    $pageSize: Int
    $sort: [SortModel!]
  ) {
    facilityChemicals(
      search: $search
      page: $page
      pageSize: $pageSize
      sort: $sort
    ) {
      items {
        id
        maxAmount
        maxAmountCode
        averageAmount
        averageAmountCode
        unit
        isAlwaysReported
        facility {
          state
        }
        chemical {
          id
          ...ChemicalPicker
          alternateId
          isEhs
          noHazardsNotReporting
        }

        reportingFacilityChemical {
          id
          isOverThreshold
        }

        issues {
          ...issue
        }
      }
      count
    }
  }
`);

const FACILITY_INVENTORY_STATUS = gql(`
  query GetFacilityChemicalInventoryStatus($facilityId: ID!, $toYear: Int!) {
    facilityInventoryStatus(facilityId: $facilityId, toYear: $toYear, inventory: CHEMICAL) {
      exists
      mostRecentYear
    }
  }
`);

const DELETE_FACILITY_CHEMICAL = gql(/* GraphQL */ `
  mutation DeleteFacilityChemical($id: ID!) {
    deleteFacilityChemical(id: $id) {
      id
    }
  }
`);

const COPY_CHEMICAL_INVENTORY = gql(`
  mutation CopyChemicalInventory($facilityId: ID!, $toYear: Int!) {
    copyFacilityChemicals(facilityId: $facilityId, toYear: $toYear) {
      success
      reason
      fromYear
    }
  }
`);

export function FacilityChemicalsTable({
  facilityId,
  reportId,
}: ChemicalsTableProps) {
  const ignoredIssues = useIgnoredIssues();

  const alerts = useAlerts();
  const reportingYear = useSelectedReportingYear();
  const { hasProducts: isOtherChemicals } = useProducts();
  const [deletingChemical, setDeletingChemical] = useState<
    FacilityChemicalRow | undefined
  >();

  const { data: facilityQuery } = useFacility(facilityId);

  const {
    selectedId: facilityChemicalId,
    pushIdToUrl,
    popIdFromUrl,
  } = useSelectedIdInUrl("facilityChemicalId");
  const [addingChemical, setAddingChemical] = useState(false);

  const [deleteFacilityChemical, { loading: isDeleting }] = useMutation(
    DELETE_FACILITY_CHEMICAL,
    {
      onCompleted() {
        alerts.success("Successfully removed the chemical");
        setDeletingChemical(undefined);
      },
      onError(err) {
        alerts.error("There was an error removing the chemical", err);
        setDeletingChemical(undefined);
      },
      refetchQueries: ["FacilityChemicals"].concat(
        reportId // Include report details if we are in a report context
          ? [GetReportDetailsQueryName, "ReportingFacilityChemicals"]
          : []
      ),
    }
  );

  const [copyChemicalInventory, { loading: isCopyingInventory }] = useMutation(
    COPY_CHEMICAL_INVENTORY,
    {
      onCompleted({ copyFacilityChemicals }) {
        if (copyFacilityChemicals.success === false) {
          alerts.info("No chemical inventory was copied");
          return;
        }

        alerts.success(
          `Successfully copied chemical inventory from ${copyFacilityChemicals.fromYear}`
        );
      },
      onError(err) {
        alerts.error(
          "There was an error copying this facility's chemical inventory"
        );
        console.error(err);
      },
      refetchQueries: ["FacilityChemicals"],
      variables: {
        facilityId,
        toYear: reportingYear,
      },
    }
  );

  const { data: facilityInventoryStatus } = useQuery(
    FACILITY_INVENTORY_STATUS,
    {
      variables: { facilityId, toYear: reportingYear },
      fetchPolicy: "cache-and-network",
    }
  );
  const handleDelete = useCallback(() => {
    if (deletingChemical)
      deleteFacilityChemical({ variables: { id: deletingChemical.id } });
  }, [deleteFacilityChemical, deletingChemical]);

  const handleAddEditChemical = useCallback(
    (facilityChemicalId?: string) => {
      if (!facilityChemicalId) {
        setAddingChemical(true);
        return;
      }
      if (facilityChemicalId) {
        pushIdToUrl(facilityChemicalId);
      }
    },
    [pushIdToUrl, setAddingChemical]
  );

  const columns = useMemo<
    Array<OmnisearchGridColDef<FacilityChemicalRow>>
  >(() => {
    const cols: Array<OmnisearchGridColDef<FacilityChemicalRow>> = [
      {
        headerName: "Chemical",
        field: "chemical.name",
        flex: 2,
        renderCell(params) {
          return (
            <Tooltip
              title={
                params.row.chemical
                  ? formatChemicalName(params.row.chemical)
                  : ""
              }
            >
              <Typography
                variant="body2"
                textOverflow={"ellipsis"}
                overflow={"hidden"}
              >
                {formatChemicalName(params.row.chemical!, {
                  showCommonName: true,
                  showCasNumber: false,
                  showPureOrMixture: false,
                  showMixtureDetails: false,
                  showStateOfMatter: true,
                })}
              </Typography>
            </Tooltip>
          );
        },
      },
      {
        headerName: "CAS #",
        field: "chemical.casNumber",
        flex: 1,
        valueGetter(params) {
          return params.row.chemical?.casNumber;
        },
      },
      {
        headerName: "Alt ID",
        field: "chemical.alternateId",
        flex: 0.9,
        valueGetter(params) {
          return params.row.chemical?.alternateId;
        },
      },
      {
        headerName: "Is EHS",
        field: "chemical.isEhs",
        flex: 0.6,
        headerAlign: "center",
        align: "center",
        filterKeyType: "boolean",
        renderCell(params) {
          const isEhs = params.row.chemical?.isEhs;
          if (isEhs) return <Check />;
          return <></>;
        },
      },
      {
        headerName: "Max Amt",
        field: "maxAmount",
        flex: 1,
        filterKeyType: "number",
        valueGetter(params) {
          if (params.row.maxAmount) {
            return prettyPrintQuantity({
              amount: params.row.maxAmount,
              unit: params.row.unit,
              state: facilityQuery?.facility?.state,
            });
          } else if (params.row.maxAmountCode) {
            return prettyPrintAmountCode(
              params.row.facility?.state ?? "",
              params.row.maxAmountCode,
              params.row.unit,
              true
            );
          }
          return "";
        },
      },
      {
        headerName: "Avg Amt",
        field: "averageAmount",
        flex: 1,
        filterKeyType: "number",
        valueGetter(params) {
          if (params.row.averageAmount) {
            return prettyPrintQuantity({
              amount: params.row.averageAmount,
              unit: params.row.unit,
              state: facilityQuery?.facility?.state,
            });
          } else if (params.row.averageAmountCode) {
            return prettyPrintAmountCode(
              params.row.facility?.state ?? "",
              params.row.averageAmountCode,
              params.row.unit,
              true
            );
          }
          return "";
        },
      },
    ];

    if (!isOtherChemicals) {
      cols.push(
        {
          headerName: "Over Threshold",
          field: "isOverThreshold",
          headerAlign: "center",
          align: "center",
          flex: 0.6,
          filterKeyType: "boolean",
          renderCell(params) {
            return params.row.reportingFacilityChemical?.isOverThreshold ? (
              <Check />
            ) : (
              <></>
            );
          },
        },
        {
          field: "willReport",
          headerName: "Will Report",
          headerAlign: "center",
          align: "center",
          flex: 0.6,
          filterKeyType: "boolean",
          renderCell({ row }) {
            const willReport = isChemicalReporting({
              isAlwaysReported: row.isAlwaysReported,
              isOverThreshold: row.reportingFacilityChemical?.isOverThreshold,
              chemical: {
                noHazardsNotReporting: row.chemical?.noHazardsNotReporting,
              },
            });

            return willReport ? <Check /> : <></>;
          },
          sortable: false,
        }
      );
    }
    cols.push(
      {
        field: "issues",
        headerName: "Issues",
        align: "center",
        headerAlign: "center",
        flex: 0.6,
        renderCell: ({ row: { issues } }) => {
          const shouldIgnore = ignoredIssues.some((issue) =>
            issues.some((i) => i.modelId === issue.modelId)
          );
          return (
            <IssueCount
              issueCount={issues.length}
              ignoreForReport={shouldIgnore}
            />
          );
        },
        sortable: false,
      },
      {
        field: "actions",
        type: "actions",
        flex: 0.8,
        getActions: ({ row }) => {
          return [
            <Tooltip title="Edit Chemical" key="editChemical">
              <GridActionsCellItem
                onClick={() => {
                  handleAddEditChemical(row.id);
                }}
                label="Edit Chemical"
                icon={<Edit />}
                key="edit"
              />
            </Tooltip>,
            <Tooltip title="Remove Chemical" key="removeChemical">
              <GridActionsCellItem
                onClick={() => setDeletingChemical(row)}
                label="Delete Chemical"
                key="delete"
                icon={<Delete />}
              />
            </Tooltip>,
          ];
        },
      }
    );

    return cols;
  }, [handleAddEditChemical, isOtherChemicals, ignoredIssues]);

  const defaultSearch = useMemo(
    () => `facilityId:${facilityId} reportingYear:${reportingYear}`,
    [facilityId, reportingYear]
  );

  const commandButtons = useMemo(() => {
    const buttons: ReactNode[] = [];
    if (!reportId) {
      buttons.push(
        <TierIIYearPicker
          key="yearPicker"
          selectProps={{
            size: "small",
          }}
        />
      );
    }
    buttons.push(
      <Button
        variant="contained"
        key="addChemical"
        size="small"
        onClick={() => handleAddEditChemical()}
      >
        Add Chemical
      </Button>
    );
    return buttons;
  }, [reportId, handleAddEditChemical]);

  return (
    <>
      <OmnisearchDataGrid
        columns={columns}
        dataQuery={FACILITY_CHEMICALS_QUERY}
        defaultSearch={defaultSearch}
        initialSortModel={[{ field: "chemical.name", sort: "asc" }]}
        getCount={(data) => data.facilityChemicals.count}
        getItems={(data) => data.facilityChemicals.items}
        withPadding={false}
        commandButtons={commandButtons}
        excludeFilterColumns={["issues"]}
        noDataMessage={`No chemical information has been added to this facility for ${reportingYear}${
          !facilityInventoryStatus?.facilityInventoryStatus.exists
            ? " or any previous year"
            : ""
        }.`}
        noDataButtonText={
          facilityInventoryStatus?.facilityInventoryStatus.exists
            ? `Copy Inventory from ${facilityInventoryStatus?.facilityInventoryStatus.mostRecentYear}`
            : undefined
        }
        noDataOnButtonClick={copyChemicalInventory}
        onRowClick={(params) => handleAddEditChemical(params.row.id)}
        otherLoading={isCopyingInventory}
      />
      <ConfirmDialog
        open={!!deletingChemical}
        onClose={() => setDeletingChemical(undefined)}
        onConfirm={handleDelete}
        title="Remove Chemical Inventory?"
        msg={
          <DeleteChemicalMessage
            chemicalName={deletingChemical?.chemical?.name}
            reportingYear={reportingYear}
            forMeasurements={false}
          />
        }
        confirmText="delete"
        loading={isDeleting}
      />
      {(facilityChemicalId || addingChemical) && (
        <AddEditFacilityChemicalDialog
          facilityChemicalId={facilityChemicalId}
          reportingYear={reportingYear}
          facilityId={facilityId}
          reportId={reportId}
          onClose={() => {
            if (addingChemical) {
              setAddingChemical(false);
            } else {
              popIdFromUrl();
            }
          }}
        />
      )}
    </>
  );
}

export function DeleteChemicalMessage({
  chemicalName,
  reportingYear,
  forMeasurements,
}: {
  chemicalName?: string;
  reportingYear: number;
  forMeasurements?: boolean;
}) {
  const { hasProducts } = useProducts();

  return (
    <>
      <Typography>
        {chemicalName} will be deleted from your chemical inventory for
        reporting year {reportingYear}.
      </Typography>
      <Typography component="span">
        This action will:
        <ul>
          <li>Remove {chemicalName} from your chemical inventory.</li>
          {forMeasurements && (
            <li>Remove all daily measurements for this chemical.</li>
          )}
        </ul>
      </Typography>
      <Typography component="span">
        This action will <b>not:</b>
        <ul>
          {hasProducts && (
            <li>
              Remove {chemicalName} contained in products from your product
              inventory.
            </li>
          )}
          <li>Change {chemicalName} in your chemical catalog.</li>
          <li>Affect previous reporting years.</li>
        </ul>
      </Typography>
      <Typography>
        Are you sure you want to remove {chemicalName} from your chemical
        inventory?
      </Typography>
    </>
  );
}
