import { useQuery } from "@apollo/client";
import AssignmentOutlined from "@mui/icons-material/AssignmentOutlined";
import AssignmentReturnOutlined from "@mui/icons-material/AssignmentReturnOutlined";
import { Tooltip } from "@mui/material";
import {
  GridActionsCellItem,
  GridRowParams,
  GridSortModel,
} from "@mui/x-data-grid-premium";
import { DataGrid } from "components/DataGrid";
import { currentTierIIReportingYear } from "encamp-shared/src/constants/tierii";
import { gql } from "generated-graphql";
import { ChemicalAnomaliesQuery } from "generated-graphql/graphql";
import { usePaginationModel } from "hooks/usePaginationModel";
import { useTenant } from "hooks/useTenant";
import { useState } from "react";
import { prettyPrintQuantity } from "encamp-shared/src/utils/prettyPrintUnits";
import { useChemicalOverviewState } from "../ChemicalOverviewContext";

const CHEMICAL_ANOMALIES_QUERY = gql(`
  query ChemicalAnomalies($tenantId: ID!, $filter: ChemicalOverviewFilter, $page: Int, $pageSize: Int, $sort: [SortModel!]) {
    chemicalAnomalies(tenantId: $tenantId, filter: $filter, page: $page, pageSize: $pageSize, sort: $sort) {
      items {
        facilityId
        facilityName
        chemicalId
        chemicalName
        previousYearMaxAmount
        previousYearUnit
        previousYearReportId
        currentYearMaxAmount
        currentYearUnit
        currentYearReportId
      }
      count
    }
  }
`);

type Anomaly = ChemicalAnomaliesQuery["chemicalAnomalies"]["items"][number];

export const AnomaliesDataGrid = () => {
  const { tenantId } = useTenant();
  const [paginationModel, setPaginationModel] = usePaginationModel();
  const [sortModel, setSortModel] = useState<GridSortModel>([
    { field: "facilityName", sort: "asc" },
  ]);
  const {
    chemicalOverviewState: { deferredFilters: filter },
    drillDown,
  } = useChemicalOverviewState();

  const { data, previousData, loading } = useQuery(CHEMICAL_ANOMALIES_QUERY, {
    variables: {
      tenantId: tenantId ?? "",
      filter: {
        assignedToMe: filter.assignedToMe,
        tagNames: filter.facilityTags,
      },
      page: paginationModel.page,
      pageSize: paginationModel.pageSize,
      sort: sortModel,
    },
    fetchPolicy: "cache-and-network",
  });

  return (
    <DataGrid
      columns={[
        { field: "facilityName", headerName: "Facility", flex: 1 },
        { field: "chemicalName", headerName: "Chemical", flex: 1 },
        {
          field: "previousYearMaxAmount",
          headerName: `Amount reported in ${currentTierIIReportingYear - 1}`,
          flex: 1,
          valueGetter: ({
            row: { previousYearMaxAmount: amount, previousYearUnit: unit },
          }) =>
            prettyPrintQuantity({
              amount: amount,
              unit: unit,
            }),
        },
        {
          field: "currentYearMaxAmount",
          headerName: `Amount reported in ${currentTierIIReportingYear}`,
          flex: 1,
          valueGetter: ({
            row: { currentYearMaxAmount: amount, currentYearUnit: unit },
          }) =>
            prettyPrintQuantity({
              amount: amount,
              unit: unit,
            }),
        },
        {
          field: "actions",
          type: "actions",
          getActions: ({
            row: { previousYearReportId, currentYearReportId },
          }) => [
            <GridActionsCellItem
              key="previous"
              onClick={() => {
                window.open(
                  `/o/${tenantId}/chemicals/reports/${previousYearReportId}/chemical-summary`,
                  "_blank"
                );
              }}
              label={`${currentTierIIReportingYear - 1} Report`}
              icon={
                <Tooltip title={`${currentTierIIReportingYear - 1} Report`}>
                  <AssignmentReturnOutlined />
                </Tooltip>
              }
            />,
            <GridActionsCellItem
              key="current"
              onClick={() => {
                window.open(
                  `/o/${tenantId}/chemicals/reports/${currentYearReportId}/chemical-summary`,
                  "_blank"
                );
              }}
              label={`${currentTierIIReportingYear} Report`}
              icon={
                <Tooltip title={`${currentTierIIReportingYear} Report`}>
                  <AssignmentOutlined />
                </Tooltip>
              }
            />,
          ],
        },
      ]}
      rows={
        data?.chemicalAnomalies.items ??
        previousData?.chemicalAnomalies.items ??
        []
      }
      rowCount={
        data?.chemicalAnomalies.count ??
        previousData?.chemicalAnomalies.count ??
        0
      }
      loading={loading}
      getRowId={(row) =>
        `${row.chemicalId}-${row.facilityId}-${row.currentYearReportId}-${row.previousYearReportId}`
      }
      noRowsMessage="No chemical anomalies found!"
      isRowSelectable={() => false}
      pagination
      paginationMode="server"
      paginationModel={paginationModel}
      onPaginationModelChange={setPaginationModel}
      sortingMode="server"
      sortModel={sortModel}
      onSortModelChange={setSortModel}
      onRowClick={(params: GridRowParams<Anomaly>) => {
        const navigateTo = params.row.currentYearReportId
          ? `../inventory/${params.row.facilityId}/other-chemicals`
          : "../catalog/chemicals";

        drillDown(navigateTo, () => ({
          chemicalId: params.row.chemicalId,
        }));
      }}
    />
  );
};
