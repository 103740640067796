import { useQuery } from "@apollo/client";
import { gql } from "generated-graphql";
import { GetTierIiReportQuery } from "generated-graphql/graphql";
import { useParams, useSearchParams } from "react-router-dom";

export const PERSON_FRAGMENT = gql(`
  fragment PersonFragment on Person {
    id
    first
    last
    email
    tenantId
    title
    city
    state
    streetAddress1
    zip
    phones {
      number
      type
    }
  }
`);

export const GET_TIERII_REPORT = gql(`
query GetTierIIReport($reportId: ID!, $version: String) {
  tierIIReport(reportId: $reportId, version: $version) {
    id
    tenantId
    reportingYear
    reportKind
    productCount
    organizationStatus
    encampStatus
    tags
    issues {
      key
    }
    tenant {
      id
      name
    }
    reviewers {
      isComplete
    }
    facility {
      id
      name
      county
      companyName
      naicsCode
      customerFacilityId
      stateFields {
        key
        value
        type
        jurisdiction
        inputType
        label
        tooltip
        selectOptions {
          label
          value
        }
      }
      reportsManually
      city
      state
      streetAddress1
      streetAddress2
      zip
      portalCredential {
        type
        url
        credential {
          id
          isEncampOwned
          kind
          password
          username
          lastVerificationCheckAt
          lastVerificationSuccessAt
          verificationStatus
          tenantId
          usesCombinedInvoicing
        }
      }
      jobs {
        id
        action
        status
        createdAt
        logs {
          id
          jobId
          createdAt
          status
          errorMessage
          cloudWatchLogStream
          screenshotKey
          screenshotUrl
          updatedAt
        }
        stepsDone {
          id
          jobId
          name
          createdAt
          updatedAt
        }
      }
      contacts {
        id
        reportingRoles
        person {
          ...PersonFragment
        }
      }
      facilityAlternateIds {
        id
        type
        value
      }
      lepc {
        id
        name
        email
        mailingAddress
        mailingAddressLine2
        city
        state
        county
        zip
        isEmailed
        isMailed
        phone
        additionalComments
        needsT2sFile
        isStateSubmits
      }
      fireDepartment {
        id
        fireDeptId
        name
        email
        mailingAddress
        mailingAddressLine2
        city
        state
        county
        zip
        isMailed
        isEmailed
        phone
        phoneExt
        additionalComments
        stateSubmitsToFireDept
        needsT2sFile
      }
    }
    currentWorkflow {
      id
      activities {
        id
        type
        status
        executionPlan
        description
        championId
        nonChampionIds
        cohortFacilityIds
        reportDetailsExtended
        output
        inputs {
          id
          activityId
          label
          shortName
          value
          required
          type
        }
        documents {
          document {
            id
            title
            description
            storageLink
            documentType
            updatedAt
            createdAt
          }
        }
        job {
          id
          status
          action
        }
        payments {
          id
          status
          paidAt
          tenantId
          amount
          lobMail {
            id
            lobId
            status
            type
          }
        }
      }
      currentActivityId
      currentActivity {
        id
        type
        status
        executionPlan
        description
        assigneeId
        jobId
        nonChampionIds
        job {
          id
          taskArn
          taskUrl
        }
        assignee {
          id
          email
          person {
            first
            last
          }
        }
        documents  {
          document {
            id
            title
            description
            storageLink
            documentType
            updatedAt
            createdAt
          }
        }
      }
    }
    documents {
      id
      title
      description
      storageLink
      documentType
      updatedAt
      createdAt
      activities {
        id
        type
        description
      }
    }
    verifier {
      ...PersonFragment
    }
    verifierFullName
    verifierTitle
    verifiedAt
    comments {
      ...CommentList
    }
    payments {
      ...PaymentRow
    }
  }
}`);

type MinimalOptions = {
  onCompleted?: (data: GetTierIiReportQuery) => void;
};

export function useReportDetails(options?: MinimalOptions) {
  const { reportId } = useParams<{ reportId: string }>();
  const [searchParams] = useSearchParams();
  const version = searchParams.get("version");

  const query = useQuery(GET_TIERII_REPORT, {
    variables: {
      reportId: reportId ?? "",
      // Transitional argument to do side-by-side-testing.
      version: version ?? undefined,
    },
    skip: !reportId,
    onCompleted: options?.onCompleted,
  });

  if (query.error) throw query.error;

  return query;
}
