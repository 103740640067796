import { prettyPrintEnumValue } from "encamp-shared/src/utils/prettyPrintEnumValue";
import {
  Chemical,
  ChemicalComponent,
  ChemicalStateField,
  DynamicField,
  Maybe,
  PureOrMixture,
} from "generated-graphql/graphql";

type Component = Pick<
  ChemicalComponent,
  "name" | "componentPercentage" | "casNumber" | "isEhs"
>;

interface ChemicalNameConfig {
  showCommonName?: boolean;
  showCasNumber?: boolean;
  showPureOrMixture?: boolean;
  showMixtureDetails?: boolean;
  showStateOfMatter?: boolean;
}

export const formatChemicalName = (
  chemical: Pick<Chemical, "name" | "casNumber" | "pureOrMixture"> & {
    stateOfMatter?: Chemical["stateOfMatter"];
    components?: Maybe<Array<Component>>;
    stateFields?: Maybe<
      Array<Pick<DynamicField, "key" | "value" | "jurisdiction">>
    >;
  },
  config?: ChemicalNameConfig
) => {
  const effectiveConfig = {
    showCommonName: true,
    showCasNumber: true,
    showPureOrMixture: true,
    showMixtureDetails: true,
    showStateOfMatter: true,
    ...config,
  };
  const mixtureDetails =
    chemical.pureOrMixture === PureOrMixture.Mixture
      ? chemical.components
          // NOTE: We are supposed to chose the largest EHS component:
          // I have *chosen* to drive via the db's `component.isEhs`
          // if that flag is wrong, we will manifest it to the user elsewhere
          ?.filter((c) => c.isEhs)
          .reduce((acc: Component | null, component) => {
            return (component.componentPercentage ?? 0) >
              (acc?.componentPercentage ?? 0)
              ? component
              : acc;
          }, null)
      : null;

  const mixtureDisplay = mixtureDetails
    ? `${mixtureDetails.componentPercentage}% ${mixtureDetails.name}`
    : "";

  const stateOfMatterDisplay = chemical.stateOfMatter?.length
    ? ` [${chemical.stateOfMatter
        .map((state) => prettyPrintEnumValue(state))
        .join("/")}]`
    : "";

  const effectiveCas = !!chemical.casNumber ? chemical.casNumber : "N/A";

  let commonNameDisplay = "";
  if (chemical.stateFields?.length) {
    const commonName = chemical.stateFields?.find(
      (sf) => sf.key === "commonName" && sf.jurisdiction === "CA"
    )?.value;
    if (commonName && commonName !== chemical.name) {
      commonNameDisplay = ` (${commonName})`;
    }
  }

  // Build name parts with configuration
  const parts: string[] = [];

  // Base name is always included
  parts.push(chemical.name);

  // Common name (optional)
  if (effectiveConfig.showCommonName && commonNameDisplay) {
    parts.push(commonNameDisplay);
  }

  // Details section (CAS, Pure/Mixture, Mixture Details)
  const detailsParts: string[] = [];
  if (effectiveConfig.showCasNumber) {
    detailsParts.push(effectiveCas);
  }
  if (effectiveConfig.showPureOrMixture) {
    detailsParts.push(prettyPrintEnumValue(chemical.pureOrMixture));
  }
  if (effectiveConfig.showMixtureDetails && mixtureDisplay) {
    detailsParts.push(mixtureDisplay);
  }

  // Add details section if any details are present
  if (detailsParts.length > 0) {
    parts.push(`(${detailsParts.join(", ")})`);
  }

  // State of matter (optional)
  if (effectiveConfig.showStateOfMatter && stateOfMatterDisplay) {
    parts.push(stateOfMatterDisplay);
  }

  // Join all parts with appropriate spacing
  return parts.join(" ").trim();
};
