import { useMutation, useQuery } from "@apollo/client";
import Add from "@mui/icons-material/Add";
import ArrowForward from "@mui/icons-material/ArrowForward";
import Delete from "@mui/icons-material/Delete";
import Edit from "@mui/icons-material/Edit";
import { Button, Skeleton, Typography } from "@mui/material";
import { GridActionsCellItem } from "@mui/x-data-grid-premium";
import { useAlerts } from "components/Alerts/AlertProvider";
import { ConfirmDialog } from "components/ConfirmDialog";
import { FloatingSaveBar } from "components/FloatingSaveBar";
import { IssueCount } from "components/IssueCount";
import { CatalogLink } from "components/ModelLinks/CatalogLink";
import { OmnisearchDataGrid } from "components/OmnisearchDataGrid";
import { gql } from "generated-graphql";
import { FacilityProductMeasurementsQuery } from "generated-graphql/graphql";
import { OmnisearchGridColDef } from "hooks/useOmnisearchDatagridSettings";
import { useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { prettyPrintDateFull, prettyPrintDateMed } from "util/dates";
import { prettyPrintQuantity } from "encamp-shared/src/utils/prettyPrintUnits";
import { FacilityProductMeasurementForm } from "./FacilityProductMeasurementForm";
import { FACILITY_PRODUCT_MEASUREMENT } from "./Inventory/Facility/schema";
import { TierIIYearPicker } from "components/TierIIYearPicker";
import { useFacility } from "../Facility/useFacility";

type Row =
  FacilityProductMeasurementsQuery["facilityProductMeasurements"]["items"][0];

const PRODUCT_FOR_FACILITY = gql(/** GraphQL */ `
  query ProductForFacility($productId: ID!, $facilityId: ID!) {
    productForFacility(productId: $productId, facilityId: $facilityId) {
      id
      name
      productChemicals {
        chemical {
          id
          name
        }
      }
      issues {
        ...issue
      }
    }
  }
`);

const DELETE_FACILITY_PRODUCT_MEASUREMENT = gql(/** GraphQL */ `
  mutation DeleteFacilityProductMeasurement($id: ID!) {
    deleteFacilityProductMeasurement(id: $id) {
      id
    }
  }
`);

export function FacilityProductMeasurementTable({
  productId,
  facilityId,
  reportingYear,
  reportId,
}: {
  productId: string;
  facilityId: string;
  reportingYear: number;
  reportId?: string;
}) {
  const alerts = useAlerts();
  const navigate = useNavigate();
  const [editingMeasurementId, setEditingMeasurementId] = useState<
    string | undefined
  >();
  const [measurementSeed, setMeasurementSeed] = useState<string | null>(null);
  const [addDialogOpen, setAddDialogOpen] = useState(false);
  const [deletingMeasurement, setDeletingMeasurement] = useState<
    Row | undefined
  >();

  const { data: facilityQuery } = useFacility(facilityId);

  const { data: productData, loading } = useQuery(PRODUCT_FOR_FACILITY, {
    variables: {
      productId,
      facilityId,
    },
    skip: !productId,
  });

  const [deleteMeasurement, { loading: isDeleting }] = useMutation(
    DELETE_FACILITY_PRODUCT_MEASUREMENT,
    {
      onCompleted() {
        alerts.success("Successfully deleted the measurement");
        setDeletingMeasurement(undefined);
      },
      onError(err) {
        alerts.error("An error occurred deleting the measurement", err);
        setDeletingMeasurement(undefined);
      },
      refetchQueries: [FACILITY_PRODUCT_MEASUREMENT],
    }
  );

  const handleDelete = useCallback(() => {
    if (deletingMeasurement) {
      deleteMeasurement({
        variables: {
          id: deletingMeasurement.id,
        },
      });
    }
  }, [deleteMeasurement, deletingMeasurement]);

  const columns = useMemo<OmnisearchGridColDef<Row>[]>(
    () => [
      {
        field: "location",
        valueGetter: ({ row }) => row.storageLocation?.description,
        headerName: "Storage Location",
        flex: 1,
      },
      {
        field: "measuredAtUtc",
        headerName: "Measurement Date",
        valueGetter: ({ row }) => {
          const measurementYear = row.measuredAtUtc.slice(0, 4);
          return `${prettyPrintDateMed(row.measuredAtUtc)}${
            measurementYear < reportingYear ? " (carry-over)" : ""
          }`;
        },
        flex: 1,
      },
      {
        field: "quantity",
        headerName: "Quantity on Hand",
        filterKeyType: "number",
        flex: 1,
        valueGetter: ({ row }) =>
          prettyPrintQuantity({
            amount: row.quantity,
            unit: null,
            state: facilityQuery?.facility?.state,
          }),
      },
      {
        field: "issues",
        headerName: "Issues",
        align: "center",
        headerAlign: "center",
        flex: 0.5,
        renderCell: ({ row }) => {
          return <IssueCount issueCount={row.issues.length} />;
        },
      },
      {
        field: "actions",
        type: "actions",
        flex: 0.5,
        align: "right",
        getActions: (params) => [
          <GridActionsCellItem
            onClick={() => {
              setEditingMeasurementId(params.row.id);
            }}
            key={1}
            label="Edit"
            icon={<Edit />}
          />,
          <GridActionsCellItem
            onClick={() => setDeletingMeasurement(params.row)}
            key={2}
            label="Delete Measurement"
            icon={<Delete />}
          />,
          <GridActionsCellItem
            onClick={() => {
              setMeasurementSeed(params.row.id);
            }}
            key={3}
            label="Quick Add"
            icon={<Add />}
          />,
        ],
      },
    ],
    [facilityId, productId, reportingYear]
  );

  if (loading) {
    return (
      <>
        <Skeleton />
        <Skeleton />
        <Skeleton />
      </>
    );
  }

  return (
    <>
      <Typography variant="h5" sx={{ mb: 3 }}>
        Daily Measurements for {productData?.productForFacility.name}
      </Typography>
      <OmnisearchDataGrid
        columns={columns}
        dataQuery={FACILITY_PRODUCT_MEASUREMENT}
        getCount={(d) => d.facilityProductMeasurements.count}
        getItems={(d) => d.facilityProductMeasurements.items ?? []}
        defaultSearch={`productId:${productId} facilityId:${facilityId} reportingYear:${reportingYear}`}
        initialSortModel={[
          { field: "location", sort: "desc" },
          { field: "measuredAtUtc", sort: "desc" },
        ]}
        commandButtons={[
          !reportId ? (
            <TierIIYearPicker
              key="yearPicker"
              selectProps={{
                size: "small",
              }}
            />
          ) : undefined,
          <Button
            key="add"
            variant="contained"
            size="small"
            onClick={() => setAddDialogOpen(true)}
          >
            Add Measurement
          </Button>,
        ]}
        onRowClick={(params) => {
          setEditingMeasurementId(params.row.id);
        }}
        withPadding={false}
        skip={!productId || !reportingYear || !facilityId}
        excludeFilterColumns={["issues"]}
        noDataMessage={
          "No measurement information has been added to this facility yet."
        }
        noDataButtonText="Add Measurement"
        noDataOnButtonClick={() => setAddDialogOpen(true)}
      />
      <CatalogLink
        id={productData?.productForFacility.id ?? ""}
        type="Product"
        name={productData?.productForFacility.name ?? ""}
        issues={productData?.productForFacility.issues ?? []}
      />
      {(productData?.productForFacility?.productChemicals ?? []).map(
        (pc, idx) => (
          <CatalogLink
            key={idx}
            id={pc.chemical.id ?? ""}
            type="Chemical"
            name={pc.chemical.name ?? ""}
            issues={productData?.productForFacility.issues ?? []}
          />
        )
      )}
      <FloatingSaveBar
        hideCancel={true}
        saveStartIcon={<ArrowForward />}
        saveText="Return to Products"
        onSaveClick={() => {
          navigate(`../?ry=${reportingYear}`);
        }}
      />
      <ConfirmDialog
        open={!!deletingMeasurement}
        onClose={() => setDeletingMeasurement(undefined)}
        onConfirm={handleDelete}
        title="Remove Product Measurement?"
        confirmText="delete"
        msg={
          <>
            <p>
              The {productData?.productForFacility.name} measurement for{" "}
              {prettyPrintDateFull(deletingMeasurement?.measuredAtUtc)} will be
              deleted.
            </p>
            <p>Are you sure you want to remove this measurement?</p>
          </>
        }
        loading={isDeleting}
      />
      {(editingMeasurementId || measurementSeed || addDialogOpen) && (
        <FacilityProductMeasurementForm
          open={!!editingMeasurementId || !!measurementSeed || addDialogOpen}
          facilityId={facilityId}
          measurementId={editingMeasurementId}
          product={productData?.productForFacility}
          measurementSeed={measurementSeed ?? undefined}
          onClose={() => {
            setEditingMeasurementId(undefined);
            setMeasurementSeed(null);
            setAddDialogOpen(false);
          }}
        />
      )}
    </>
  );
}
