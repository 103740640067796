import { List, ListItem, ListItemText, Stack } from "@mui/material";
import { ExportDataButton } from "components/ExportDataButton";
import { FacilitiesExportButton } from "components/FacilitiesExportButton";
import { Importer } from "components/Importer";
import { ImportButtonWithDialog } from "components/Importer/ImportDialog";
import { OneSchemaTemplateType } from "encamp-shared/src/oneSchema/types";
import { useTenant } from "hooks/useTenant";

export function ImportExport({ tenantId }: { tenantId: string }) {
  const { tenant } = useTenant();
  const tenantPrefix = tenant?.name ?? tenantId;

  return (
    <List>
      <ListItem>
        <ListItemText
          primary={`Chemical Catalog`}
          secondary="Chemical, ChemicalComponent, ChemicalStateField"
        />
        <Stack direction="row" spacing={1}>
          <ExportDataButton
            key="export"
            disabled={!tenantId}
            tenantId={tenantId}
            exportType="chemical"
            fileName={`${tenantPrefix}-chemical-catalog`}
            timeoutInSeconds={900}
          />
          <ImportButtonWithDialog
            importers={[
              {
                name: "Chemicals",
                importer: (
                  <Importer templateType={OneSchemaTemplateType.Chemical} />
                ),
              },
              {
                name: "Chemical Components",
                importer: (
                  <Importer
                    templateType={OneSchemaTemplateType.ChemicalComponent}
                  />
                ),
              },
              {
                name: "Chemical State Fields",
                importer: (
                  <Importer
                    templateType={OneSchemaTemplateType.ChemicalStateField}
                  />
                ),
              },
            ]}
          />
        </Stack>
      </ListItem>
      <ListItem>
        <ListItemText
          primary={`Chemical Inventory - MEASUREMENT mode`}
          secondary=" StorageLocation, FacilityChemicalMeasurement"
        />
        <Stack direction="row" spacing={1}>
          <ExportDataButton
            key="export"
            disabled={!tenantId}
            tenantId={tenantId}
            exportType="facility-chemical-measurement"
            fileName={`${tenantPrefix}-chemical-measurements`}
            timeoutInSeconds={900}
          />
          <ImportButtonWithDialog
            importers={[
              {
                name: "Storage Locations",
                importer: (
                  <Importer
                    templateType={OneSchemaTemplateType.StorageLocation}
                  />
                ),
              },
              {
                name: "Measurements",
                importer: (
                  <Importer
                    templateType={
                      OneSchemaTemplateType.FacilityChemicalMeasurement
                    }
                  />
                ),
              },
            ]}
          />
        </Stack>
      </ListItem>
      <ListItem>
        <ListItemText
          primary={`Chemical Inventory - AGGREGATE mode`}
          secondary="FacilityChemical, StorageLocation, FacilityChemicalStorageLocation, FacilityChemicalStateField"
        />
        <Stack direction="row" spacing={1}>
          <ExportDataButton
            key="export"
            disabled={!tenantId}
            tenantId={tenantId}
            exportType="facility-chemical"
            fileName={`${tenantPrefix}-chemical-inventory`}
            timeoutInSeconds={900}
          />
          <ImportButtonWithDialog
            importers={[
              {
                name: "FacilityChemicals",
                importer: (
                  <Importer
                    templateType={OneSchemaTemplateType.FacilityChemical}
                  />
                ),
              },
              {
                name: "Storage Locations",
                importer: (
                  <Importer
                    templateType={OneSchemaTemplateType.StorageLocation}
                  />
                ),
              },
              {
                name: "FacilityChemicalStorageLocations",
                importer: (
                  <Importer
                    templateType={
                      OneSchemaTemplateType.FacilityChemicalStorageLocation
                    }
                  />
                ),
              },
              {
                name: "Facility Chemical State Fields",
                importer: (
                  <Importer
                    templateType={
                      OneSchemaTemplateType.FacilityChemicalStateField
                    }
                  />
                ),
              },
            ]}
          />
        </Stack>
      </ListItem>
      <ListItem>
        <ListItemText
          primary={`Product Catalog`}
          secondary="Product, ProductChemical"
        />
        <Stack direction="row" spacing={1}>
          <ExportDataButton
            key="export"
            disabled={!tenantId}
            tenantId={tenantId}
            exportType="product-catalog"
            fileName={`${tenantPrefix}-product-catalog`}
            timeoutInSeconds={900}
          />
          <ImportButtonWithDialog
            importers={[
              {
                name: "Products",
                importer: (
                  <Importer templateType={OneSchemaTemplateType.Product} />
                ),
              },
              {
                name: "ProductChemicals",
                importer: (
                  <Importer
                    templateType={OneSchemaTemplateType.ProductChemical}
                  />
                ),
              },
            ]}
          />
        </Stack>
      </ListItem>
      <ListItem>
        <ListItemText
          primary={`Product Inventory - MEASUREMENT mode`}
          secondary="StorageLocation, FacilityProductMeasurement"
        />
        <Stack direction="row" spacing={1}>
          <ExportDataButton
            key="export"
            disabled={!tenantId}
            tenantId={tenantId}
            exportType="facility-product-measurement"
            fileName={`${tenantPrefix}-product-measurements`}
            timeoutInSeconds={900}
          />
          <ImportButtonWithDialog
            importers={[
              {
                name: "Storage Locations",
                importer: (
                  <Importer
                    templateType={OneSchemaTemplateType.StorageLocation}
                  />
                ),
              },
              {
                name: "FacilityProductMeasurements",
                importer: (
                  <Importer
                    templateType={
                      OneSchemaTemplateType.FacilityProductMeasurement
                    }
                  />
                ),
              },
            ]}
          />
        </Stack>
      </ListItem>
      <ListItem>
        <ListItemText
          primary={`Product Inventory - AGGREGATE mode`}
          secondary="FacilityProduct, StorageLocation, FacilityProductStorageLocation"
        />
        <Stack direction="row" spacing={1}>
          <ExportDataButton
            key="export"
            disabled={!tenantId}
            tenantId={tenantId}
            exportType="facility-product"
            fileName={`${tenantPrefix}-product-inventory`}
            timeoutInSeconds={900}
          />
          <ImportButtonWithDialog
            importers={[
              {
                name: "FacilityProducts",
                importer: (
                  <Importer
                    templateType={OneSchemaTemplateType.FacilityProduct}
                  />
                ),
              },
              {
                name: "Storage Locations",
                importer: (
                  <Importer
                    templateType={OneSchemaTemplateType.StorageLocation}
                  />
                ),
              },
              {
                name: "FacilityProductStorageLocations",
                importer: (
                  <Importer
                    templateType={
                      OneSchemaTemplateType.FacilityProductStorageLocation
                    }
                  />
                ),
              },
            ]}
          />
        </Stack>
      </ListItem>
      <ListItem>
        <ListItemText
          primary={`Contact Catalog`}
          secondary="Person, FacilityContact"
        />
        <Stack direction="row" spacing={1}>
          <ExportDataButton
            key="export"
            disabled={!tenantId}
            tenantId={tenantId}
            exportType="contact"
            fileName={`${tenantPrefix}-contact-catalog`}
            timeoutInSeconds={900}
          />
          <ImportButtonWithDialog
            importers={[
              {
                name: "People",
                importer: (
                  <Importer templateType={OneSchemaTemplateType.Contact} />
                ),
              },
              {
                name: "FacilityContacts",
                importer: (
                  <Importer
                    templateType={OneSchemaTemplateType.FacilityContact}
                  />
                ),
              },
            ]}
          />
        </Stack>
      </ListItem>
      <ListItem>
        <ListItemText
          primary={`Facilities`}
          secondary="Facility, FacilityStateField, FacilityAlternateId"
        />
        <Stack direction="row" spacing={1}>
          <FacilitiesExportButton key="export" tenantId={tenantId} />
          <ImportButtonWithDialog
            importers={[
              {
                name: "Facilities",
                importer: (
                  <Importer templateType={OneSchemaTemplateType.Facility} />
                ),
              },
              {
                name: "Facility State Fields",
                importer: (
                  <Importer
                    templateType={OneSchemaTemplateType.FacilityStateField}
                  />
                ),
              },
              {
                name: "Facility Alternate IDs",
                importer: (
                  <Importer
                    templateType={OneSchemaTemplateType.FacilityAlternateId}
                  />
                ),
              },
            ]}
          />
        </Stack>
      </ListItem>
    </List>
  );
}
