import { useLazyQuery, useMutation } from "@apollo/client";
import Download from "@mui/icons-material/Download";
import Loop from "@mui/icons-material/Loop";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { GridActionsCellItem } from "@mui/x-data-grid-premium";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { useAlerts } from "components/Alerts/AlertProvider";
import { Dialog } from "components/Dialog";
import { IssueCount } from "components/IssueCount";
import { OmnisearchDataGrid } from "components/OmnisearchDataGrid";
import {
  TierIIYearPicker,
  useSelectedReportingYear,
} from "components/TierIIYearPicker";
import { currentTierIIReportingYear } from "encamp-shared/src/constants/tierii";
import { gql } from "generated-graphql";
import {
  JobStatus,
  JobTask,
  StaffReportJobsQuery,
  StaffReportType,
} from "generated-graphql/graphql";
import { OmnisearchGridColDef } from "hooks/useOmnisearchDatagridSettings";
import { useTenant } from "hooks/useTenant";
import { useCallback, useMemo, useState } from "react";
import { prettyPrintDateTime } from "util/dates";

gql(`
  fragment StaffReportJobFragment on Job {
    id
    status
    updatedAt
    totalIssues
    staffReportKey
    parameters
    tenant {
      id
      name
    }
  }
`);

const STAFF_REPORTS = gql(`
  query StaffReportJobs($search: String, $page: Int, $pageSize: Int, $sort: [SortModel!]) {
    jobs(search: $search, page: $page, pageSize: $pageSize, sort: $sort) {
      count
      items {
        ...StaffReportJobFragment
      }
    }
  }
`);

const GET_DOCUMENT_DOWNLOAD_LINK_QUERY = gql(`
  query GetStaffReportDownloadLink($id: ID!) {
    job(id: $id) {
      staffReportDownloadLink
    }
  }
`);

const GENERATE_STAFF_REPORT = gql(`
  mutation GenerateStaffReport($reportType: StaffReportType!, $reportingYear: Int!, $startDate: DateTime, $endDate: DateTime, $tenantId: String) {
    generateStaffReport(reportType: $reportType, reportingYear: $reportingYear, startDate: $startDate, endDate: $endDate, tenantId: $tenantId) {
      ...StaffReportJobFragment
    }
  }
`);

type Row = StaffReportJobsQuery["jobs"]["items"][number];

export const StaffReports = () => {
  const { tenantId, tenant } = useTenant();
  const alerts = useAlerts();

  const [getDownloadLink] = useLazyQuery(GET_DOCUMENT_DOWNLOAD_LINK_QUERY, {
    onCompleted: ({ job: { staffReportDownloadLink } }) => {
      if (staffReportDownloadLink) {
        window.open(staffReportDownloadLink, "_blank");
      }
    },
    onError(error) {
      alerts.error("An error occurred while downloading the report", error);
    },
  });
  const [reportHasBeenGenerated, setReportHasBeenGenerated] = useState(false);
  const [reportType, setReportType] = useState<StaffReportType>(
    StaffReportType.TierIiValidation
  );
  const reportingYear = useSelectedReportingYear();
  const [billingStartDate, setBillingStartDate] = useState<Date | null>(
    new Date(currentTierIIReportingYear + 1, 0, 1)
  );
  const [billingEndDate, setBillingEndDate] = useState<Date | null>(
    new Date(Date.now())
  );

  const [generateReport, { loading }] = useMutation(GENERATE_STAFF_REPORT, {
    refetchQueries: [STAFF_REPORTS],
    onCompleted: () => {
      setGenerateReportOpen(false);
      setReportHasBeenGenerated(true);
    },
    onError: (error) => {
      alerts.error("Error generating report", error);
      setGenerateReportOpen(false);
    },
  });
  const [generateReportOpen, setGenerateReportOpen] = useState(false);

  const reportIsTenanted: boolean = useMemo(
    () =>
      ![
        StaffReportType.BillingAnalysis,
        StaffReportType.FacilityBilling,
        StaffReportType.Rbr,
      ].includes(reportType),
    [reportType]
  );

  const columns: OmnisearchGridColDef<Row>[] = useMemo(
    () => [
      {
        field: "organization",
        headerName: "Organization",
        flex: 1,
        valueGetter: ({ row }) => row.tenant?.name ?? "All tenants",
      },
      {
        field: "reportType",
        headerName: "Report Type",
        flex: 1,
        valueGetter: ({ row }) => row.parameters["reportType"],
      },
      {
        field: "status",
        headerName: "Status",
        flex: 1,
        filterKeyType: "enum",
        enumValues: Object.values(JobStatus),
      },
      {
        field: "updatedAt",
        headerName: "Generated On",
        valueFormatter: ({ value }) => prettyPrintDateTime(value),
        flex: 1,
        filterKeyType: "time",
      },
      {
        field: "totalIssues",
        headerName: "Issues",
        headerAlign: "center",
        align: "center",
        renderCell: ({ value }) => <IssueCount issueCount={value} />,
        filterKeyType: "number",
      },
      {
        field: "actions",
        type: "actions",
        getActions({ row }) {
          if (row.status === JobStatus.Succeeded)
            return [
              <GridActionsCellItem
                key="download"
                label="Download"
                icon={<Download />}
                onClick={() =>
                  getDownloadLink({
                    variables: { id: row.id },
                  })
                }
              />,
            ];

          if (
            row.status === JobStatus.Queued ||
            row.status === JobStatus.Running
          )
            return [
              <GridActionsCellItem
                key="queued"
                label="Queued"
                disabled
                icon={<Loop />}
              />,
            ];

          return [];
        },
      },
    ],
    [getDownloadLink]
  );

  const shouldPoll = useCallback((data: StaffReportJobsQuery) => {
    const polling = data.jobs.items.some((j) =>
      [
        JobStatus.Running,
        JobStatus.Queued,
        JobStatus.ProvisioningEcsTask,
      ].includes(j.status as JobStatus)
    );
    return polling ? 2000 : undefined;
  }, []);

  return (
    <>
      <Box
        sx={{
          mb: 2,
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            mb: reportType === StaffReportType.BillingAnalysis ? 2 : 0,
          }}
        >
          <FormControl sx={{ minWidth: 250, mr: 2 }}>
            <InputLabel>Report Type</InputLabel>
            <Select
              value={reportType}
              label="Report Type"
              onChange={(e) => setReportType(e.target.value as StaffReportType)}
            >
              <MenuItem value={StaffReportType.TierIiValidation}>
                Tier II Validation
              </MenuItem>
              <MenuItem value={StaffReportType.NotReportingAnalysis}>
                Not Reporting Analysis
              </MenuItem>
              <MenuItem value={StaffReportType.NewlyNotReportingFacilities}>
                Newly Not Reporting Facilities
              </MenuItem>
              <MenuItem value={StaffReportType.BillingAnalysis}>
                Billing Analysis
              </MenuItem>
              <MenuItem value={StaffReportType.Rbr}>RBR</MenuItem>
              <MenuItem value={StaffReportType.FacilityBilling}>
                Facility Billing
              </MenuItem>
            </Select>
          </FormControl>
          <Button
            key="generate"
            variant="contained"
            disabled={reportHasBeenGenerated}
            onClick={() => setGenerateReportOpen(true)}
          >
            Generate Report
          </Button>
        </Box>
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={2}>
            <FormControl fullWidth>
              <InputLabel id="year-label">Reporting Year</InputLabel>
              <TierIIYearPicker
                key="yearPicker"
                selectProps={{
                  size: "medium",
                  label: "Reporting Year",
                  labelId: "year-label",
                }}
              />
            </FormControl>
          </Grid>
          {reportType === StaffReportType.BillingAnalysis && (
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Grid item xs={4}>
                <DatePicker
                  label="Start Date"
                  value={billingStartDate}
                  onChange={(date) => setBillingStartDate(date)}
                  slotProps={{ textField: { fullWidth: true } }}
                />
              </Grid>
              <Grid item xs={4}>
                <DatePicker
                  label="End Date"
                  value={billingEndDate}
                  onChange={(date) => setBillingEndDate(date)}
                  slotProps={{ textField: { fullWidth: true } }}
                />
              </Grid>
            </LocalizationProvider>
          )}
        </Grid>
      </Box>
      <OmnisearchDataGrid
        withPadding={false}
        columns={columns}
        dataQuery={STAFF_REPORTS}
        defaultSearch={`task:${JobTask.StaffReport} tenantId:${tenantId} or task:${JobTask.StaffReport} tenantId:null`}
        getItems={(data) => data.jobs.items}
        getCount={(data) => data.jobs.count}
        shouldPoll={shouldPoll}
        initialSortModel={[{ sort: "desc", field: "updatedAt" }]}
        excludeFilterColumns={["reportType", "organization"]}
        commandButtons={[<></>]}
      />
      <Dialog
        open={generateReportOpen}
        onClose={() => setGenerateReportOpen(false)}
      >
        <DialogTitle>Generate Staff Report</DialogTitle>
        <DialogContent>
          This will generate a {reportType} report. Are you sure you want to
          generate a {reportType} report for{" "}
          {reportIsTenanted && tenant?.name ? tenant.name : "all tenants"}?
          {reportType === StaffReportType.BillingAnalysis && (
            <Box sx={{ mt: 2 }}>
              <p>
                Start Date:{" "}
                {billingStartDate
                  ? billingStartDate.toLocaleDateString()
                  : "Not specified"}
              </p>
              <p>
                End Date:{" "}
                {billingEndDate
                  ? billingEndDate.toLocaleDateString()
                  : "Not specified"}
              </p>
            </Box>
          )}
          {/* We should run all tenant validation reports on ECS as a lambda
              is likely not capable of validating all data for a reporting year */}
          {/* <Box paddingTop={theme.spacing(2)} />
          <Typography variant="subtitle2">
            You can also generate a report for all tenants by clicking{" "}
            <b
              style={{ cursor: "pointer" }}
              onClick={() =>
                generateReport({
                  variables: {
                    reportType: StaffReportType.TierIiValidation,
                    reportingYear: currentTierIIReportingYear,
                  },
                })
              }
            >
              <span style={{ textDecoration: "underline" }}>here</span>
            </b>
          </Typography> */}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setGenerateReportOpen(false)}>Cancel</Button>
          <LoadingButton
            variant="contained"
            loading={loading}
            onClick={() =>
              generateReport({
                variables: {
                  reportType: reportType,
                  reportingYear,
                  tenantId: reportIsTenanted ? tenantId : undefined,
                  ...(reportType === StaffReportType.BillingAnalysis && {
                    startDate: billingStartDate,
                    endDate: billingEndDate,
                  }),
                },
              })
            }
          >
            Generate
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};
